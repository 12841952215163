<!-- enable -->
<b *ngIf="initialState === 'enable' || initialState === 'enableTemp'" class="title">{{dataTitle}}</b>
<ng-select *ngIf="initialState === 'enable'"
  class="form-control filter-by-category"
  bindValue="value"
  [ngClass]="getClasses()"
  [(ngModel)]="selectedItems"
  [items]="!searchObj.isSearchEmpty ? (searchObj.data && searchObj.data.length > 0 ? searchObj.data : bufferItems$ | async) : []"
  [searchable]="isSearch"
  [loading]="searchObj.loading"
  [multiple]="true"
  [virtualScroll]="isLazyLoading"
  [clearable]="selectedItems.length > 1"
  [closeOnSelect]="false"
  [trackByFn]="trackByFn"
  [searchFn]="!isFetchDataOnSearch ? customSearchFn : null"
  [typeahead]="isFetchDataOnSearch ? searchControl.valueChanges : null"
  [typeToSearchText]="null"
  (ngModelChange)="onSelectedItemsChange($event)"
  (blur)="onBlur()"
  (focus)="onFocus()"
  (close)="onClose()"
  (add)="onAdd($event)"
  (remove)="onRemove($event)"
  (search)="!isFetchDataOnSearch ? onSearchLocal($event.term) : null"
  (scroll)="onScroll($event)"
  (scrollToEnd)="onScrollToEnd()">
    <ng-template *ngIf="isHierarchical" ng-option-tmp let-item="item" let-index="index">
      <div *ngIf="showTooltip" class="ng-option-tooltip" [ngbTooltip]="item.value" placement="top-left" [container]="'body'">
        <input type="checkbox" class="cb-multi-select"/>
        <ng-container *ngIf="item?.child">&nbsp;&nbsp;&nbsp;</ng-container>
        {{ item.value }}
      </div>

      <div *ngIf="!showTooltip" class="ng-option-tooltip">
        <input type="checkbox" class="cb-multi-select"/>
        <ng-container *ngIf="item?.child">&nbsp;&nbsp;&nbsp;</ng-container>
        {{ item.value }}
      </div>
    </ng-template>

    <ng-template *ngIf="!isHierarchical" ng-option-tmp let-item="item" let-index="index">
      <div *ngIf="showTooltip" class="ng-option-tooltip" [ngbTooltip]="item.value" placement="top-left" [container]="'body'">
        <input type="checkbox" class="cb-multi-select"/>
        {{ item.value }}
      </div>

      <div *ngIf="!showTooltip" class="ng-option-tooltip">
        <input type="checkbox" class="cb-multi-select"/>
        {{ item.value }}
      </div>
    </ng-template>

    <ng-template ng-footer-tmp>
      <button type="submit" class="btn btn-primary" [disabled]="selectedItems.length <= 0 || searchObj.loading" (click)="onSave()">Aggregate</button>
    </ng-template>

    <ng-template ng-multi-label-tmp let-items="items">
      <div class="text-over-dropdown">

        <ng-container *ngFor="let item of items|sort:isNeedSortDisplayValue:'asc'; index as i">
          <ng-container>
              <span *ngIf="i > 0">,&#160;</span><span>{{item.value}}</span>
          </ng-container>
        </ng-container>

      </div>
  </ng-template>

  <ng-template ng-notfound-tmp>
    <div class="ng-option ng-option-not-found disabled" *ngIf="(isFetchDataOnSearch && (!searchObj.loading && searchObj.isSearchEmpty) || !isFetchDataOnSearch)">
      No match found
    </div>
  </ng-template>
</ng-select>

<!-- enableTemp -->
<div *ngIf="initialState === 'enableTemp'" class="enable-temp-txt">
  <strong *ngFor="let item of initialSelected; index as i">
    <span *ngIf="i > 0">,&#160;</span><span>{{item}}</span>
  </strong>
</div>
<button *ngIf="initialState === 'enableTemp'" class="btn-remove-filter" (click)="onConvertEnableTempToTentative()">
  <span class="first"></span>
  <span class="last"></span>
</button>

<!-- tentative -->
<button *ngIf="initialState === 'tentative'" class="btn-addmore-filter" (click)="onConvertTentativeToEnableTemp()">
  <b class="title first" [innerHTML]="dataDescription"></b>
  <b class="title last">Add Filter</b>
  <span class="first"></span>
  <span class="last"></span>
</button>
