import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { environment } from 'src/environments/environment';
import { AnalyticsQueries, SKUQueries } from 'src/app/model/constants';
import * as tsvParse from 'd3-dsv';
import { Router } from '@angular/router';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
const ALL_OBJ = { cd: 'All', name: 'All' };
const Region_TranslationName = {
  "FV統括部": "FV Management Department", "VMエリア営業本部": "VM Area Sales Headquarters",
  "アルコール事業統括部": "Alcohol Business Division", "カスタマーデベロップメント本部": "Customer Development Division",
  "九州/中四国営業本部": "Kyushu/Chu-Shikoku Sales Headquarters", "北関東/南東北営業本部": "North Kanto/South Tohoku Sales Headquarters",
  "営業本部その他": "Sales Headquarters Others", "関西/中部営業本部": "Kansai/Chubu Sales Headquarters", "首都圏営業本部": "Metropolitan Area Sales Headquarters",
  "FSエリア営業本部": "FS Area Sales Headquarters"
}

@Component({
  selector: 'gp-monthly',
  templateUrl: './gp-monthly.component.html',
  styleUrls: ['./gp-monthly.component.scss']
})

export class GPMonthlyComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedDate: any;
  @Input() monthlyData: any;
  gpMonthlyTrendChart: ApexCharts;
  channels: any;
  displayBP: boolean;
  displayGPPerCase: boolean;
  monthlyTrendChannel: any;
  populated: boolean;
  salesArea = [ALL_OBJ];
  salesDistrict = [ALL_OBJ];
  aggregatedSalesArea = [ALL_OBJ];
  aggregatedSalesDistrict = [ALL_OBJ];
  salesAreaList = [];
  salesAreaBinding = [];
  salesDistrictList = [];
  salesDistrictBinding = [];
  sales_area_to_sales_district = {};
  salesAreaDistrictData = {};
  districtCode = [];
  regionCode = [];
  channelLev3codetoRegioncode = {};
  chartId="traffic-source-gp-chart"

  constructor(public datepipe: DatePipe,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  async ngOnChanges() {
    //Init data
    if (!this.populated) {
      this.channels = [];
      this.channels.push(
        {
          name: 'All',
          level: 1
        });
      let channels = AnalyticsHelper.ChannelLv2();
      channels.push({
        code: [41],
        channel: 'Alcohol',
        level: 1,
        region: ['7200']
      });
      for (var i = 0; i < channels.length; i++) {
        let channel = channels[i];
        if (channel.channel == 'Vending_FS' || channel.channel == 'Vending_REG') {
          continue;
        }
        this.channels.push({
          name: channel.channel,
          level: channel.level,
          code: channel.code
        })
      }
      this.displayBP = true;
      this.displayGPPerCase = false;
      this.monthlyTrendChannel = ['All'];
      this.populated = true;
    }
    //Bind data for charts
    if (this.monthlyData != null && this.selectedDate != null) {
      if (this.salesAreaBinding.length == 0)
      {
        await this.getDataInit();
      }
      let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
      this.handleGPSalesData(selectedDate);
    }
  }

  public async getDataInit() {
    //binding data to SKU
    await Promise.all([this.bindingComboboxData(environment.snowflakeProxy + "?code=" + SKUQueries.salesArea)
    ]).then(([salesarealist]) => {
      this.salesAreaList = [ALL_OBJ, ...tsvParse.tsvParse("cd	name\n" + salesarealist)];
      this.salesAreaList.forEach(x => {
        if (x.name in Region_TranslationName) {
          x.name = Region_TranslationName[x.name];
        }
      })
      this.salesAreaBinding = this.salesAreaList;
    })
  }
  private async bindingComboboxData(queryUrl) {
    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    }
    let api = this.http.get<any>(queryUrl, httpOptions);
    return api.toPromise();
  }

  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["monthlyTrendChannel"] = this.monthlyTrendChannel;
    } else {
      this.monthlyTrendChannel = window["monthlyTrendChannel"];
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  onRemoveFilterMonthly($event) {
    if (this.monthlyTrendChannel.length == 0) {
      this.monthlyTrendChannel = ['All'];
      this.SaveOptionValueTemp(false);
      this.renderMonthlyTrendChart();
    }
    if ($event.value === 'All') {
      this.monthlyTrendChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      var arrChilds = AnalyticsHelper.getSmddChild($event.value);
      this.monthlyTrendChannel = this.monthlyTrendChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      var parent = AnalyticsHelper.getSmddParent($event.value);
      this.monthlyTrendChannel = this.monthlyTrendChannel.filter(e => e !== parent);
    }
  }
  onAddFilterMonthly($event) {
    if ($event === 'All') {
      this.monthlyTrendChannel = ['All'];
      return;
    } else {
      this.monthlyTrendChannel = this.monthlyTrendChannel.filter(e => e !== 'All');
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      var arrChilds = AnalyticsHelper.getSmddChild($event);
      this.monthlyTrendChannel = this.monthlyTrendChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      var parent = AnalyticsHelper.getSmddParent($event);
      var checkChildSelected = true;
      var arrChilds = AnalyticsHelper.getSmddChild(parent);
      arrChilds.forEach(child => {
        if (!this.monthlyTrendChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        var arrTemp = [parent];
        this.monthlyTrendChannel = this.monthlyTrendChannel.filter(e => !arrChilds.includes(e));
        this.monthlyTrendChannel = arrTemp.concat(this.monthlyTrendChannel);
      } else {
        this.monthlyTrendChannel = this.monthlyTrendChannel.filter(e => e !== parent);
      }
    }
    if (!this.monthlyTrendChannel.some(x => x === 'All') && (this.monthlyTrendChannel.length + 1) == this.channels.length) {
      this.monthlyTrendChannel = ['All'];
    }
    var checkParentFilter = true;
    var filterNotCheck = this.channels.filter(e => !this.monthlyTrendChannel.includes(e.name) && e.name !== 'All');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element.name) || !this.monthlyTrendChannel.includes(AnalyticsHelper.getSmddParent(element.name))) {
        checkParentFilter = false;
      }
    });
    if (checkParentFilter) {
      this.monthlyTrendChannel = ['All'];
      return;
    }
  }
  handleGPSalesData(selectedDate: Date) {
    let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let salesTrends = [];
    let currentYear = selectedDate.getFullYear();
    let lastYear = currentYear - 1;
    for (var i = 0; i < this.monthlyData.length; i++) {
      let row = this.monthlyData[i];
      if (row.channelCode != '' && !(row.channelCode in this.channelLev3codetoRegioncode)) {
        this.channelLev3codetoRegioncode[row.channelCode] = [];
      }
      if (row.channelCode != '' && !this.channelLev3codetoRegioncode[row.channelCode].includes(row.regionCode) && this.salesAreaList.some(s => s.cd == row.regionCode)) {
        if (row.month.indexOf(currentYear) == 0) {
          if (parseFloat(row.gp_ty) != 0 || parseFloat(row.bapc_ty) != 0 || parseFloat(row.gp_bp) != 0 || parseFloat(row.bapc_bp) != 0) {
            this.channelLev3codetoRegioncode[row.channelCode].push(row.regionCode);
          }
        }
        else if (parseFloat(row.gp_ty) != 0 || parseFloat(row.bapc_ty) != 0) {
          this.channelLev3codetoRegioncode[row.channelCode].push(row.regionCode);
        }
      }

      let monthName = months[parseInt(row.month.substring(4, 6)) - 1];

      let month = salesTrends.find(x => x.month == monthName);
      if (month == null) {
        month = {
          month: monthName,
          index: parseInt(row.month.substring(4, 6)) - 1,
          bapc: {
            act: 0,
            bp: 0,
            ty2019: 0,
            bp2019: 0,
            ly2019: 0,
            ly: 0
          },
          gp: {
            act: 0,
            bp: 0,
            ty2019: 0,
            bp2019: 0,
            ly2019: 0,
            ly: 0,
            perCase: 0,
            perCaseBp: 0,
            perCaseLy: 0,
            perCase2019: 0
          },
          channels: []
        };
        salesTrends.push(month);
      }

      if (row.month.indexOf(currentYear) == 0) {
        month.gp.act += parseFloat(row.gp_ty);
        month.gp.bp += parseFloat(row.gp_bp);
        month.bapc.act += parseFloat(row.bapc_ty);
        month.bapc.bp += parseFloat(row.bapc_bp);
      } else if (row.month.indexOf(lastYear) == 0) {
        month.gp.ly += parseFloat(row.gp_ty);
        month.bapc.ly += parseFloat(row.bapc_ty);
      } else {
        month.gp.ty2019 += parseFloat(row.gp_ty);
        month.bapc.ty2019 += parseFloat(row.bapc_ty);
        month.gp.bp2019 += parseFloat(row.gp_bp);
        month.bapc.bp2019 += parseFloat(row.bapc_bp);
        month.gp.ly2019 += parseFloat(row.gp_ly);
        month.bapc.ly2019 += parseFloat(row.bapc_ly);
      }

      // [FPT-ThinhLD4][1204061681619804] Dashboard Channel filter update
      let currentChannels = AnalyticsHelper.ChannelLv2();
      currentChannels.push({
        code: [41],
        channel: 'Alcohol',
        level: 1,
        region: ['7200']
      });
      currentChannels = currentChannels.filter(x => x.code.find(y => row.channelCode.indexOf(y) == 0) != null && (!x.region || x.region.includes(row.regionCode)));
      for (var j = 0; j < currentChannels.length; j++) {
        let currentChannel = currentChannels[j];
        let channel = month.channels.find(x => x.channel == currentChannel.channel);
        if (channel == null) {
          channel = {
            channel: currentChannel.channel,
            bapc: {
              act: 0,
              bp: 0,
              ty2019: 0,
              bp2019: 0,
              ly2019: 0,
              ly: 0
            },
            gp: {
              act: 0,
              bp: 0,
              ty2019: 0,
              bp2019: 0,
              ly2019: 0,
              ly: 0,
              perCase: 0,
              perCaseBp: 0,
              perCaseLy: 0,
              perCase2019: 0
            },
            regions: []
          };
          month.channels.push(channel);
        }

        //handle region code except 7100 (7100 will be handled after)
        if (!channel.regions.some(x => x.regionCode == row.regionCode) && row.regionCode != '7100') {
          let region = {
            regionCode: row.regionCode,
            bapc: {
              act: 0,
              bp: 0,
              ty2019: 0,
              bp2019: 0,
              ly2019: 0,
              ly: 0
            },
            gp: {
              act: 0,
              bp: 0,
              ty2019: 0,
              bp2019: 0,
              ly2019: 0,
              ly: 0,
              perCase: 0,
              perCaseBp: 0,
              perCaseLy: 0,
              perCase2019: 0
            }
          };

          if (row.month.indexOf(currentYear) == 0) {
            region.gp.act = parseFloat(row.gp_ty);
            region.gp.bp = parseFloat(row.gp_bp);
            region.bapc.act = parseFloat(row.bapc_ty);
            region.bapc.bp = parseFloat(row.bapc_bp);
          } else if (row.month.indexOf(lastYear) == 0) {
            region.gp.ly = parseFloat(row.gp_ty);
            region.bapc.ly = parseFloat(row.bapc_ty);
          } else {
            region.gp.ty2019 = parseFloat(row.gp_ty);
            region.bapc.ty2019 = parseFloat(row.bapc_ty);
            region.gp.bp2019 = parseFloat(row.gp_bp);
            region.bapc.bp2019 = parseFloat(row.bapc_bp);
            region.gp.ly2019 = parseFloat(row.gp_ly);
            region.bapc.ly2019 = parseFloat(row.bapc_ly);
          }
          channel.regions.push(region);
        }
        else if (row.regionCode != '7100')
        {
          let region = channel.regions.find(x => x.regionCode == row.regionCode);
          if (row.month.indexOf(currentYear) == 0) {
            region.gp.act += parseFloat(row.gp_ty);
            region.gp.bp += parseFloat(row.gp_bp);
            region.bapc.act += parseFloat(row.bapc_ty);
            region.bapc.bp += parseFloat(row.bapc_bp);
          } else if (row.month.indexOf(lastYear) == 0) {
            region.gp.ly += parseFloat(row.gp_ty);
            region.bapc.ly += parseFloat(row.bapc_ty);
          } else {
            region.gp.ty2019 += parseFloat(row.gp_ty);
            region.bapc.ty2019 += parseFloat(row.bapc_ty);
            region.gp.bp2019 += parseFloat(row.gp_bp);
            region.bapc.bp2019 += parseFloat(row.bapc_bp);
            region.gp.ly2019 += parseFloat(row.gp_ly);
            region.bapc.ly2019 += parseFloat(row.bapc_ly);
          }
        }


        //only cvs and online channel has customer development division that has region code 7100
        if ((channel.channel == 'CVS' || channel.channel == 'On-Line') && row.regionCode != '9100')
        {
          if (channel.regions.some(x => x.regionCode == '7100'))
          {
            let region_7100 = channel.regions.find(x => x.regionCode == '7100');
            if (row.month.indexOf(currentYear) == 0) {
              region_7100.gp.act += parseFloat(row.gp_ty);
              region_7100.gp.bp += parseFloat(row.gp_bp);
              region_7100.bapc.act += parseFloat(row.bapc_ty);
              region_7100.bapc.bp += parseFloat(row.bapc_bp);
            } else if (row.month.indexOf(lastYear) == 0) {
              region_7100.gp.ly += parseFloat(row.gp_ty);
              region_7100.bapc.ly += parseFloat(row.bapc_ty);
            } else {
              region_7100.gp.ty2019 += parseFloat(row.gp_ty);
              region_7100.bapc.ty2019 += parseFloat(row.bapc_ty);
              region_7100.gp.bp2019 += parseFloat(row.gp_bp);
              region_7100.bapc.bp2019 += parseFloat(row.bapc_bp);
              region_7100.gp.ly2019 += parseFloat(row.gp_ly);
              region_7100.bapc.ly2019 += parseFloat(row.bapc_ly);
            }
          }
          else
          {
            let region_7100 = {
              regionCode: '7100',
              bapc: {
                act: 0,
                bp: 0,
                ty2019: 0,
                bp2019: 0,
                ly2019: 0,
                ly: 0
              },
              gp: {
                act: 0,
                bp: 0,
                ty2019: 0,
                bp2019: 0,
                ly2019: 0,
                ly: 0,
                perCase: 0,
                perCaseBp: 0,
                perCaseLy: 0,
                perCase2019: 0
              }
            };

            if (row.month.indexOf(currentYear) == 0) {
              region_7100.gp.act = parseFloat(row.gp_ty);
              region_7100.gp.bp = parseFloat(row.gp_bp);
              region_7100.bapc.act = parseFloat(row.bapc_ty);
              region_7100.bapc.bp = parseFloat(row.bapc_bp);
            } else if (row.month.indexOf(lastYear) == 0) {
              region_7100.gp.ly = parseFloat(row.gp_ty);
              region_7100.bapc.ly = parseFloat(row.bapc_ty);
            } else {
              region_7100.gp.ty2019 = parseFloat(row.gp_ty);
              region_7100.bapc.ty2019 = parseFloat(row.bapc_ty);
              region_7100.gp.bp2019 = parseFloat(row.gp_bp);
              region_7100.bapc.bp2019 = parseFloat(row.bapc_bp);
              region_7100.gp.ly2019 = parseFloat(row.gp_ly);
              region_7100.bapc.ly2019 = parseFloat(row.bapc_ly);
            }
            channel.regions.push(region_7100);
          }
        }

        if (row.month.indexOf(currentYear) == 0) {
          channel.gp.act += parseFloat(row.gp_ty);
          channel.gp.bp += parseFloat(row.gp_bp);
          channel.bapc.act += parseFloat(row.bapc_ty);
          channel.bapc.bp += parseFloat(row.bapc_bp);
        } else if (row.month.indexOf(lastYear) == 0) {
          channel.gp.ly += parseFloat(row.gp_ty);
          channel.bapc.ly += parseFloat(row.bapc_ty);
        } else {
          channel.gp.ty2019 += parseFloat(row.gp_ty);
          channel.bapc.ty2019 += parseFloat(row.bapc_ty);
          channel.gp.bp2019 += parseFloat(row.gp_bp);
          channel.bapc.bp2019 += parseFloat(row.bapc_bp);
          channel.gp.ly2019 += parseFloat(row.gp_ly);
          channel.bapc.ly2019 += parseFloat(row.bapc_ly);
        }
      }
    }

    for (let i = 0; i < salesTrends.length; i++) {
      let salesTrend = salesTrends[i];
      let perCase = AnalyticsHelper.calcPerCase(salesTrend.gp, salesTrend.bapc);
      salesTrend.gp.perCase = parseFloat((perCase.act / 1000).toFixed(2));
      salesTrend.gp.perCaseBp = parseFloat((perCase.bp / 1000).toFixed(2));
      salesTrend.gp.perCaseLy = parseFloat((perCase.ly / 1000).toFixed(2));
      salesTrend.gp.perCase2019 = parseFloat((AnalyticsHelper.calcPerCase({
        act: salesTrend.gp.ty2019,
        bp: salesTrend.gp.bp2019,
        ly: salesTrend.gp.ly2019
      }, {
        act: salesTrend.bapc.ty2019,
        bp: salesTrend.bapc.bp2019,
        ly: salesTrend.bapc.ly2019
      }).act / 1000).toFixed(2));

      for (let j = 0; j < salesTrend.channels.length; j++) {
        let channel = salesTrend.channels[j];
        let perCase = AnalyticsHelper.calcPerCase(channel.gp, channel.bapc);
        channel.gp.perCase = parseFloat((perCase.act / 1000).toFixed(2));
        channel.gp.perCaseBp = parseFloat((perCase.bp / 1000).toFixed(2));
        channel.gp.perCaseLy = parseFloat((perCase.ly / 1000).toFixed(2));
        channel.gp.perCase2019 = parseFloat((AnalyticsHelper.calcPerCase({
          act: channel.gp.ty2019,
          bp: channel.gp.bp2019,
          ly: channel.gp.ly2019
        }, {
          act: channel.bapc.ty2019,
          bp: channel.bapc.bp2019,
          ly: channel.bapc.ly2019
        }).act / 1000).toFixed(2));

        for (let k = 0; k < channel.regions.length; k++) {
          let region = channel.regions[k];
          let perCase = AnalyticsHelper.calcPerCase(region.gp, region.bapc);
          region.gp.perCase = parseFloat((perCase.act / 1000).toFixed(2));
          region.gp.perCaseBp = parseFloat((perCase.bp / 1000).toFixed(2));
          region.gp.perCaseLy = parseFloat((perCase.ly / 1000).toFixed(2));
          region.gp.perCase2019 = parseFloat((AnalyticsHelper.calcPerCase({
            act: region.gp.ty2019,
            bp: region.gp.bp2019,
            ly: region.gp.ly2019
          }, {
            act: region.bapc.ty2019,
            bp: region.bapc.bp2019,
            ly: region.bapc.ly2019
          }).act / 1000).toFixed(2));
        }
      }


    }

    salesTrends.sort((a, b) => a.index > b.index ? 1 : -1);
    window['monthlyTrendAggGP'] = salesTrends;
    this.renderMonthlyTrendChart();
  }
  renderMonthlyTrendChart() {
    if (this.monthlyTrendChannel.length == 0) {
      this.monthlyTrendChannel = ["All"]
    }
    if (this.gpMonthlyTrendChart != null) {
      this.gpMonthlyTrendChart.destroy();
    }
    let monthlyTrendAgg = window['monthlyTrendAggGP'];
    let categories = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    let allColors = ['#F40009', '#C877DB', '#5FB0FF', '#BAFECC', '#0000FF',
      '#00FF00', '#C877DB', '#FFC0CB', '#FFA500',
      '#A52A2A', '#800000', '#808080', '#C0C0C0', '#008000', '#808000',
      '#7FFD4', '#000000', '#FFFFFF'];
    let series = [
      {
        name: '2019',
        data: [],
        type: 'line'
      },
      {
        name: `${this.selectedDate.year - 1}`, // FPT-HoiLT2: [2022/11/01][SENSE][1203187749185293] SENSE Dashboard page cannot download CSV of component NSR (MM JPY) Monthly  -->
        data: [],
        type: 'line'
      },
      {
        name: `${this.selectedDate.year}`, // FPT-HoiLT2: [2022/11/01][SENSE][1203187749185293] SENSE Dashboard page cannot download CSV of component NSR (MM JPY) Monthly  -->
        data: [],
        type: 'column'
      }
    ];
    if (this.displayBP) {
      series.push({
        name: 'BP ' + this.selectedDate.year,
        data: [],
        type: 'area'
      });
    }
    let colors = [];
    for (var i = 0; i < series.length; i++) {
      colors.push(allColors[i]);
    }
    for (var i = 0; i < monthlyTrendAgg.length; i++) {
      // FPT-HoiLT2:  [2022/10/25][SENSE][1203222980629285] SENSE dashboard QA (latest day KPI as well as trends)
      let channelTemp = {
        bapc: {
          act: 0,
          bp: 0,
          ty2019: 0,
          bp2019: 0,
          ly2019: 0,
          ly: 0
        },
        gp: {
          act: 0,
          bp: 0,
          ty2019: 0,
          bp2019: 0,
          ly2019: 0,
          ly: 0,
          perCase: 0,
          perCaseBp: 0,
          perCaseLy: 0,
          perCase2019: 0
        }
      };
      let monthlyTrend = monthlyTrendAgg[i];
      let data2019 = 0;
      let dataLy = 0;
      let dataTy = 0;
      let dataBp = 0;
      if (this.monthlyTrendChannel.some(channel => channel == 'All') && this.salesArea.some(s => s.name == 'All')) {
        data2019 = this.displayGPPerCase ? parseFloat(monthlyTrend.gp.perCase2019.toFixed(0)) : parseFloat((monthlyTrend.gp.ty2019 / 1000000).toFixed(0));
        dataLy = this.displayGPPerCase ? parseFloat(monthlyTrend.gp.perCaseLy.toFixed(0)) : parseFloat((monthlyTrend.gp.ly / 1000000).toFixed(0));
        dataTy = this.displayGPPerCase ? parseFloat(monthlyTrend.gp.perCase.toFixed(0)) : parseFloat((monthlyTrend.gp.act / 1000000).toFixed(0));
        dataBp = this.displayGPPerCase ? parseFloat(monthlyTrend.gp.perCaseBp.toFixed(0)) : parseFloat((monthlyTrend.gp.bp / 1000000).toFixed(0));
      }
      else {
        if (this.monthlyTrendChannel.some(channel => channel == 'All')) {
          this.salesArea.forEach(regionSelected => {
            monthlyTrend.channels.forEach(channel => {
              channel.regions.forEach(region => {
                if (regionSelected.cd == region.regionCode) {

                  //SMDD, Vending and Retail, Food were already sum
                  if (channel.channel == "SM" || channel.channel == "D&D" || channel.channel == "Vending_FS" || channel.channel == "Vending_REG"
                    || channel.channel == "Retail/Food") {
                    return;
                  }
                  channelTemp.gp.act += parseFloat(region.gp.act.toFixed(0));
                  channelTemp.gp.bp += parseFloat(region.gp.bp.toFixed(0));
                  channelTemp.bapc.act += parseFloat(region.bapc.act.toFixed(0));
                  channelTemp.bapc.bp += parseFloat(region.bapc.bp.toFixed(0));
                  channelTemp.gp.ly += parseFloat(region.gp.ly.toFixed(0));
                  channelTemp.bapc.ly += parseFloat(region.bapc.ly.toFixed(0));
                  channelTemp.gp.ty2019 += parseFloat(region.gp.ty2019.toFixed(0));
                  channelTemp.bapc.ty2019 += parseFloat(region.bapc.ty2019.toFixed(0));
                  channelTemp.gp.bp2019 += parseFloat(region.gp.bp2019.toFixed(0));
                  channelTemp.bapc.bp2019 += parseFloat(region.bapc.bp2019.toFixed(0));
                  channelTemp.gp.ly2019 += parseFloat(region.gp.ly2019.toFixed(0));
                  channelTemp.bapc.ly2019 += parseFloat(region.bapc.ly2019.toFixed(0));
                }
              })
            })
          })
        }
        else if (this.salesArea.some(x => x.cd == 'All')) {
          this.monthlyTrendChannel.forEach(channelname => {
            monthlyTrend.channels.forEach(channel => {
              if (channel.channel == channelname) {
                channelTemp.gp.act += parseFloat(channel.gp.act.toFixed(0));
                channelTemp.gp.bp += parseFloat(channel.gp.bp.toFixed(0));
                channelTemp.bapc.act += parseFloat(channel.bapc.act.toFixed(0));
                channelTemp.bapc.bp += parseFloat(channel.bapc.bp.toFixed(0));
                channelTemp.gp.ly += parseFloat(channel.gp.ly.toFixed(0));
                channelTemp.bapc.ly += parseFloat(channel.bapc.ly.toFixed(0));
                channelTemp.gp.ty2019 += parseFloat(channel.gp.ty2019.toFixed(0));
                channelTemp.bapc.ty2019 += parseFloat(channel.bapc.ty2019.toFixed(0));
                channelTemp.gp.bp2019 += parseFloat(channel.gp.bp2019.toFixed(0));
                channelTemp.bapc.bp2019 += parseFloat(channel.bapc.bp2019.toFixed(0));
                channelTemp.gp.ly2019 += parseFloat(channel.gp.ly2019.toFixed(0));
                channelTemp.bapc.ly2019 += parseFloat(channel.bapc.ly2019.toFixed(0));
              }
            })
          })
        }
        else {
          this.salesArea.forEach(regionSelected => {
            this.monthlyTrendChannel.forEach(channelSelected => {
              monthlyTrend.channels.forEach(channel => {
                channel.regions.forEach(region => {
                  if (channel.channel == channelSelected && region.regionCode == regionSelected.cd) {
                    channelTemp.gp.act += parseFloat(region.gp.act.toFixed(0));
                    channelTemp.gp.bp += parseFloat(region.gp.bp.toFixed(0));
                    channelTemp.bapc.act += parseFloat(region.bapc.act.toFixed(0));
                    channelTemp.bapc.bp += parseFloat(region.bapc.bp.toFixed(0));
                    channelTemp.gp.ly += parseFloat(region.gp.ly.toFixed(0));
                    channelTemp.bapc.ly += parseFloat(region.bapc.ly.toFixed(0));
                    channelTemp.gp.ty2019 += parseFloat(region.gp.ty2019.toFixed(0));
                    channelTemp.bapc.ty2019 += parseFloat(region.bapc.ty2019.toFixed(0));
                    channelTemp.gp.bp2019 += parseFloat(region.gp.bp2019.toFixed(0));
                    channelTemp.bapc.bp2019 += parseFloat(region.bapc.bp2019.toFixed(0));
                    channelTemp.gp.ly2019 += parseFloat(region.gp.ly2019.toFixed(0));
                    channelTemp.bapc.ly2019 += parseFloat(region.bapc.ly2019.toFixed(0));
                  }
                })
              })
            })
          })
        }
      }
      // FPT-HoiLT2:  [2022/10/25][SENSE][1203222980629285] SENSE dashboard QA (latest day KPI as well as trends)
      if (this.monthlyTrendChannel.some(channel => channel != 'All') || this.salesArea.some(region => region.cd != 'All')) {
        let perCase = AnalyticsHelper.calcPerCase(channelTemp.gp, channelTemp.bapc);
        channelTemp.gp.perCase = parseFloat((perCase.act / 1000).toFixed(0));
        channelTemp.gp.perCaseBp = parseFloat((perCase.bp / 1000).toFixed(0));
        channelTemp.gp.perCaseLy = parseFloat((perCase.ly / 1000).toFixed(0));
        channelTemp.gp.perCase2019 = parseFloat((AnalyticsHelper.calcPerCase({
          act: channelTemp.gp.ty2019,
          bp: channelTemp.gp.bp2019,
          ly: channelTemp.gp.ly2019
        }, {
          act: channelTemp.bapc.ty2019,
          bp: channelTemp.bapc.bp2019,
          ly: channelTemp.bapc.ly2019
        }).act / 1000).toFixed(0));
        data2019 = this.displayGPPerCase ? parseFloat(channelTemp.gp.perCase2019.toFixed(0)) : parseFloat((channelTemp.gp.ty2019 / 1000000).toFixed(0) == "0" ? (channelTemp.gp.ty2019 / 1000000).toFixed(2) : (channelTemp.gp.ty2019 / 1000000).toFixed(0));
        dataLy = this.displayGPPerCase ? parseFloat(channelTemp.gp.perCaseLy.toFixed(0)) : parseFloat((channelTemp.gp.ly / 1000000).toFixed(0) == "0" ? (channelTemp.gp.ly / 1000000).toFixed(2) : (channelTemp.gp.ly / 1000000).toFixed(0));
        dataTy = this.displayGPPerCase ? parseFloat(channelTemp.gp.perCase.toFixed(0)) : parseFloat((channelTemp.gp.act / 1000000).toFixed(0) == "0" ? (channelTemp.gp.act / 1000000).toFixed(2) : (channelTemp.gp.act / 1000000).toFixed(0));
        dataBp = this.displayGPPerCase ? parseFloat(channelTemp.gp.perCaseBp.toFixed(0)) : parseFloat((channelTemp.gp.bp / 1000000).toFixed(0) == "0" ? (channelTemp.gp.bp / 1000000).toFixed(2) : (channelTemp.gp.bp / 1000000).toFixed(0));
      }
      series[0].data.push(data2019);
      series[1].data.push(dataLy);
      series[2].data.push(dataTy);
      if (this.displayBP) {
        series[3].data.push(dataBp);
      }
    }

    for (var i = 0; i < series.length; i++) {
      for (var j = 0; j < series[i].data.length; j++) {
        if (series[i].data[j] == 0) {
          series[i].data[j] = null;
        }
      }
    }
    let min = 9999999;
    if (this.displayGPPerCase) {
      for (var i = 0; i < series.length; i++) {
        for (var j = 0; j < series[i].data.length; j++) {
          if (series[i].data[j] != null && series[i].data[j] < min) {
            min = series[i].data[j];
          }
        }
      }
    }
    if (this.selectedDate.year == 2019 || this.selectedDate.year == 2020) {
      series.splice(0, 1);
      colors.splice(0, 1);
    }
    min = min - 100;
    let t = this;
    const options = {
      chart: {
        height: 310,
        type: 'line',
        zoom: {
          enabled: false
        },
        toolbar: {
          show: true,
          tools: {
            download: ''
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [5, 5, 5],
        curve: 'smooth'
      },
      plotOptions: {
        bar: {
          columnWidth: '40%'
        }
      },
      colors: colors,
      series: series,
      fill: {
        opacity: [1, 1, 1],
        gradient: {
          inverseColors: false,
          shade: 'light',
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100]
        }
      },
      labels: categories,
      markers: {
        size: 0
      },
      xaxis: {
        type: 'text'
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            return AnalyticsHelper.formatNumber(val);
          }
        },
        min: t.displayGPPerCase ? min : 0,
        forceNiceScale: true
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (val) {
            if (typeof val !== "undefined") {
              return AnalyticsHelper.formatNumber(val);
            }
            return val;
          }
        }
      },
      legend: {
        offsetY: 0,
        labels: {
          useSeriesColors: false
        }
      }
    }

    document.querySelector(`#${this.chartId}`).innerHTML = '';

    this.gpMonthlyTrendChart = new ApexCharts(
      document.querySelector(`#${this.chartId}`),
      options
    );
    this.gpMonthlyTrendChart.render();
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  public onDisplayBPSelect() {
    this.displayBP = true;
    this.displayGPPerCase = false;
    this.renderMonthlyTrendChart();
  }

  public ondisplayGPPerCaseSelect() {
    this.displayBP = false;
    this.displayGPPerCase = true;
    this.renderMonthlyTrendChart();
  }

  public isSmddChild(channel: string) {
    if (channel == "SM" || channel == "Drug" || channel == "Discounter" || channel == "D&D") {
      return true;
    }
    // [FPT-HoiLT][1204061681619804] Dashboard Channel filter update
    if (channel == "Retail" || channel == "Food") {
      return true;
    }
    return false;
  }

  onRemoveFilterSalesArea($event) {
    if (this.salesArea.length == 0) {
      this.salesArea = [ALL_OBJ];
      return;
    }
  }

  onAddFilterSalesArea($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.salesArea = [ALL_OBJ];
      return;
    }
    this.salesArea = this.salesArea.filter(e => e.cd !== ALL_OBJ.cd);
  }

  setSalesArea() {
    this.salesArea = this.aggregatedSalesArea;
  }

  SalesAreaAggregate() {
    this.aggregatedSalesArea = this.salesArea;
    this.salesDistrict = [ALL_OBJ];
    this.salesDistrictBinding = this.salesDistrictList;
    this.aggregatedSalesDistrict = [ALL_OBJ];
    this.renderMonthlyTrendChart();
    //this.bindingSalesDistrictData();
  }

  onCloseSalesArea() {
    this.salesArea = this.aggregatedSalesArea;
  }

  bindingSalesDistrictData() {
    if (!this.salesArea.some(x => x.name === "All")) {
      this.salesDistrict = [ALL_OBJ];
      this.salesDistrictBinding = [ALL_OBJ];
      this.salesArea.forEach(x => {
        var district_names = this.sales_area_to_sales_district[x.name];
        this.salesDistrictList.filter(d => district_names.includes(d.name)).forEach(s => {
          if (!this.salesDistrictBinding.some(x => x.name === s.name)) {
            this.salesDistrictBinding.push(s);
          }
        });
      })
    }
  }

  onRemoveFilterSalesDistrict($event) {
    if (this.salesDistrict.length == 0) {
      this.salesDistrict = [ALL_OBJ];
      return;
    }
  }

  onAddFilterSalesDistrict($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.salesDistrict = [ALL_OBJ];
      return;
    }
    this.salesDistrict = this.salesDistrict.filter(e => e.cd !== ALL_OBJ.cd);
  }

  setSalesDistrict() {
    this.salesDistrict = this.aggregatedSalesDistrict;
  }

  SalesDistrictAggregate() {
    this.aggregatedSalesDistrict = this.salesDistrict;
  }

  onCloseSalesDistrict() {
    this.salesDistrict = this.aggregatedSalesDistrict;
  }

  ChannelAggregate() {
    this.aggregatedSalesArea = [ALL_OBJ];
    this.salesArea = this.aggregatedSalesArea;
    var code_list = [];
    var regionCode_binding = [];
    if (this.monthlyTrendChannel.some(x => x != 'All')) {
      this.monthlyTrendChannel.forEach(channel => {

        var codes = this.channels.filter(x => x.name == channel)[0].code;
        codes.forEach(c => {
          if (!code_list.includes(c)) {
            code_list.push(c);
          }
        })
      });


      code_list.forEach(code => {
        for (var channelCode in this.channelLev3codetoRegioncode) {
          if (channelCode.indexOf(code) == 0) {
            this.channelLev3codetoRegioncode[channelCode].forEach(x => {
              if (channelCode == "411" && this.monthlyTrendChannel.some(channel => channel == "Alcohol") && this.monthlyTrendChannel.some(channel => channel == "Others")) {
                if (!regionCode_binding.includes("7200")) {
                  regionCode_binding.push("7200");
                }
                if (!regionCode_binding.includes("9100")) {
                  regionCode_binding.push("9100");
                  return;
                }
              }
              if (channelCode == "411" && this.monthlyTrendChannel.some(channel => channel == "Alcohol")) {
                if (!regionCode_binding.includes("7200")) {
                  regionCode_binding.push("7200");
                  return;
                }
              }
              else if (channelCode == "411" && this.monthlyTrendChannel.some(channel => channel == "Others")) {
                if (!regionCode_binding.includes("9100")) {
                  regionCode_binding.push("9100");
                  return;
                }
              }
              else if (!regionCode_binding.includes(x)) {
                regionCode_binding.push(x);
              }
            })
          }
        }
      })
      this.salesAreaBinding = [ALL_OBJ, ...this.salesAreaList.filter(x => regionCode_binding.includes(x.cd))];
    }
    else {
      this.salesAreaBinding = this.salesAreaList;
    }
    this.renderMonthlyTrendChart();
  }

  downloadPng() {
    this.gpMonthlyTrendChart["exports"]?.exportToPng()
  }

  downloadCSV() {
    this.gpMonthlyTrendChart["exports"]?.exportToCSV({
      series: this.gpMonthlyTrendChart['w']['config']['series'],
      columnDelimiter: this.gpMonthlyTrendChart['w'].config.chart.toolbar.export.csv.columnDelimiter
    });
  }

}
