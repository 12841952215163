import { Component, Renderer2, OnInit, OnChanges,SimpleChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import moment from 'moment';
import { HQProgressHelper } from 'src/app/components/pages/hq-progress/hq_progress_helper';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { getSortColumnName } from 'src/app/helpers/sort';
import { localStorageHelper } from 'src/app/helpers/localStorage';
import { downloadAsPDF as _downloadAsPDF } from '../../../../helpers/pdfExtend';
import { PDFProperty } from 'src/app/model/pdfProperty.model';
import { FILTER_CONFIGS_HQ, AnalyticsQueries } from 'src/app/model/constants';
import { FilterHelper } from 'src/app/components/elements/add-more-filter-hq/filter-helper-hq';
import lodashClonedeep from 'lodash.clonedeep';
import { PRIORITY_ORDER } from 'src/app/helpers/sort';
import { sortArrayByPriority } from 'src/app/helpers/sort';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'hq-national-local',
  templateUrl: './hq-national-local.component.html',
  styleUrls: ['./hq-national-local.component.scss']
})

export class HQNationalLocalComponent implements OnInit, OnChanges{
  @Input() fromDate: any;
  @Input() toDate: any;
  hqNationalLocalRawData : any;
  listColumn: any = [];
  listGroupNameRoot1: any[];
  listGroupNameRoot2: any[];
  sortType     : string = "desc";
  sortColumn   : string  = "NSR_DIFF_VS_BP";
  sortColumnName: string = '';
  totalRoot1   : any[];
  totalRoot2   : any[];
  saleDate: string = '';

  @Input() lstOption_Selected_SalesHQLev1: any= {};

  FILTERS: any        = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.KEY_NAME || [];
  HQ_PROGRESS_FILTERS = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS?.COLLECTIONS;
  defaultVisibleFilters: number = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS?.VISIBLE_COUNT ?? 4;

  listData_Filter: any = null;
  lstOption_States: any = {};
  lstOption_States_Applied: any = {};
  lstOption_Selected: any = {};
  lstOption_IsUsed: any = {};
  lstOption_Selected_Applied: any = {};

  lstOption_ChannelLev: any;
  lstOption_Selected_ChannelLev: any = 'CHANNEL_LEV1_NAME';
  analyticsQuery: object = {};
  isLoading:boolean = false;

  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  weeks: any;
  populated: boolean;
  aggregateBy: any;
  isHasFistLoad: boolean = false;

  constructor(public datepipe: DatePipe, private http: HttpClient, private csvService: CsvService, private userService: UserService) {}
  ngOnInit(): void {
    const lstOption = FilterHelper.init_lstOption_States('HQNationalLocalComponent', this.FILTERS, this.HQ_PROGRESS_FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
    this.lstOption_States   = {...lstOption?.lstOption_States};
    this.lstOption_Selected = {...lstOption?.lstOption_Selected};
    this.listData_Filter  = {...lstOption.listData_Filter};
    if(lstOption?.lstOption_Selected?.AggregateBy){
        this.aggregateBy = lstOption?.lstOption_Selected?.AggregateBy ;
    }

    this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.HQ_PROGRESS_FILTERS) || {};
    this.lstOption_States_Applied = {...lstOption?.lstOption_States};
    this.lstOption_Selected_Applied = {...lstOption?.lstOption_Selected}
    this.analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn
    );

    this.sortColumnName = getSortColumnName('HQ_NATIONALLOCAL', this.sortColumn);
    this.listColumn = HQProgressHelper.ListColumnNationalLocal();
    this._getChannelLevFilterConfigs();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((changes['fromDate'] || changes['toDate']) && this.fromDate && this.toDate) {
      this.saleDate = moment(this.fromDate).format('YYYY/MM/DD') + ' to ' + moment(this.toDate).format('YYYY/MM/DD');
      if(this.isHasFistLoad){
        this.onSearchData();
      }
    }
    this.isHasFistLoad = true;
  }

  onUpdateChart(event: any) {
    const {lstOption_States, lstOption_Selected} = event;
    if (lstOption_States && lstOption_Selected) {
        this.lstOption_Selected = {};
        this.lstOption_Selected = lstOption_Selected;
        this.lstOption_States   = {};
        this.lstOption_States   = lstOption_States;
        this.onUpdateCurrent_Filter_Applied();
        this.updateHQLevel2Data();
    }
  }

  onResetFiltersGroup(event: any) {
    const { data } = event;

    if (data && Array.isArray(data) && data.length > 0) {
    data.map(key => {
        if (key && key.length > 0) {
        this.lstOption_Selected[key] = ['All'];
        }
    });
    }
  }

  onUpdateCurrent_Filter_Applied() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }

  private _getChannelLevFilterConfigs() {
    this.lstOption_ChannelLev = FILTER_CONFIGS_HQ?.CHART_FILTER?.HQ_NATIONAL_CHANNEL_LEV;
    this.lstOption_Selected_ChannelLev = FILTER_CONFIGS_HQ?.CHART_FILTER?.HQ_NATIONAL_CHANNEL_LEV[0]?.value;
    this.onSaveChannelLevChange(this.lstOption_Selected_ChannelLev);
  }

  sortTable(defaultSort: string,column: string) {
    if(column == this.sortColumn){
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }

    this.sortColumn = column;
    this.sortColumnName = getSortColumnName('HQ_NATIONALLOCAL', this.sortColumn);

    this.onSearchData();
  }

  formartNumber(category:string, number : any){
    return HQProgressHelper.FormartNumber(category,number);
  }

  formartClassForColumn(isLunisolarColor: boolean, value : any) {
    return HQProgressHelper.formartClassForColumn(isLunisolarColor,value);
  }

  public onAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
  }

  handleonReady(){
    let t1= this;

    setTimeout(() => {
      t1.ready.emit(true);
    });
  }

  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }

  onSearchData() {
    if (this.fromDate === undefined || this.toDate === undefined) {
      return;
    }

    this.isLoading = true;

    this.analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    const startDate = moment(this.fromDate).format("YYYY-MM-DD");
    const endDate = moment(this.toDate).format("YYYY-MM-DD");

    HQProgressHelper.GetHQNationalLocalDataBySaleDate(
          this.http,
          startDate,
          endDate,
          this.lstOption_Selected_ChannelLev,
          this.analyticsQuery
      ).then((data) => {
        this.hqNationalLocalRawData = data;
        this.handleonReady();
          this.isLoading = false;
      });
  }

  onSaveChannelLevChange(e: string): void {
    if (!e) {
      return;
    }

    this.lstOption_Selected_ChannelLev = e;
    this.lstOption_ChannelLev.map((elt: any) => {
      const option = elt?.value || null;

      this.listColumn.forEach((column: any) => {
        const { category } = column;

        if (option?.replace(/ /g, '_').toUpperCase() === category) {
          column.isDisplay = this.lstOption_Selected_ChannelLev.includes(option) ? true : false;
        }
      });
    });

    this.onSearchData();
  }

  public updateHQLevel2Data():void {
    this.onSearchData();
  }


  filteredRoots2(listRoot2: any, nameRoot1: string) {
    return listRoot2.filter(p => p["CHANNEL_NL"] === nameRoot1);
  }

  filteredRoots3(listRoot3: any,nameRoot1: string, nameRoot2 : string) {
    return listRoot3.filter(p => p["CHANNEL_NL"] === nameRoot1  && p["CHANNEL_LEV1_NAME"] === nameRoot2);
  }

  filteredRoots4(listRoot4: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string) {
    return listRoot4.filter(p => p["CHANNEL_NL"] === nameRoot1  && p["CHANNEL_LEV1_NAME"] === nameRoot2 && p["CHANNEL_LEV2_NAME"] === nameRoot3);
  }

  public exportHqLevel2_Excel_by_Url() {
    const obj = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    const startDate = moment(this.fromDate).format("YYYY-MM-DD");
    const endDate   = moment(this.toDate).format("YYYY-MM-DD");

    const analyticsQuery = {
      ...obj,
      'CacheKey' : `${obj['CacheKey']}&StartDate=${startDate}&EndDate=${endDate}`,
      'StartDate': startDate,
      'EndDate'  : endDate
    }

    this.csvService.getCsvUrl(AnalyticsQueries.hqprogressnationallocalSaleDate, analyticsQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  public exportHqLevel2_Excel(){

    const analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );


    HQProgressHelper.GetHQNationalLocalDataBySaleDate(
      this.http,
      moment(this.fromDate).format("YYYY-MM-DD"),
      moment(this.toDate).format("YYYY-MM-DD"),
      null,
      analyticsQuery,
      true
    ).then((response) => {
        if (response) {
          const fileName = "Progress_by_National_Local";
          let data = [];
          let filters: any;
          filters = {
            saleDate: moment(this.fromDate).format('YYYY/MM/DD') + ' to ' + moment(this.toDate).format('YYYY/MM/DD'),
            saleHQLev2:String(this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME),
            channelLev1:String(this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME), 
            channelLev2:String(this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME), 
            channelLev3:String(this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME), 
            channel_NL:String(this.lstOption_Selected_Applied.CHANNEL_NL) 
          };

          let CHANNEL_NL = "";
          let CHANNEL_LEV1_NAME = "";
          let CHANNEL_LEV2_NAME = "";

         // let CHANNEL_NL_CODE = "";
          let CHANNEL_LEV1_CODE = "";
          let CHANNEL_LEV2_CODE = "";

          response.ListRoot1.forEach(root1=> {
            data.push(this.toRowCSV(root1, CHANNEL_NL, "Total", "", "Total", "", filters));
           // CHANNEL_NL_CODE = root1.CHANNEL_NL_CODE ===''?CHANNEL_NL_CODE:root1.CHANNEL_NL_CODE;
            CHANNEL_NL = root1.CHANNEL_NL ===''?CHANNEL_NL:root1.CHANNEL_NL;

            const root2Sort = sortArrayByPriority(this.filteredRoots2(response.ListRoot2,root1['CHANNEL_NL']), PRIORITY_ORDER?.CHANNEL_LEV1_NAME, 'CHANNEL_LEV1_NAME');
            root2Sort.forEach(root2=>{
              data.push(this.toRowCSV(root2, CHANNEL_NL, CHANNEL_LEV1_NAME, "Total", CHANNEL_LEV1_CODE, "Total", filters));
              CHANNEL_LEV1_CODE = root2.CHANNEL_LEV1_CODE ===''?CHANNEL_LEV1_CODE:root2.CHANNEL_LEV1_CODE;
              CHANNEL_LEV1_NAME = root2.CHANNEL_LEV1_NAME ===''?CHANNEL_LEV1_NAME:root2.CHANNEL_LEV1_NAME;

              const root3Sort = sortArrayByPriority(this.filteredRoots3(response.ListRoot3,root1['CHANNEL_NL'], root2['CHANNEL_LEV1_NAME']), PRIORITY_ORDER?.CHANNEL_LEV2_NAME, 'CHANNEL_LEV2_NAME');
              root3Sort.forEach(root3=>{
                    data.push(this.toRowCSV(root3, CHANNEL_NL, CHANNEL_LEV1_NAME, CHANNEL_LEV2_NAME, CHANNEL_LEV1_CODE, CHANNEL_LEV2_CODE, filters));
                    CHANNEL_LEV2_CODE = root3.CHANNEL_LEV2_CODE ===''?CHANNEL_LEV2_CODE:root3.CHANNEL_LEV2_CODE;
                    CHANNEL_LEV2_NAME = root3.CHANNEL_LEV2_NAME ===''?CHANNEL_LEV2_NAME:root3.CHANNEL_LEV2_NAME;

                    const root4Sort = sortArrayByPriority(this.filteredRoots4(response.ListAll, root1['CHANNEL_NL'], root2['CHANNEL_LEV1_NAME'] , root3['CHANNEL_LEV2_NAME']), PRIORITY_ORDER?.CHANNEL_LEV3_NAME, 'CHANNEL_LEV3_NAME');
                    root4Sort.forEach(root4 => {
                       data.push(this.toRowCSV(root4, CHANNEL_NL, CHANNEL_LEV1_NAME, CHANNEL_LEV2_NAME, CHANNEL_LEV1_CODE, CHANNEL_LEV2_CODE, filters));
                    });
                }
              )
            });
          });

          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: [
              "Sales Date", 
              "Sales HQ Lev2", 
              "Channel Lev1", 
              "Channel Lev2", 
              "Channel Lev3",
              'National/Local',
              'National/Local',
              'Channel Lev1 Code',
              'Channel Lev1 Name',

              'Channel Lev2 Code',
              'Channel Lev2 Name',

              'Channel Lev3 Code', 
              'Channel Lev3 Name', 

              'BAPC TY',
              'BAPC BP', //add
              'BAPC% VS BP', 
              'BAPC DIFF VS BP', 
              'BAPC LY', // add
              'BAPC% VS LY', 
              'BAPC DIFF VS LY', // add

              'NSR TY', 
              'NSR BP', // add
              'NSR% VS BP', 
              'NSR DIFF VS BP', 
              'NSR LY', // add
              'NSR% VS LY', 
              'NSR DIFF VS LY', // add

              'NSR PER CASE TY', 
              'NSR PER CASE BP',  // add
              'NSR PER CASE% VS BP',  // add
              'NSR PER CASE DIFF VS BP', 
              'NSR PER CASE LY', // add
              'NSR PER CASE% VS LY', // add
              'NSR PER CASE DIFF VS LY', 

              'GP TY', 
              'GP BP', // add
              'GP% VS BP', 
              'GP DIFF VS BP', 
              'GP LY', // add
              'GP% VS LY', 
              'GP DIFF VS LY', // add

              'GP PER CASE TY', 
              'GP PER CASE BP', // add
              'GP PER CASE% VS BP', //add 
              'GP PER CASE DIFF VS BP', 
              'GP PER CASE LY', //add 
              'GP PER CASE% VS LY',//add 
              'GP PER CASE DIFF VS LY'
            ],
          });
        }
    });
  }

  toRowCSV(row:any, CHANNEL_NL:any, CHANNEL_LEV1_NAME:any, CHANNEL_LEV2_NAME: any, CHANNEL_LEV1_CODE:any, CHANNEL_LEV2_CODE: any, filters:any){
    return {
      SALE_DATE: filters === null?'': filters.saleDate,
      SALE_HQ_LEV2: filters === null?'': String(filters.saleHQLev2),
      CHANNEL_LEV1: filters === null?'':String(filters.channelLev1),
      CHANNEL_LEV2: filters === null?'':String(filters.channelLev2),
      CHANNEL_LEV3: filters === null?'':String(filters.channelLev3),
      CHANNEL_NL : filters == null? '' : String(filters.channel_NL),

      //CHANNEL_NL_CODE : !row['CHANNEL_NL_Display'] ? CHANNEL_NL_CODE : row['CHANNEL_NL_CODE'],
      CHANNEL_NL_Name : !row['CHANNEL_NL_Display'] ? CHANNEL_NL : row['CHANNEL_NL_Display'], //
      
      CHANNEL_LEV1_CODE: !row['CHANNEL_LEV1_NAME_Display'] ? CHANNEL_LEV1_CODE : row['CHANNEL_LEV1_CODE'],
      CHANNEL_LEV1_NAME: !row['CHANNEL_LEV1_NAME_Display'] ? CHANNEL_LEV1_NAME : row['CHANNEL_LEV1_NAME_Display'], //

      CHANNEL_LEV2_CODE: !row['CHANNEL_LEV2_NAME_Display'] ? CHANNEL_LEV2_CODE : row['CHANNEL_LEV2_CODE'],
      CHANNEL_LEV2_NAME: !row['CHANNEL_LEV2_NAME_Display'] ? CHANNEL_LEV2_NAME : row['CHANNEL_LEV2_NAME_Display'], //

      CHANNEL_LEV3_CODE: !row['CHANNEL_LEV3_NAME_Display'] ? '' : row['CHANNEL_LEV3_CODE'],
      CHANNEL_LEV3_NAME: !row['CHANNEL_LEV3_NAME_Display'] ? '' : row['CHANNEL_LEV3_NAME_Display'], //

      "BAPC_TY": row['BAPC_TY_Display'] ,
      "BAPC_BP": row['BAPC_BP_Display'] ,
      "BAPC_VS_BP": row['BAPC_VS_BP_Display'] ,
      "BAPC_DIFF_VS_BP": row['BAPC_DIFF_VS_BP_Display'] ,
      "BAPC_LY": row['BAPC_LY_Display'] ,
      "BAPC_VS_LY": row['BAPC_VS_LY_Display'] ,
      "BAPC_DIFF_VS_LY": row['BAPC_DIFF_VS_LY_Display'] ,

      "NSR_TY": row['NSR_TY_Display'] ,
      "NSR_BP": row['NSR_BP_Display'] ,
      "NSR_VS_BP": row['NSR_VS_BP_Display'] ,
      "NSR_DIFF_VS_BP": row['NSR_DIFF_VS_BP_Display'] ,
      "NSR_LY": row['NSR_LY_Display'] ,
      "NSR_VS_LY": row['NSR_VS_LY_Display'] ,
      "NSR_DIFF_VS_LY": row['NSR_DIFF_VS_LY_Display'] ,

      "NSR_PER_CASE_TY": row['NSR_PER_CASE_TY_Display'] ,
      "NSR_PER_CASE_BP": row['NSR_PER_CASE_BP_Display'] ,
      "NSR_PER_CASE_VS_BP": row['NSR_PER_CASE_VS_BP_Display'] ,
      "NSR_PER_CASE_DIFF_VS_BP": row['NSR_PER_CASE_DIFF_VS_BP_Display'] ,
      "NSR_PER_CASE_LY": row['NSR_PER_CASE_LY_Display'] ,
      "NSR_PER_CASE_VS_LY": row['NSR_PER_CASE_VS_LY_Display'] ,
      "NSR_PER_CASE_DIFF_VS_LY": row['NSR_PER_CASE_DIFF_VS_LY_Display'] ,

      "GP_TY": row['GP_TY_Display'] ,
      "GP_BP": row['GP_BP_Display'] ,
      "GP_VS_BP": row['GP_VS_BP_Display'] ,
      "GP_DIFF_VS_BP": row['GP_DIFF_VS_BP_Display'] ,
      "GP_LY": row['GP_LY_Display'] ,
      "GP_VS_LY": row['GP_VS_LY_Display'] ,
      "GP_DIFF_VS_LY": row['GP_DIFF_VS_LY_Display'] ,

      "GP_PER_CASE_TY": row['GP_PER_CASE_TY_Display'] ,
      "GP_PER_CASE_BP": row['GP_PER_CASE_BP_Display'] ,
      "GP_PER_CASE_VS_BP": row['GP_PER_CASE_VS_BP_Display'] ,
      "GP_PER_CASE_DIFF_VS_BP": row['GP_PER_CASE_DIFF_VS_BP_Display'] ,
      "GP_PER_CASE_LY": row['GP_PER_CASE_LY_Display'] ,
      "GP_PER_CASE_VS_LY": row['GP_PER_CASE_VS_LY_Display'] ,
      "GP_PER_CASE_DIFF_VS_LY": row['GP_PER_CASE_DIFF_VS_LY_Display']
    }
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  @ViewChild('pdf_print_hq_lev2', { static: false }) pdf_print_hq_lev2: ElementRef;
  exportHQLEV_PDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing-se pdf-printing");

    const pdfProperty:PDFProperty={
      option: {margin: [40, 0, 40, 40]}
    }
    const area = this.pdf_print_hq_lev2.nativeElement;
    return _downloadAsPDF(name, area,false, pdfProperty );
  }
}
