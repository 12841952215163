export class localStorageHelper {
    static set_Option_Filter_States(filterModuleName, 
        lstOption_States: any = null,
        lstOption_Selected : any = null,
        lstOptions_IndexDB : any = null,
        lstAllOptionsFilteredCalendarData : any = null,
        lstAllOptionsFilteredAreaData : any = null,
        lstAllOptionsFilteredAccountData : any = null,
        lstAllOptionsFilteredProductData : any = null,
        lstOption_Selected_DateType : any = null,
        lstOption_Selected_SalesDate : any = null,
        dailySalesDateRange: any = null,
        userGroupName: string
         ){
        let objectData = {
            lstOption_States,
            lstOption_Selected,
            listData_Filter: {
                lstOptions_IndexDB,
                lstAllOptionsFilteredCalendarData,
                lstAllOptionsFilteredAreaData,
                lstAllOptionsFilteredAccountData,
                lstAllOptionsFilteredProductData
            },
            lstOption_Selected_DateType,
            lstOption_Selected_SalesDate,
            dailySalesDateRange: lstOption_Selected_DateType === 'MTD' ? null : dailySalesDateRange,
            userGroupName
        }
        let valueString = JSON.stringify(objectData);
        localStorage.setItem('Option_Filter_States_'+filterModuleName,valueString);
    }

    static get_Option_Filter_States(filterModuleName: string){
        let valueString =  localStorage.getItem('Option_Filter_States_'+filterModuleName);
        if(!valueString) return null;
        return JSON.parse(valueString);
    }


    static remove_Option_Filter_States(element){
        let valueString =  localStorage.removeItem('Option_Filter_States_'+element);
    }

    static clear(){
            let lstStorage: string[] = [];
            for (let i = 0; i < localStorage.length; i++) {
                const key = localStorage.key(i);
                if (!key.includes("Option_Filter_States_")) {
                    lstStorage.push(key);
                }
              }
              lstStorage.forEach(element => {
                localStorage.removeItem(element);
              });
    }
}