import { DATA } from './../../../pages/sku-deepdive/data';
import { Component, Renderer2, OnInit, OnChanges,SimpleChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import moment from "moment";

import { PDFProperty } from 'src/app/model/pdfProperty.model';
import { HQProgressHelper } from 'src/app/components/pages/hq-progress/hq_progress_helper';
import { getSortColumnName } from 'src/app/helpers/sort';
import { FILTER_CONFIGS_HQ, AnalyticsQueries } from 'src/app/model/constants';
import { downloadAsPDF as _downloadAsPDF } from '../../../../helpers/pdfExtend';
import { FilterHelper } from 'src/app/components/elements/add-more-filter-hq/filter-helper-hq';
import lodashClonedeep from 'lodash.clonedeep';
import { PRIORITY_ORDER } from 'src/app/helpers/sort';
import { sortArrayByPriority } from 'src/app/helpers/sort';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'hq-level1',
  templateUrl: './hq-level1.component.html',
  styleUrls: ['./hq-level1.component.scss']
})

export class HQLevel1Component implements OnInit, OnChanges {
  @Output() event_Aggregated_SalesHQLev1 = new EventEmitter<string>();
  @Output() event_SalesHQLev1_Applied = new EventEmitter<boolean>();
  @Input() fromDate: any;
  @Input() toDate: any;

  hqLevel1RawData : any;
  listColumn        : any = [];
  listGroupNameRoot1: any[];
  listGroupNameRoot2: any[];
  sortType          : string ="desc";
  sortColumn        : string ="";
  sortColumnName    : string = '';
  saleDate: string = '';

  FILTERS: any        = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.KEY_NAME || [];
  HQ_PROGRESS_FILTERS = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS_LEVE1?.COLLECTIONS;
  defaultVisibleFilters: number = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.HQ_PROGRESS_LEVE1?.VISIBLE_COUNT ?? 4;

  listData_Filter: any = null;
  lstOption_States: any = {};
  lstOption_States_Applied: any = {};
  lstOption_Selected: any = {};
  lstOption_IsUsed: any = {};
  lstOption_Selected_Applied: any = {};

  lstOption_ChannelLev: any;
  lstOption_Selected_ChannelLev: any = 'CHANNEL_LEV1_NAME';
  lstOption_Aggregated_Channel_NL: any;
  analyticsQuery: object = {};
  isLoading:boolean = false;

  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  populated: boolean;
  aggregateBy: any;
  isHasFistLoad: boolean = false;

  constructor(public datepipe: DatePipe, private http: HttpClient, private csvService: CsvService, private userService: UserService) {}

  ngOnInit(): void {
    const lstOption = FilterHelper.init_lstOption_States('HQLevel1Component', this.FILTERS, this.HQ_PROGRESS_FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
    this.lstOption_States   = {...lstOption?.lstOption_States};
    this.lstOption_Selected = {...lstOption?.lstOption_Selected};
    this.listData_Filter  = {...lstOption.listData_Filter};
    if(lstOption?.lstOption_Selected?.AggregateBy){
        this.aggregateBy = lstOption?.lstOption_Selected?.AggregateBy ;
    }

    this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.HQ_PROGRESS_FILTERS) || {};
    this.lstOption_States_Applied = {...lstOption?.lstOption_States};
    this.lstOption_Selected_Applied = {...lstOption?.lstOption_Selected}
    this.analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    this.sortColumnName = getSortColumnName('HQ_LEVEL_1', this.sortColumn);
    this.listColumn     = HQProgressHelper.ListColumnHQLevel1();
    this._getChannelLevFilterConfigs();

  }

  ngOnChanges(changes: SimpleChanges) {
    if((changes['fromDate'] || changes['toDate']) && this.fromDate && this.toDate) {
      this.saleDate = moment(this.fromDate).format('YYYY/MM/DD') + ' to ' + moment(this.toDate).format('YYYY/MM/DD');
      if(this.isHasFistLoad){
        this.onSearchData();
      }
    }
    this.isHasFistLoad = true;
  }

  onUpdateChart(event: any) {
    const {lstOption_States, lstOption_Selected} = event;
    if (lstOption_States && lstOption_Selected) {
        this.lstOption_Selected = {};
        this.lstOption_Selected = lstOption_Selected;
        this.lstOption_States   = {};
        this.lstOption_States   = lstOption_States;
        this.onUpdateCurrent_Filter_Applied();
        this.updateHqLevel1Data();
    }
  }

  public updateHqLevel1Data(): void {
    this.event_Aggregated_SalesHQLev1.emit(this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME);
    this.onSearchData();
  }

  onResetFiltersGroup(event: any) {
    const { data } = event;

    if (data && Array.isArray(data) && data.length > 0) {
    data.map(key => {
        if (key && key.length > 0) {
        this.lstOption_Selected[key] = ['All'];
        }
    });
    }
  }

  onUpdateCurrent_Filter_Applied() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }

  private _getChannelLevFilterConfigs() {
    this.lstOption_ChannelLev = FILTER_CONFIGS_HQ?.CHART_FILTER?.HQ1_CHANNEL_LEV;
    this.lstOption_Selected_ChannelLev = FILTER_CONFIGS_HQ?.CHART_FILTER?.HQ1_CHANNEL_LEV[0]?.value;
    this.onSaveChannelLevChange(this.lstOption_Selected_ChannelLev);
  }

  sortTable(defaultSort: string, column: string) {
    if (column == this.sortColumn) {
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }

    this.sortColumn = column;
    this.sortColumnName = getSortColumnName('HQ_LEVEL_1', this.sortColumn);

    this.onSearchData();
  }

  formartNumber(category:string, number: any) {
    return HQProgressHelper.FormartNumber(category,number);
  }

  formartClassForColumn(isLunisolarColor: boolean, value : any) {
    return HQProgressHelper.formartClassForColumn(isLunisolarColor,value);
  }

  public onAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
  }

  handleonReady(){
    let t= this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  onSearchData() {
    if (this.fromDate === undefined || this.toDate === undefined) {
      return;
    }

    this.isLoading  = true;
    this.analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    const startDate = moment(this.fromDate).format("YYYY-MM-DD");
    const endDate   = moment(this.toDate).format("YYYY-MM-DD");

    HQProgressHelper.GetHQLevel1DataBySaleDate(
          this.http,
          startDate,
          endDate,
          this.lstOption_Selected_ChannelLev,
          this.analyticsQuery
      ).then((data) => {
        this.hqLevel1RawData = data;
        this.handleonReady();
          this.sortDefault();
          this.isLoading = false;
      });
  }

  onSaveChannelLevChange(e: string): void {
    if (!e) {
      return;
    }

    this.lstOption_Selected_ChannelLev = e;
    this.lstOption_ChannelLev.map((elt: any) => {
      const option = elt?.value || null;

      this.listColumn.forEach((column: any) => {
        const { category } = column;

        if (option?.replace(/ /g, '_').toUpperCase() === category) {
          column.isDisplay = this.lstOption_Selected_ChannelLev.includes(option) ? true : false;
        }
      });
    });

    this.onSearchData();
  }

  public updateHQLevel1Data():void {
    this.event_SalesHQLev1_Applied.emit(true);
    this.onSearchData();
  }

  filteredRoots2(listRoot2: any, nameRoot1: string) {
    return listRoot2.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1);
  }

  filteredRoots3(listRoot3: any,nameRoot1: string, nameRoot2 : string) {
    return listRoot3.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1  && p["CHANNEL_LEV1_NAME"] === nameRoot2);
  }

  filteredRoots4(listRoot4: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string) {
    return listRoot4.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1  && p["CHANNEL_LEV1_NAME"] === nameRoot2  && p["CHANNEL_LEV2_NAME"] === nameRoot3);
  }

  exportHQLEV1_CSV_by_Url() {
    const obj = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    const startDate = moment(this.fromDate).format("YYYY-MM-DD");
    const endDate   = moment(this.toDate).format("YYYY-MM-DD");

    const analyticsQuery = {
      ...obj,
      'CacheKey' : `${obj['CacheKey']}&StartDate=${startDate}&EndDate=${endDate}`,
      'StartDate': startDate,
      'EndDate'  : endDate
    }

    this.csvService.getCsvUrl(AnalyticsQueries.hqProgressLeve1BySaleDate, analyticsQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  exportHQLEV1_CSV(){
    const startDate = moment(this.fromDate).format("YYYY-MM-DD");
    const endDate   = moment(this.toDate).format("YYYY-MM-DD");

    const analyticsQuery = HQProgressHelper.HQLevel1SetQueryString(
      this.lstOption_Selected_Applied,
      1,
      this.sortType,
      this.sortColumn,
      false
    );

    HQProgressHelper.GetHQLevel1DataBySaleDate(
          this.http,
          startDate,
          endDate,
          null,
          analyticsQuery,
          true
      ).then((response) => {
          if (response) {
            const fileName = "Progress_by_HQ_Lv1"
            const data     = [];
            let filters:any;
            filters = {
              saleDate: moment(this.fromDate).format('YYYY/MM/DD') + ' to ' + moment(this.toDate).format('YYYY/MM/DD'),
              saleHQLev1:  String(this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME),
              channelLev1: String(this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME), 
              channelLev2: String(this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME),
              channelLev3: String(this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME),
              channel_NL: String(this.lstOption_Selected_Applied.CHANNEL_NL)
            };

            let SALES_HQ_LEV1_NAME = "";
            let CHANNEL_LEV1_NAME = "";
            let CHANNEL_LEV2_NAME = "";

            let SALES_HQ_LEV1_CODE = "";
            let CHANNEL_LEV1_CODE = "";
            let CHANNEL_LEV2_CODE = "";

            const root1Sort = sortArrayByPriority([...response.ListRoot1], PRIORITY_ORDER?.SALES_HQ_LEV1_NAME, 'SALES_HQ_LEV1_NAME');
            root1Sort.forEach(root1=>{
              data.push(this.toRowCSV(root1, SALES_HQ_LEV1_NAME, "Total", "", SALES_HQ_LEV1_CODE, "Total", "", filters));

              SALES_HQ_LEV1_CODE = root1.SALES_HQ_LEV1_CODE ===''?SALES_HQ_LEV1_CODE:root1.SALES_HQ_LEV1_CODE;
              SALES_HQ_LEV1_NAME = root1.SALES_HQ_LEV1_NAME ===''?SALES_HQ_LEV1_NAME:root1.SALES_HQ_LEV1_NAME;

              const root3Sort = sortArrayByPriority([...response.ListRoot2], PRIORITY_ORDER?.CHANNEL_LEV1_NAME, 'CHANNEL_LEV1_NAME');
              this.filteredRoots2([...root3Sort], root1['SALES_HQ_LEV1_NAME']).forEach(root2=>{
                data.push(this.toRowCSV(root2, SALES_HQ_LEV1_NAME, CHANNEL_LEV1_NAME, "Total",SALES_HQ_LEV1_CODE, CHANNEL_LEV1_CODE, "Total", filters));

                CHANNEL_LEV1_CODE = root2.CHANNEL_LEV1_CODE ===''?CHANNEL_LEV1_CODE:root2.CHANNEL_LEV1_CODE;
                CHANNEL_LEV1_NAME = root2.CHANNEL_LEV1_NAME ===''?CHANNEL_LEV1_NAME:root2.CHANNEL_LEV1_NAME;

                const root3Sort = sortArrayByPriority([...response.ListRoot3], PRIORITY_ORDER?.CHANNEL_LEV2_NAME, 'CHANNEL_LEV2_NAME');
                this.filteredRoots3([...root3Sort], root1['SALES_HQ_LEV1_NAME'], root2['CHANNEL_LEV1_NAME']).forEach(root3=>{
                    data.push(this.toRowCSV(root3, SALES_HQ_LEV1_NAME, CHANNEL_LEV1_NAME, CHANNEL_LEV2_NAME,SALES_HQ_LEV1_CODE, CHANNEL_LEV1_CODE, CHANNEL_LEV2_CODE, filters));

                    CHANNEL_LEV2_CODE = root3.CHANNEL_LEV2_CODE ===''?CHANNEL_LEV2_CODE:root3.CHANNEL_LEV2_CODE;
                    CHANNEL_LEV2_NAME = root3.CHANNEL_LEV2_NAME ===''?CHANNEL_LEV2_NAME:root3.CHANNEL_LEV2_NAME;

                    const root4Sort = sortArrayByPriority([...response.ListAll], PRIORITY_ORDER?.CHANNEL_LEV3_NAME, 'CHANNEL_LEV3_NAME');
                    this.filteredRoots4([...root4Sort], root1['SALES_HQ_LEV1_NAME'], root2['CHANNEL_LEV1_NAME'] , root3['CHANNEL_LEV2_NAME']).forEach(root4 => {
                      data.push(this.toRowCSV(root4, SALES_HQ_LEV1_NAME, CHANNEL_LEV1_NAME, CHANNEL_LEV2_NAME,SALES_HQ_LEV1_CODE, CHANNEL_LEV1_CODE, CHANNEL_LEV2_CODE, filters));
                    });
                  }
                )
              });
            });

            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ['Sales Date',
              'Sales HQ Lev1', 
              'Channel Lev1', 
              'Channel Lev2', 
              'Channel Lev3',
              'National/Local',
              'Sales HQ Lev1 Code',
              'Sales HQ Lev1 Name',

              'Channel Lev1 Code',
              'Channel Lev1 Name',

              'Channel Lev2 Code',
              'Channel Lev2 Name',

              'Channel Lev3 Code', 
              'Channel Lev3 Name', 

              'BAPC TY',
              'BAPC BP', //add
              'BAPC% VS BP', 
              'BAPC DIFF VS BP', 
              'BAPC LY', // add
              'BAPC% VS LY', 
              'BAPC DIFF VS LY', // add

              'NSR TY', 
              'NSR BP', // add
              'NSR% VS BP', 
              'NSR DIFF VS BP', 
              'NSR LY', // add
              'NSR% VS LY', 
              'NSR DIFF VS LY', // add

              'NSR PER CASE TY', 
              'NSR PER CASE BP',  // add
              'NSR PER CASE% VS BP',  // add
              'NSR PER CASE DIFF VS BP', 
              'NSR PER CASE LY', // add
              'NSR PER CASE% VS LY', // add
              'NSR PER CASE DIFF VS LY', 

              'GP TY', 
              'GP BP', // add
              'GP% VS BP', 
              'GP DIFF VS BP', 
              'GP LY', // add
              'GP% VS LY', 
              'GP DIFF VS LY', // add

              'GP PER CASE TY', 
              'GP PER CASE BP', // add
              'GP PER CASE% VS BP', //add 
              'GP PER CASE DIFF VS BP', 
              'GP PER CASE LY', //add 
              'GP PER CASE% VS LY',//add 
              'GP PER CASE DIFF VS LY'
            ]
            });
          }
      });
  }

  toRowCSV(row:any, SALES_HQ_LEV1_NAME:any, CHANNEL_LEV1_NAME:any, CHANNEL_LEV2_NAME: any, SALES_HQ_LEV1_CODE:any, CHANNEL_LEV1_CODE:any, CHANNEL_LEV2_CODE: any, filters:any){
    return {SALE_DATE: filters === null?'': filters.saleDate,
        SALE_HQ_LEV1: filters === null?'': String(filters.saleHQLev1),
        CHANNEL_LEV1: filters === null?'':String(filters.channelLev1),
        CHANNEL_LEV2: filters === null?'':String(filters.channelLev2),
        CHANNEL_LEV3: filters === null?'':String(filters.channelLev3),
        CHANNEL_NL : filters == null? '' : String(filters.channel_NL),

        SALES_HQ_LEV1_CODE : !row['SALES_HQ_LEV1_NAME_Display'] ? SALES_HQ_LEV1_CODE : row['SALES_HQ_LEV1_CODE'],
        SALES_HQ_LEV1_NAME : !row['SALES_HQ_LEV1_NAME_Display'] ? SALES_HQ_LEV1_NAME : row['SALES_HQ_LEV1_NAME_Display'], //

        CHANNEL_LEV1_CODE: !row['CHANNEL_LEV1_NAME_Display'] ? CHANNEL_LEV1_CODE : row['CHANNEL_LEV1_CODE'],
        CHANNEL_LEV1_NAME: !row['CHANNEL_LEV1_NAME_Display'] ? CHANNEL_LEV1_NAME : row['CHANNEL_LEV1_NAME_Display'], //

        CHANNEL_LEV2_CODE: !row['CHANNEL_LEV2_NAME_Display'] ? CHANNEL_LEV2_CODE : row['CHANNEL_LEV2_CODE'],
        CHANNEL_LEV2_NAME: !row['CHANNEL_LEV2_NAME_Display'] ? CHANNEL_LEV2_NAME : row['CHANNEL_LEV2_NAME_Display'], //

        CHANNEL_LEV3_CODE: !row['CHANNEL_LEV3_NAME_Display'] ? '' : row['CHANNEL_LEV3_CODE'],
        CHANNEL_LEV3_NAME: !row['CHANNEL_LEV3_NAME_Display'] ? '' : row['CHANNEL_LEV3_NAME_Display'], //


        "BAPC_TY": row['BAPC_TY_Display'] ,
        "BAPC_BP": row['BAPC_BP_Display'] ,
        "BAPC_VS_BP": row['BAPC_VS_BP_Display'] ,
        "BAPC_DIFF_VS_BP": row['BAPC_DIFF_VS_BP_Display'] ,
        "BAPC_LY": row['BAPC_LY_Display'] ,
        "BAPC_VS_LY": row['BAPC_VS_LY_Display'] ,
        "BAPC_DIFF_VS_LY": row['BAPC_DIFF_VS_LY_Display'] ,


        "NSR_TY": row['NSR_TY_Display'] ,
        "NSR_BP": row['NSR_BP_Display'] ,
        "NSR_VS_BP": row['NSR_VS_BP_Display'] ,
        "NSR_DIFF_VS_BP": row['NSR_DIFF_VS_BP_Display'] ,
        "NSR_LY": row['NSR_LY_Display'] ,
        "NSR_VS_LY": row['NSR_VS_LY_Display'] ,
        "NSR_DIFF_VS_LY": row['NSR_DIFF_VS_LY_Display'] ,

        "NSR_PER_CASE_TY": row['NSR_PER_CASE_TY_Display'] ,
        "NSR_PER_CASE_BP": row['NSR_PER_CASE_BP_Display'] ,
        "NSR_PER_CASE_VS_BP": row['NSR_PER_CASE_VS_BP_Display'] ,
        "NSR_PER_CASE_DIFF_VS_BP": row['NSR_PER_CASE_DIFF_VS_BP_Display'] ,
        "NSR_PER_CASE_LY": row['NSR_PER_CASE_LY_Display'] ,
        "NSR_PER_CASE_VS_LY": row['NSR_PER_CASE_VS_LY_Display'] ,
        "NSR_PER_CASE_DIFF_VS_LY": row['NSR_PER_CASE_DIFF_VS_LY_Display'] ,

        "GP_TY": row['GP_TY_Display'] ,
        "GP_BP": row['GP_BP_Display'] ,
        "GP_VS_BP": row['GP_VS_BP_Display'] ,
        "GP_DIFF_VS_BP": row['GP_DIFF_VS_BP_Display'] ,
        "GP_LY": row['GP_LY_Display'] ,
        "GP_VS_LY": row['GP_VS_LY_Display'] ,
        "GP_DIFF_VS_LY": row['GP_DIFF_VS_LY_Display'] ,

        "GP_PER_CASE_TY": row['GP_PER_CASE_TY_Display'] ,
        "GP_PER_CASE_BP": row['GP_PER_CASE_BP_Display'] ,
        "GP_PER_CASE_VS_BP": row['GP_PER_CASE_VS_BP_Display'] ,
        "GP_PER_CASE_DIFF_VS_BP": row['GP_PER_CASE_DIFF_VS_BP_Display'] ,
        "GP_PER_CASE_LY": row['GP_PER_CASE_LY_Display'] ,
        "GP_PER_CASE_VS_LY": row['GP_PER_CASE_VS_LY_Display'] ,
        "GP_PER_CASE_DIFF_VS_LY": row['GP_PER_CASE_DIFF_VS_LY_Display']
    }
  }

  sortDefault(){
    var channelSort=PRIORITY_ORDER?.CHANNEL_LEV1_NAME;
    if(this.lstOption_Selected_ChannelLev == 'CHANNEL_LEV2_NAME') channelSort = PRIORITY_ORDER?.CHANNEL_LEV2_NAME;
    else if (this.lstOption_Selected_ChannelLev == 'CHANNEL_LEV3_NAME') channelSort = PRIORITY_ORDER?.CHANNEL_LEV3_NAME;

    this.hqLevel1RawData.ListRoot1= sortArrayByPriority([...this.hqLevel1RawData.ListRoot1], PRIORITY_ORDER?.SALES_HQ_LEV1_NAME, 'SALES_HQ_LEV1_NAME')
    this.hqLevel1RawData.ListRoot2= sortArrayByPriority([...this.hqLevel1RawData.ListRoot2], channelSort, this.lstOption_Selected_ChannelLev)
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }

  @ViewChild('pdf_print_hq_lev1', { static: false }) pdf_print_hq_lev1: ElementRef;
  exportHQLEV1_PDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing-se pdf-printing");

    const pdfProperty:PDFProperty={
      option: {margin: [40, 0, 40, 40]}
    }

    const area = this.pdf_print_hq_lev1.nativeElement;
    _downloadAsPDF(name, area,false, pdfProperty );
  }
}
