export class PhotoReportFilterHelper {
  static getDefaultFilterList(config: any) {
    const obj = {};

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        obj[key] = [];
      }
    }

    return obj;
  }
}
