import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { DistrictRouterProgressHelper } from './district_route_progress_helper';
import { downloadAsPDF as _downloadAsPDF } from './../../../helpers/pdf';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { AnalyticsQueries } from 'src/app/model/constants';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { SalesDateService } from '../../elements/sales-date-filter/sales-date-filter.service';
import { UserService } from 'src/app/services/user.services';

@Component ({
  selector   : 'app-district-router-progress',
  templateUrl: './district_route_progress.component.html',
  styleUrls  : ['./district_route_progress.component.scss'],
  host     : {
    'class': 'se-report-content'
  }
})

export class DistrictRouterProgressComponent implements OnInit, OnDestroy {
   isLoading: boolean = false;
   private loadingSubscription: Subscription;
   private salesDateSubscription: Subscription

   lstAllOptionsData : any;
   isChangeWeekReady: boolean;
   isdistsrictReady: boolean=false;
   isRouteReady: boolean=false;
   isTimeLineReady: boolean=false;

   //lstOption_SalesDate : any                 = [];
   lstOption_Selected_SalesDate: any         = [];
   lstOption_Selected_SalesDate_Applied: any = [];
   userGroupName: string;

    constructor(private router: Router,
                public datepipe: DatePipe,
                private http: HttpClient,
                private salesDateService: SalesDateService,
                private userService: UserService) {}

    async ngOnInit(){
        if (!authorize()) {
            this.router.navigateByUrl('unauthorized');
        } else {
          this.userGroupName = this.userService.getUserInfo() || '';

          this.loadingSubscription = DistrictRouterProgressHelper.isLoading().subscribe((loading: boolean) => {
            this.isLoading = loading;
          });

          this.salesDateSubscription = this.salesDateService.getSESalesDate().subscribe((date: string[]) => {
            if (date && date.length > 0) {
              DistrictRouterProgressHelper.GetAllFilterOptions(this.http, date[0], {}, this.userGroupName).then((data) => {
               this.lstAllOptionsData = data;
              });
            }
          });
        }
    }

    ngOnDestroy() {
      if (this.loadingSubscription) {
        this.loadingSubscription.unsubscribe();
      }

      if (this.salesDateSubscription) {
        this.salesDateSubscription.unsubscribe();
      }
    }

    public onSalesDateSaveItemsChange = (e: any) => {
      const {keyName, selectedItems} = e;

      if (keyName && selectedItems) {
        this.lstOption_Selected_SalesDate = [...selectedItems];

        this._storeFilterOption();
      }
    }

    public onDateTypeSaveItemsChange(event: any) {
      const {selectedDateType, selectedSalesDate} = event;

      if (selectedDateType) {
        // this.lstOption_Aggregated_DateType = selectedDateType;
      }

      if (selectedSalesDate) {
        this.lstOption_Selected_SalesDate_Applied = [...new Set(selectedSalesDate)];
      }
    }

    private _storeFilterOption(){
      FilterHelper.store_lstOption_States("DistrictRouterProgressComponent",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          this.lstOption_Selected_SalesDate,
          null,
          this.userGroupName);
    }

    public distsrictReady(ready: boolean){
        this.isdistsrictReady = ready;
    }

    public routeReady(ready: boolean){
      this.isRouteReady = ready;
    }

    public timelineReady(ready: boolean){
        this.isTimeLineReady = ready;
    }
}
