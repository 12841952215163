<div id="pdf_print_area_1" #pdf_print_area_1 class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>VM Market Value Share by Category - CCBJI</h3>
            </div>
            <!-- FPT-ThinhLD4: [SENSE] INTAGE page Region Dropdownbox -->
            <div class="filter-section no-title ssmm-filter">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic option-sankey" (change)="onAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Week to Date'}, {value: 'mtd', name: 'Month to Date'}, {value: 'qtd', name: 'Quarter to Date'}, {value: 'ytd', name: 'Year to Date'}]"
                    [model]="aggregateBy"
                    (change)="onAggregateChange($event)">
                </single-dropdown>

                <div class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterRegionShare class="filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="marketShareSelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterShareRegion($event)"
                    (change)="onRemoveFilterShareRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    [clearable]="marketShareSelectedRegion.length > 1"
                    (add)="onAddFilterShareRegion($event)">
                        <ng-option *ngFor="let item of districts" [value]="item"><input type="checkbox" class="cb-multi-select"/>{{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterRegionShare.close();onMarketShareRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    {{item}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>

            </div>
            <div class="card-body">
                <div #scroll class="market-size">
                    <table #screen class="intage-market-size">
                        <tr>
                            <th colspan="3" class="summary-row-col"></th>
                            <th *ngFor="let item of categories; index as i;" [style]="'background-color:' + getCategoryColor(item.category)">{{getCategoryDisplayName(item.category)}}</th>
                        </tr>
                        <!-- // FPT-HoiLT2 [2022-12-21][SENSE][1203240599236569] INTAGE OTC formatting -->
                        <ng-container>
                            <tr>
                                <ng-container>
                                    <td class="first-left-col" [attr.rowspan]="2" colspan="2"><b>Market Size</b></td>
                                </ng-container>
                                <td class="bg-row third-left-col">% vs LY</td>
                                <td class="bg-row" *ngFor="let item of categories; index as i;">
                                    <b [class]="getVsLyOfCategoryForMarketSize(market_size, item.category).indexOf('-') == 0 ? 'text-red': ''">
                                        {{getVsLyOfCategoryForMarketSize(market_size,item.category)}}
                                    </b>
                                </td>
                            </tr>
                            <tr class="last-row">
                                <td class="third-left-col">IMP</td>
                                <td *ngFor="let item of categories; index as j;">{{getImpOfCategory(market_size, item.category)}}</td>
                            </tr>
                        </ng-container>
                        <ng-container>
                            <ng-container>
                                <tr>
                                    <ng-container>
                                        <td class="first-left-col" [attr.rowspan]="2" colspan="2">
                                            <ng-container>
                                                <img class="maker-logo-first-column" src="{{makerLogo[0].logo}}" />
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <td class="bg-row third-left-col">Share</td>
                                    <ng-container *ngFor="let item of categories; index as k;">
                                        <td class="bg-row"><b>{{getShareOfCategory(marketShareData2, "COCA-COLA", item.category)}}</b></td>
                                    </ng-container>
                                </tr>
                                <tr class="last-row">
                                    <td class="third-left-col">Diff.LY</td>
                                    <ng-container *ngFor="let item of categories; index as k;">
                                        <td [class]="getVsLyOfCategory(marketShareData2, 'COCA-COLA', item.category).indexOf('-') == 0 ? 'text-red': ''">{{getVsLyOfCategory(marketShareData2, "COCA-COLA", item.category)}}</td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let maker of competitors_maker_list; index as i;">
                            <ng-container>
                                <tr>
                                    <ng-container *ngIf="i==0">
                                        <td class="first-left-col" [attr.rowspan]="competitors_maker_list.length * 2">
                                            <ng-container>
                                                <span class="maker-name">Competitors</span>
                                            </ng-container>
                                        </td>
                                    </ng-container>
                                    <td rowspan="2" class="second-left-col" [class]="i == competitors_maker_list.length - 1 ? 'last-row-td' : ''" style="min-width: 100px;">
                                        <ng-container *ngIf="maker.logo != ''">
                                            <img class="maker-logo-second-column" src="{{maker.logo}}" style="margin: auto;"/>
                                        </ng-container>
                                        <ng-container *ngIf="maker.logo == ''">
                                            <span class="maker-name">{{maker.maker}}</span>
                                        </ng-container>
                                    </td>
                                    <td class="bg-row third-left-col">Share</td>
                                    <ng-container *ngFor="let item of categories; index as k;">
                                        <td class="bg-row"><b>{{getShareOfCategory(marketShareData2, maker.maker, item.category)}}</b></td>
                                    </ng-container>
                                </tr>
                                <tr [class]="i == competitors_maker_list.length - 1 ? 'last-row' : ''">
                                    <td class="third-left-col">Diff.LY</td>
                                    <ng-container *ngFor="let item of categories; index as k;">
                                        <td [class]="getVsLyOfCategory(marketShareData2, maker.maker, item.category).indexOf('-') == 0 ? 'text-red': ''">{{getVsLyOfCategory(marketShareData2,maker.maker, item.category)}}</td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </table>
                </div>
                <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                    <comment-button title1="VM Market Value Share by Category - CCBJI" [screen]="screen"
                    [chartId]="chartId" [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', marketShareSelectedRegion}}]"></comment-button>
                    <download-button>
                        <button ngbDropdownItem (click)="exportOTCMarketSizeCCBJI(true)">Download PNG</button>
                        <button ngbDropdownItem (click)="exportOTCMarketSizeCCBJI(false)">Download CSV</button>
                        <button ngbDropdownItem (click)="downloadAsPDF('VM Market Value Share by Category - CCBJI')">Download PDF</button>
                    </download-button>
                </div>                
            </div>
             <!--FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG-->
            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>
