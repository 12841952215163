import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import lodashClonedeep from 'lodash.clonedeep';
import moment from 'moment';

import { ProgressRankingHelper } from './progress-ranking-helper';
import { downloadAsPDF as _downloadAsPDF } from '../../../helpers/pdf';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { AnalyticsQueries } from 'src/app/model/constants';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { SalesDateService } from '../../elements/sales-date-filter/sales-date-filter.service';
import { UserService } from 'src/app/services/user.services';

@Component ({
  selector   : 'app-progress-ranking',
  templateUrl: './progress-ranking.component.html',
  styleUrls  : ['./progress-ranking.component.scss'],
  host     : {
    'class': 'se-report-content'
  }
})

export class ProgressRankingComponent implements OnInit, OnDestroy {
   private salesDateSubscription: Subscription;
   pageTitle: string = 'District - Route Progress Rank';
   isChangeWeekReady: boolean;
   isdistsrictReady: boolean=false;
   isRouteReady: boolean=false;
   isTimeLineReady: boolean=false;

   lstOption_SalesDate : any                 = [];
   lstOption_Selected_SalesDate: any         = [];
   lstOption_Selected_SalesDate_Applied: any = [];

   lstOption_RankingUnitany: any             = [];
   userGroupName: string;

    constructor(private router: Router,
                public datepipe: DatePipe,
                private http: HttpClient,
                private salesDateService: SalesDateService,
                private userService: UserService) {}

    async ngOnInit(){
        if (!authorize()) {
            this.router.navigateByUrl('unauthorized');
        } else {
          this.userGroupName = this.userService.getUserInfo() || '';

          this.salesDateSubscription = this.salesDateService.getSESalesDate().subscribe((date: string[]) => {
            if (date && date.length > 0) {
              ProgressRankingHelper.GetAllFilterOptions(this.http, date[0], this.userGroupName).then((data) => {
                const lstSalesYM = data.SALES_YM;
                const lstRankingUnit = data.RANK_UNIT;

                let list_ym = [...new Set(lstSalesYM.map(item => item.SALES_YM))].sort((n1,n2) => {
                       if (n1 > n2) {
                           return 1;
                       }
                       if (n1 < n2) {
                           return -1;
                       }
                       return 0;
               });

               const yesterday        = moment(new Date()).add(-1, 'day');
               const monthofYesterday = yesterday.format('YYYY-MM');

               let option_dataYM        = list_ym.map((item)=> { return { value : item , text: item} });
               option_dataYM            = option_dataYM?.filter(p=> p.value <= monthofYesterday);
               this.lstOption_SalesDate = [{value: 'All', text: 'All'}, ...option_dataYM];

               let option_data_RankingUnitany = lstRankingUnit.map((item)=> { return { value : item.RANK_UNIT , name: item.RANK_UNIT , text: item.RANK_UNIT} });
               this.lstOption_RankingUnitany  = [...this.sortRankingUnit(option_data_RankingUnitany)];
             });
            }
          });
        }
    }

    ngOnDestroy(): void {
      if (this.salesDateSubscription) {
        this.salesDateSubscription.unsubscribe();
      }
    }

    private sortRankingUnit(lstOption_RankingUnitany: any[]) {
      let order: string[] = ["District", "Center", "Distribution Route", "Dealer"];
      let orderMap: { [key: string]: number } = {};
      order.forEach((item, index) => {
          orderMap[item] = index;
      });

      let result = lstOption_RankingUnitany.sort((a, b) => {
          return orderMap[a.value] - orderMap[b.value];
      });

      return result;
  }

    public onSalesDateSaveItemsChange = (e: any) => {
      const {keyName, selectedItems} = e;

      if (keyName && selectedItems) {
        this.lstOption_Selected_SalesDate = [...selectedItems];

        this._storeFilterOption();
      }
    }

    public onDateTypeSaveItemsChange(event: any) {
      const {selectedDateType, selectedSalesDate} = event;

      if (selectedDateType) {
        // this.lstOption_Aggregated_DateType = selectedDateType;
      }

      if (selectedSalesDate) {
        this.lstOption_Selected_SalesDate_Applied =  [...new Set(selectedSalesDate)];
      }
    }

    private _storeFilterOption(){
      FilterHelper.store_lstOption_States("ProgressRankingComponent",
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          this.lstOption_Selected_SalesDate,
          null,
          this.userGroupName);
    }

    public distsrictReady(ready: boolean){
        this.isdistsrictReady = ready;
    }

    public routeReady(ready: boolean){
      this.isRouteReady = ready;
    }

    public timelineReady(ready: boolean){
        this.isTimeLineReady = ready;
    }
}
