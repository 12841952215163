import { environment } from 'src/environments/environment';
import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {AnalyticsHelper} from 'src/app/helpers/analytics';
import { AnalyticsQueries } from 'src/app/model/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as tsvParse from "d3-dsv";
import Swal from 'sweetalert2';
import {DomSanitizer, SafeValue} from '@angular/platform-browser';
import { processHide, processShow } from 'src/app/helpers/process';
import { Router } from '@angular/router';
import { authorize } from 'src/app/helpers/AuthorizePage';
import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
import * as moment from 'moment';
declare let $: any;

const showError = (e) => {
  const { message } = JSON.parse(e.data);
  AnalyticsHelper.openSweetAlert(`<p class="line1">Important Notice</p><p class="line2">${message}</p>`, false);
}

@Component({
  selector: "app-analytics-table",
  templateUrl: "./analytics-table.component.html",
  styleUrls: ["./analytics-table.component.scss"]
})
export class AnalyticsTable implements OnInit {

  startDate: any;
  selectedDate: any;
  ready: boolean;
  checkedSummary: boolean;
  checkedReport: boolean;
  imageProcess: string;
  titleProcess: string;
  desProcess: string;
  percentageProcess:number;
  hasIssue : boolean;
  dailyReportUrl: SafeValue;
  dailyReportHistUrl: SafeValue;
  processReady: boolean;
  activeTab: string;
  setupMessageHandle: boolean;
  dataupdate: string;
  constructor(private router: Router,private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document, private http: HttpClient, private sanitizer : DomSanitizer) {

  }

  ngOnInit() {
    if (!authorize())
    {
      this.router.navigateByUrl('unauthorized');
    }
    else
    {
      //let selectedDate = new Date();
      this.activeTab = localStorage.getItem('activeTab');
      if(this.activeTab == null || this.activeTab == 'Summary'){
        this.activeTab = '';
      }

      var yesterday = moment(new Date()).add(-1, 'day');

      let yesterday_format = yesterday.format('YYYYMMDD'); 

      let queryUrl = environment.hygraphGetJobUpdate;
      let httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
      }
      this.http.get<any>(queryUrl, httpOptions)
      .subscribe(
          response => {
              let res = JSON.parse(response);
              if (res.data[environment.clearCacheJobStatus] != null && res.data[environment.clearCacheJobStatus].length > 0)
              {
                let latest_date = res.data[environment.clearCacheJobStatus][0].date;
                if (yesterday_format == latest_date)
                {
                  this.dataupdate = 'true';
                }
                else
                {
                  this.dataupdate = 'false';
                }
                let year = parseInt(latest_date.substring(0,4));
                let month = parseInt(latest_date.substring(4,6));
                let day = parseInt(latest_date.substring(6,8));
                this.selectedDate = {
                  year: year,
                  month: month,
                  day: day
                };
                this.startDate = {
                  year: year,
                  month: month,
                  day: day
                };        
              }

              let date = this.selectedDate.year + '-' + (this.selectedDate.month < 10 ? '0' + this.selectedDate.month : this.selectedDate.month)
              + '-' + (this.selectedDate.day < 10 ? '0' + this.selectedDate.day : this.selectedDate.day);
              // this.dailyReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.dailyReportUrl.replace('{0}', date).replace('{1}', localStorage.getItem('accessToken')).replace('{2}', this.activeTab));
              this.dailyReportHistUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.dailyReportHistUrl.replace('{0}', date).replace('{1}', localStorage.getItem('accessToken')).replace('{2}', this.activeTab).replace('{3}', this.dataupdate));
              this.bindIconProcess();
              // FPT-TruongDV19 [2022/02/28] Disable scrollbar in daily report page.
              document.body.style.overflowY = 'hidden';
          },
          error => 
          {
              console.log(error);                        
          }
      );
      

      // selectedDate.setDate(selectedDate.getDate() - 1);
      // if(localStorage.getItem('selectedDate') != null){
      //   var arr = localStorage.getItem('selectedDate').split(',');
      //   let year = parseInt(arr[0]);
      //   let month = parseInt(arr[1]);
      //   let date = parseInt(arr[2]);
      //   selectedDate = new Date(year,month,date);
      // }
      // this.startDate.setDate(this.startDate.getDate()-1);
      // if(this.startDate.getUTCHours() >= 15){
      //   this.startDate.setDate(this.startDate.getDate()-1);
      // }
      // if(this.startDate < selectedDate){
      //   selectedDate = this.startDate;
      //   let year = selectedDate.getFullYear();
      //   let month = selectedDate.getMonth();
      //   let date = selectedDate.getDate();         
      //   localStorage.setItem('selectedDate', year + ',' + month + ',' + date);
      // }
      // this.selectedDate = {
      //   year: selectedDate.getFullYear(),
      //   month: selectedDate.getMonth() +1,
      //   day: selectedDate.getDate()
      // };
    }

    window.addEventListener('message', showError);
  }

  ngOnDestroy() {
    document.body.style.overflowY = 'auto';

    window.removeEventListener("message", showError, false);

    processHide();
  }

  handleLoading(){
    this.processReady = true;

    processShow({ imageProcess: this.imageProcess, titleProcess: this.titleProcess, percentageProcess: this.percentageProcess, desProcess: this.desProcess });

    if(!this.setupMessageHandle){
      this.setupMessageHandle = true;
      let t=this;
      window.addEventListener('message', function (e) {
        if(e.data != null && typeof e.data == "string"){
          if(e.data.indexOf('activeTab_') == 0){
            t.activeTab = e.data.replace('activeTab_', '');
            if(t.activeTab == 'Summary'){
              t.activeTab = '';
            }
            localStorage.setItem('activeTab', t.activeTab);
          }
        }
      });
    }
  }
  public reportLoaded(){
    this.ready = true;
  }
  public onDateSelect(event){
    dispatchDateSelectChange({...event, ...{element:"daily-report"}});
    if(document.getElementById('analytics-data') != null){
      document.getElementById('analytics-data').scrollLeft = 0;
      document.getElementById('analytics-data').scrollTop = 0;
    }
    let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let year = selectedDate.getFullYear();
    let month = selectedDate.getMonth();
    let date = selectedDate.getDate();         
    localStorage.setItem('selectedDate', year + ',' + month + ',' + date);
    this.checkedReport = false;
    this.checkedSummary = false;
    this.ready = false;
    let day = this.selectedDate.year + '-' + (this.selectedDate.month < 10 ? '0' + this.selectedDate.month : this.selectedDate.month)
    + '-' + (this.selectedDate.day < 10 ? '0' + this.selectedDate.day : this.selectedDate.day);

    // this.dailyReportUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.dailyReportUrl.replace('{0}', date).replace('{1}', localStorage.getItem('accessToken')).replace('{2}', this.activeTab));
    this.dailyReportHistUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.dailyReportHistUrl.replace('{0}', day).replace('{1}', localStorage.getItem('accessToken')).replace('{2}', this.activeTab).replace('{3}', this.dataupdate));
    this.bindIconProcess();
  }

  load()
  {
    //Wait for data check from indexeddb before calling script
    if(this.checkedSummary && this.checkedReport){
      this._document.getElementById("analytics-data").innerHTML = '';
      AnalyticsHelper.loadScript(this._renderer2, this._document);
      this.handleLoading();
    }
    else{
      let t = this;
      setTimeout(() => { 
        t.load()
      }, 100);
    }
  }

  public bindIconProcess() {
    this.processReady = false;
    var currentDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    let startDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    startDate.setDate(1);
    let today = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month +
      (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
      let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.percentPerformance + "&date=" + today;
    // let startMonth = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month + '01';
    // let month = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month;
    // queryUrl = queryUrl.replace('{0}', startMonth).replace('{0}', startMonth).replace('{1}', today).replace('{2}', month);
    var accessToken = 'Bearer ' + localStorage.getItem('accessToken');
    let httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', accessToken),
      responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
      .subscribe(
        response => {
          let arr = tsvParse.tsvParse('act	id\n' + response);
          if(arr[0].act == ''){
            arr[0].act = 0;
          }
          if(arr.length > 1 && arr[1].act == ''){
            arr[1].act = 0;
          }
          let percent = Math.round((parseFloat(arr[0].act) / parseFloat(arr[1].act)) * 100);
          this.titleProcess = 'Progress from <b>' + startDate.getFullYear() + '/' + ("0" + (startDate.getMonth() + 1)).slice(-2) + '/' + startDate.getDate() + '</b> to <b>'
            + currentDate.getFullYear() + '/' + ("0" + (currentDate.getMonth() + 1)).slice(-2) + '/' + currentDate.getDate() + '</b><br/>' + (parseFloat(arr[0].act) / 1000000).toFixed(1) + ' MM / ' + (parseFloat(arr[1].act) / 1000000).toFixed(1) + ' MM';
          this.percentageProcess = percent;
          this.desProcess = '*This chart is using the most updated master data and transaction data from HANA';
          if (percent < 10) {
            this.imageProcess = 'assets/img/image_process/0_percent.png';
          } else if (percent < 20) {
            this.imageProcess = 'assets/img/image_process/10_percent.png';
          } else if (percent < 30) {
            this.imageProcess = 'assets/img/image_process/20_percent.png';
          } else if (percent < 40) {
            this.imageProcess = 'assets/img/image_process/30_percent.png';
          } else if (percent < 50) {
            this.imageProcess = 'assets/img/image_process/40_percent.png';
          } else if (percent < 60) {
            this.imageProcess = 'assets/img/image_process/50_percent.png';
          } else if (percent < 70) {
            this.imageProcess = 'assets/img/image_process/60_percent.png';
          } else if (percent < 80) {
            this.imageProcess = 'assets/img/image_process/70_percent.png';
          } else if (percent < 90) {
            this.imageProcess = 'assets/img/image_process/80_percent.png';
          } else if (percent < 100) {
            this.imageProcess = 'assets/img/image_process/90_percent.png';
          } else {
            this.imageProcess = 'assets/img/image_process/perfect_percent.png';
          };
          this.handleLoading();
        },
        error =>
         {
          console.log(error);
          if (error.status == '401')
          {
            this.router.navigateByUrl('authentication/login');
          }
        }
      );
  }

}