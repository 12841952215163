import { Component, Renderer2, OnInit, Inject, ViewChild, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
@Component({
  selector: 'nsr-trend',
  templateUrl: './nsr-trend.component.html',
  styleUrls: ['./nsr-trend.component.scss']
})
export class NsrTrendComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedDate: any;
  @Input() monthlyData: any;
  nsrSalesTrendChart: ApexCharts;
  channels: any;
  displayVs2019: boolean;
  displayBP: boolean;
  salesTrendChannel: any;
  populated:boolean;
  salesTrends: any;
  chartId = "nsr-sales-trend-chart";
  series: any;

  constructor(private router: Router, public datepipe: DatePipe) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  ngOnChanges() {
    if(!this.populated){
      this.channels = [];
      this.channels.push(
        {
          name: 'All',
          level: 1
        });
      let channels = AnalyticsHelper.ChannelLv2();
      channels.push({
        code: [41],
        channel: 'Alcohol',
        level: 1,
        region: ['7200']
      });
      for(let i=0;i<channels.length;i++){
        let channel = channels[i];
        if(channel.channel == 'Vending_FS' || channel.channel == 'Vending_REG'){
          continue;
        }
        this.channels.push({
          name: channel.channel,
          level: channel.level
        })
      }
      this.displayVs2019 = true;
      this.displayBP = true;
      this.salesTrendChannel = ['All'];
      this.populated = true;
    }
    if (this.selectedDate != null && this.monthlyData != null)
    {
      let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
      this.handleNsrSalesData(selectedDate);
    }
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["salesTrendChannel"] = this.salesTrendChannel;
    } else {
      this.salesTrendChannel = window["salesTrendChannel"];
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }    
  onRemoveFilter($event) {
    if (this.salesTrendChannel.length == 0) {
      this.salesTrendChannel = ['All'];
      this.SaveOptionValueTemp(false);
      this.renderNsrSalesTrendChart()
    }
    if ($event.value === 'All') {
      this.salesTrendChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      let arrChilds = AnalyticsHelper.getSmddChild($event.value);
      this.salesTrendChannel = this.salesTrendChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      let parent = AnalyticsHelper.getSmddParent($event.value);
      this.salesTrendChannel = this.salesTrendChannel.filter(e => e !== parent);
    }
  }
  onAddFilter($event) {
    if ($event === 'All') {
      this.salesTrendChannel = ['All'];
      return;
    } else {
      this.salesTrendChannel = this.salesTrendChannel.filter(e => e !== 'All');
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      let arrChilds = AnalyticsHelper.getSmddChild($event);
      this.salesTrendChannel = this.salesTrendChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      let parent = AnalyticsHelper.getSmddParent($event);
      let checkChildSelected = true;
      let arrChilds = AnalyticsHelper.getSmddChild(parent);
      arrChilds.forEach(child => {
        if (!this.salesTrendChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        let arrTemp = [parent];
        this.salesTrendChannel = this.salesTrendChannel.filter(e => !arrChilds.includes(e));
        this.salesTrendChannel = arrTemp.concat(this.salesTrendChannel);
      } else {
        this.salesTrendChannel = this.salesTrendChannel.filter(e => e !== parent);
      }
    }
    if (!this.salesTrendChannel.some(x => x === 'All') && (this.salesTrendChannel.length + 1) == this.channels.length) {
      this.salesTrendChannel = ['All'];
    }
    let checkParentFilter = true;
    let filterNotCheck = this.channels.filter(e => !this.salesTrendChannel.includes(e.name) && e.name !== 'All');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element.name) || !this.salesTrendChannel.includes(AnalyticsHelper.getSmddParent(element.name))) {
        checkParentFilter = false;
      }
    });
    if (checkParentFilter) {
      this.salesTrendChannel = ['All'];
      return;
    }
  }
  handleNsrSalesData(selectedDate: Date) {
    let months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    this.salesTrends = [];

    for(let i=0;i<this.monthlyData.length;i++){
      let row = this.monthlyData[i];
      if(row.month.indexOf('Full') > 0){
        continue;
      }
      let monthName = months[parseInt(row.month.substring(4,6))-1] + '-' + row.month.substring(0,4);
      let salesTrend = this.salesTrends.find(x=> x.month == monthName);
      if(salesTrend == null){
        if(this.salesTrends.length > 11){
          continue;
        }
        salesTrend = {
          month: monthName,
          monthNo: row.month.substring(4,6),
          ty: parseFloat(row.nsr_ty),
          ly: parseFloat(row.nsr_ly),
          bp: parseFloat(row.nsr_bp),
          ty2019: 0,
          channels: []
        };
        this.salesTrends.push(salesTrend);
      }
      else{
        salesTrend.ty += parseFloat(row.nsr_ty);
        salesTrend.ly += parseFloat(row.nsr_ly);
        salesTrend.bp += parseFloat(row.nsr_bp);
      }
      // [FPT-ThinhLD4][1204061681619804] Dashboard Channel filter update
      let currentChannels = AnalyticsHelper.ChannelLv2();
      currentChannels.push({
        code: [41],
        channel: 'Alcohol',
        level: 1,
        region: ['7200']
      });
      currentChannels = currentChannels.filter(x=> x.code.find(y=> row.channelCode.indexOf(y) == 0) != null && (!x.region || x.region.includes(row.regionCode)));
      for(let j=0;j<currentChannels.length;j++){
        let currentChannel = currentChannels[j];
        let channel = salesTrend.channels.find(x=> x.channel == currentChannel.channel);
        if(channel == null){
          channel = {
            channel: currentChannel.channel,
            ty: parseFloat(row.nsr_ty),
            ly: parseFloat(row.nsr_ly),
            bp: parseFloat(row.nsr_bp),
            ty2019: 0
          };
          salesTrend.channels.push(channel);
        }else{
          channel.ty += parseFloat(row.nsr_ty);
          channel.ly += parseFloat(row.nsr_ly);
          channel.bp += parseFloat(row.nsr_bp);
        }
      }
    }

    // [FPT-ThinhLD4][1204061681619804] Dashboard Channel filter update
    const chanels = AnalyticsHelper.ChannelLv2();
    chanels.push({
      code: [41],
      channel: 'Alcohol',
      level: 1,
      region: ['7200']
    });
    for(let i=0;i<this.salesTrends.length;i++){
      let salesTrend = this.salesTrends[i];
      let data = this.monthlyData.filter(x=> x.month == '2019' + salesTrend.monthNo);
      for(let j=0;j<data.length;j++){
        let row = data[j];
        salesTrend.ty2019 += parseFloat(row.nsr_ty);
        let currentChannels = chanels.filter(x=> x.code.find(y=> row.channelCode.indexOf(y) == 0) != null && (!x.region || x.region.includes(row.regionCode)));
        for(let k=0;k<currentChannels.length;k++){
          let currentChannel = currentChannels[k];
          let channel = salesTrend.channels.find(x=> x.channel == currentChannel.channel);
          if(channel != null){
            channel.ty2019 += parseFloat(row.nsr_ty);
          }
        }
      }
    }

    for(let i=0;i<this.salesTrends.length;i++){
      let salesTrend = this.salesTrends[i];
      salesTrend.vsLy = AnalyticsHelper.percent(salesTrend.ty, salesTrend.ly, 2);
      salesTrend.vsBp = AnalyticsHelper.percent(salesTrend.ty, salesTrend.bp, 2);
      salesTrend.vs2019 = AnalyticsHelper.percent(salesTrend.ty, salesTrend.ty2019, 2);
      for(let j=0;j<salesTrend.channels.length;j++){
        let channel = salesTrend.channels[j];
        channel.vsLy = AnalyticsHelper.percent(channel.ty, channel.ly, 2);
        channel.vsBp = AnalyticsHelper.percent(channel.ty, channel.bp, 2);
        channel.vs2019 = AnalyticsHelper.percent(channel.ty, channel.ty2019, 2);
      }
    }

    this.salesTrends = this.salesTrends.reverse();
    this.renderNsrSalesTrendChart();
  }
  renderNsrSalesTrendChart() {
    if (this.nsrSalesTrendChart != null) {
      this.nsrSalesTrendChart.destroy();
    }
    let t = this;
    this.series = [];
    let colors = [];
    if (this.salesTrendChannel.length == 0) {
      this.salesTrendChannel = ["All"]
    }
    if (this.salesTrendChannel.some(x => x === 'All')) {
      this.series.push({
        name: 'Vs LY',
        data: this.salesTrends.map(x => x.vsLy.toFixed(1))
      });
      if (this.displayVs2019) {
        this.series.push({
          name: 'Vs 2019',
          data: this.salesTrends.map(x => x.vs2019 !='∞' ? x.vs2019.toFixed(1) : '∞')
        });
      } else {
        this.series.push({
          name: 'Vs BP',
          data: this.salesTrends.map(x => x.vsBp.toFixed(1))
        });
      }
    }
    else {
      let actResult = [];
      let lyResult = [];
      let bpResult = [];
      let data2019Result = [];
      this.salesTrendChannel.forEach(element => {
        let actArr = this.salesTrends.map(x => x.channels.find(y => y.channel == element).ty);
        let bpArr = this.salesTrends.map(x => x.channels.find(y => y.channel == element).bp);
        let lyArr = this.salesTrends.map(x => x.channels.find(y => y.channel == element).ly);
        let data2019Arr = this.salesTrends.map(x => x.channels.find(y => y.channel == element).ty2019);
        actResult = AnalyticsHelper.sumArray(actResult, actArr);
        lyResult = AnalyticsHelper.sumArray(lyResult, lyArr);
        bpResult = AnalyticsHelper.sumArray(bpResult, bpArr);
        data2019Result = AnalyticsHelper.sumArray(data2019Result, data2019Arr);
      });
      this.series.push({
        name: 'Vs LY',
        data: AnalyticsHelper.percentArray(actResult, lyResult)
      });
      if (this.displayVs2019) {
        this.series.push({
          name: 'Vs 2019',
          data: AnalyticsHelper.percentArray(actResult, data2019Result)
        });
      } else {
        this.series.push({
          name: 'Vs BP',
          data: AnalyticsHelper.percentArray(actResult, bpResult)
        });
      }
    }
    colors.push('#5FB0FF');
    if (this.displayVs2019) {
      colors.push('#58C9D0');
    } else {
      colors.push('#F40009');
    }
    const options = {
      chart: {
        id: this.chartId,
        height: 310,
        type: 'area',
        toolbar: {
          tools: {
            zoomin: false,
            zoomout: false,
            selection: false,
            zoom: false,
            pan: false,
            reset: false,
            download: ''
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        dashArray: [0, 0, 5]
      },
      colors: colors,
      series: this.series,
      legend: {
        show: true,
        onItemClick: {
          toggleDataSeries: false
        }
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6
        }
      },
      xaxis: {
        categories: this.salesTrends.map(x => x.month),
      },
      grid: {
        borderColor: '#f1f1f1',
      },
      // yaxis: {
      //   labels: {
      //     formatter: function (val: number) {
      //       return AnalyticsHelper.formatNumber(val);
      //     }
      //   }
      // }
    }

    document.querySelector(`#${this.chartId}`).innerHTML = '';
    this.nsrSalesTrendChart = new ApexCharts(
      document.querySelector(`#${this.chartId}`),
      options
    );
    this.nsrSalesTrendChart.render().then(() => setTimeout(() => {
      t.ready.emit(true);
    }));;
  }

  public onVsRadioSelect(value: boolean) {
    this.displayVs2019 = value;
    this.renderNsrSalesTrendChart();
  }
  public isSmddChild(channel: string) {
    if (channel == "SM" || channel == "Drug" || channel == "Discounter" || channel == "D&D") {
      return true;
    }
    // [FPT-HoiLT][1204061681619804] Dashboard Channel filter update
    if (channel == "Retail" || channel == "Food") {
      return true;
    }
    return false;
  }

  downloadPng() {
    this.nsrSalesTrendChart["exports"]?.exportToPng()
  }

  downloadCSV() {
    let data = [];
    const year = this.selectedDate.year;  // Get the year (e.g., 2024)
    const month = String(this.selectedDate.month).padStart(2, '0');  // Get the month (0-11), so add 1 and pad with '0' (e.g., 08 for August)
    const day = String(this.selectedDate.day).padStart(2, '0');
    let fileName = `NsrTrend_${year}-${month}-${day}`;

    for (let i = 0; i < this.salesTrends.length; i++)
    {
      let month = this.salesTrends[i].month.replace('-', '_');
      data.push({
        month: month,
        channel: this.salesTrendChannel.join(','),
        vsLy: this.series.find(x => x.name == 'Vs LY')?.data[i],
        vs2019orBP: this.displayVs2019 ? this.series.find(x => x.name == 'Vs 2019')?.data[i] : this.series.find(x => x.name == 'Vs BP')?.data[i]
      });    
    }

    let headers = this.displayVs2019 ? ["Month", "Channel", "VsLy", "Vs2019"] : ["Month", "Channel", "VsLy", "VsBP"];
    new AngularCsv(data, fileName, {
      showLabels: true,
      headers: headers
    });
  }
  
  getChart() {
    return this.nsrSalesTrendChart
  }
}
