<div id="sales-by-sku">
  <div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h3>Sales By SKU</h3>
    </div>
    <div class="filter-body">
      <!-- <b>Filter By</b> -->
      <div class="row seven-cols">
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Sales Area </b>
          <ng-select #ngFilterSalesArea class="filter-by" [searchable]="true" [virtualScroll]="true"
            [(ngModel)]="salesArea" [multiple]="true" [closeOnSelect]="false" (close)="onCloseSalesArea()"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterSalesArea($event);onChangeCheckBox($event)"
            (open)="setSalesArea();onChangeCheckBox($event)" (add)="onAddFilterSalesArea($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of salesAreaList" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="salesArea.length == 0"
                (click)="SalesAreaAggregate();ngFilterSalesArea.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Sales District </b>
          <ng-select #ngFilterSalesDistrict class="filter-by" [searchable]="true" [virtualScroll]="true"
            [(ngModel)]="salesDistrict" [multiple]="true" [closeOnSelect]="false" (close)="onCloseSalesDistrict()"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterSalesDistrict($event);onChangeCheckBox($event)"
            (open)="setSalesDistrict();onChangeCheckBox($event)" (add)="onAddFilterSalesDistrict($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of salesDistrictBinding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="salesDistrict.length == 0"
                (click)="SalesDistrictAggregate();ngFilterSalesDistrict.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <div style="white-space: pre"><b>Channel Level 1 </b></div>
          <ng-select #ngFilterChannelLev1 class="filter-by" [searchable]="true" [virtualScroll]="true"
            (scroll)="onChangeCheckBox($event)"[(ngModel)]="channelLev1" [multiple]="true" [closeOnSelect]="false" (close)="onCloseChannelLevel1()"
            [clearable]="false" (remove)="onRemoveFilterChannelLev1($event);onChangeCheckBox($event)"
            (open)="setChannelLevel1();onChangeCheckBox($event)" (add)="onAddFilterChannelLev1($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of channelLev1List" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="channelLev1.length == 0"
                (click)="ChannelLevel1Aggregate();ngFilterChannelLev1.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Brand Name </b>
          <ng-select #ngFilterBrandName class="filter-by" [loading]="loadingBrandList" [searchable]="true" [virtualScroll]="true"
            (search)="searchBrandName($event)" (scrollToEnd)="fetchMoreBrandName(ngFilterBrandName.searchTerm)"
            (scroll)="onChangeCheckBox($event)" (close)="onCloseBrandName()"
            [(ngModel)]="brandName" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterBrandName($event);onChangeCheckBox($event)"
            (open)="setBrandName();onChangeCheckBox($event)" (add)="onAddFilterBrandName($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of selectedBrand" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of brandnameList_Binding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="brandName.length == 0"
                (click)="BrandNameAggregate(); ngFilterBrandName.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Category </b>
          <ng-select #ngFilterCategory class="filter-by" [searchable]="true" [loading]="loadingCategory" [virtualScroll]="true"
            (scroll)="onChangeCheckBox($event)" (close)="onCloseCategories()"
            (search)="searchCategories($event)" (scrollToEnd)="fetchMoreCategories(ngFilterCategory.searchTerm)"
            [(ngModel)]="category" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterCategory($event);onChangeCheckBox($event)"
            (open)="setCategory();onChangeCheckBox($event)" (add)="onAddFilterCategory($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of selectedCategory" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of categoryBinding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="category.length == 0"
                (click)="CategoryAggregate();ngFilterCategory.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Product Name JP </b>
          <ng-select #ngFilterProductNameJP class="filter-by" [loading]="loading"  [searchable]="true" [virtualScroll]="true"
            (search)="searchProductNameJP($event)" 
            (scroll)="onChangeCheckBox($event)" (close)="onCloseProductname()"
            [(ngModel)]="productNameJP" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterProductNameJP($event);onChangeCheckBox($event)"
            (open)="setProductName();onChangeCheckBox($event)" (add)="onAddFilterProductNameJP($event);onChangeCheckBox($event)"
            (scrollToEnd)="fetchMore(ngFilterProductNameJP.searchTerm)">
            <ng-option *ngFor="let item of selectedProductName" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of productname_jpBinding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="productNameJP.length == 0"
                (click)="ProductNameAggregate();ngFilterProductNameJP.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Jan Code </b>
          <ng-select #ngFilterJanCode class="filter-by" [loading]="loadingJanCode" [searchable]="true" [virtualScroll]="true" 
            (scroll)="onChangeCheckBox($event)" (close)="onCloseJanCodes()"
            (search)="searchJanCode($event)"
            [(ngModel)]="janCode" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterJanCode($event);onChangeCheckBox($event)"
            (open)="setJanCode();onChangeCheckBox($event)" (add)="onAddFilterJanCode($event);onChangeCheckBox($event)" (scrollToEnd)="fetchMoreJanCode(ngFilterJanCode.searchTerm)">
            <ng-option *ngFor="let item of selectedJanCode" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of janCode_Binding" [value]="item">
                <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="janCode.length == 0"
                (click)="JanCodeAggregate();ngFilterJanCode.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div id="jancode" class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Container </b>
          <ng-select #ngFilterContainer class="filter-by" [searchable]="true" [virtualScroll]="true" [loading]="loadingContainer"
            (scroll)="onChangeCheckBox($event)" (close)="onCloseContainer()"
            (search)="searchContainer($event)" (scrollToEnd)="fetchMoreContainer(ngFilterContainer.searchTerm)"
            [(ngModel)]="container" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterContainer($event);onChangeCheckBox($event)"
            (open)="setContainer();onChangeCheckBox($event)" (add)="onAddFilterContainer($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of selectedContainer" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of containerBinding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="container.length == 0"
                (click)="ContainerAggregate();ngFilterContainer.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Package Lev1 </b>
          <ng-select #ngFilterPackageLev1 class="filter-by" [searchable]="true" [virtualScroll]="true" [loading]="loadingPackageLev1"
            (scroll)="onChangeCheckBox($event)" (close)="onClosePackageLev1()"
            (search)="searchPackageLev1($event)" (scrollToEnd)="fetchMorePackageLev1(ngFilterPackageLev1.searchTerm)"
            [(ngModel)]="packageLev1" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterPackageLev1($event);onChangeCheckBox($event)"
            (open)="setPackageLev1();onChangeCheckBox($event)" (add)="onAddFilterPackageLev1($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of selectedPackageLev1" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of pkgLev1Binding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="packageLev1.length == 0"
                (click)="PackageLev1Aggregate();ngFilterPackageLev1.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>  
      </div>
      <div class="row seven-cols">  
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Group By</b>
          <ng-select #ngFiltergroupByColumns class="filter-by" [searchable]="true" [virtualScroll]="true" (scroll)="onChangeCheckBox($event)"
            [(ngModel)]="groupByColumns" [multiple]="true" [closeOnSelect]="false" (close)="onClosegroupByColumns();onChangeCheckBox($event)"
            [clearable]="false" (remove)="onRemovegroupByColumns($event);onChangeCheckBox($event)"
            (open)="setgroupByColumns();onChangeCheckBox($event)" (add)="onAddgroupByColumns($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of columnList" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="groupByColumns.length == 0"
                (click)="groupByColumnsAggregate();ngFiltergroupByColumns.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>            
        <div class="col-lg-1 col-md-6 filter-col">
          <button type="submit" class="btn btn-primary mt-20"
                            (click)="searchSkuData()">Search</button>
        </div>
      </div>
    </div>
    <div class="text-center spinner-wrapper sku_spinner">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="card-body mt-3 sku_table">
      <ng-container>
        <div class="d-flex flex-row justify-content-between">
            <button (click)="htmlTableToExcel('xlsx')" class="hover:underline transition duration-150 header-menu-title excel-download-title" style="color: green">Excel抽出</button>
            <div class="d-flex flex-row justify-content-between">
              <pagination-controls id="skuPage" (pageChange)="handlePageChange($event)"></pagination-controls>
              <label class="font-bold" style="margin-left: 50px; transform: translate(10%, 10%)" for="fname">Go to Page: </label>  
                <input  type="number" id="gotopage" style="width: 50px; margin-left: 10px; transform: translate(10%, -15%)" class="whitespace-nowrap px-2 py-1 border-l border-gray-500 top-0 left-0 font-bold border-t border-b border-r" type="text">
                <i (click)="GotoPageEvent();" style="cursor: pointer; transform: translate(20%, -10%);" class='bx bx-md bxs-right-arrow-square'></i>
            </div>
        </div>
      </ng-container>
      <br>
      <div class="sales-by-sku-area">
        <table class="w-full rounded shadow relative" id="skuHeader">
          <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
            <tr>
              <th colspan="18" class="text-center border-l border-gray-500 bg-red-100 text-lg top-0 left-0 font-bold" *ngIf="aggregateByDisplay == 'MTD';">
                {{aggregateByDisplay}}
              </th>         
              <th colspan="18" class="text-center border-l border-gray-500 bg-blue-100 text-lg top-0 left-0 font-bold" *ngIf="aggregateByDisplay == 'Daily';">
                {{aggregateByDisplay}}
              </th>
              <th colspan="18" class="text-center border-l border-gray-500 bg-gray-blue-100 text-lg top-0 left-0 font-bold" *ngIf="aggregateByDisplay == 'YTD';">
                {{aggregateByDisplay}}
              </th>
            </tr>
            </thead>
        </table>
      </div>
      <div class="sales-by-sku-area">
        <table *ngIf="SKUdisplayColumns && SKUdisplayColumns.length > 0; else show_all" class="w-full rounded shadow relative" id="skuId">
            <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
              <tr class="text-xs font-normal text-center border-b border-gray-500">

                <ng-container *ngFor="let columnName of SKUMappingdisplayColumns">
                  <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">{{columnName}}</th>
                </ng-container>
    
                <th style="cursor: pointer;" (click)="sort('bapc_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC TY </div> <div style="display: inline-block;"><i id="bapc_tyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_tyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div> </th>
                <th style="cursor: pointer;" (click)="sort('bapc_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC LY </div> <div style="display: inline-block;"><i id="bapc_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>             
                <th style="cursor: pointer;" (click)="sort('bapc_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC % vs LY </div> <div style="display: inline-block;"><i id="bapc_per_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_per_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('nsr_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR TY </div> <div style="display: inline-block;"><i id="nsr_tyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_tyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('nsr_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR LY </div> <div style="display: inline-block;"><i id="nsr_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('nsr_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR % vs LY </div> <div style="display: inline-block;"><i id="nsr_per_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_per_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('gp_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP TY </div> <div style="display: inline-block;"><i id="gp_tyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_tyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('gp_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP LY </div> <div style="display: inline-block;"><i id="gp_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('gp_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> GP % vs LY </div> <div style="display: inline-block;"><i id="gp_per_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_per_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
              </tr>
            </thead>
            <tbody class="text-xs">
              <ng-container *ngIf="!displayData || displayData.length === 0">
                <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'skuPage', itemsPerPage: pagination.sizeOnPage, currentPage: 1, totalItems: 0 }">
                </ng-container>
                <tr>
                  <td class="px-2 py-1 border-l border-gray-500 whitespace-nowrap border-r" colspan="23">No items founds</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="displayData && displayData.length > 0">
                <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'skuPage', itemsPerPage: pagination.sizeOnPage, currentPage: pagination.current, totalItems: pagination.totalItem }; index as i">
                  <tr>
                    <ng-container *ngFor="let columnName of SKUdisplayColumns">
                      <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer[columnName]}}</td>
                    </ng-container>
                  
                    <td *ngIf="formatNumber(skuCustomer['bapc_ty']) != 'NaN'; else bapc_ty_NA" class="text-right px-2 py-1 border-l border-b border-gray-500">{{formatNumber(skuCustomer['bapc_ty'])}}</td>
                    <ng-template #bapc_ty_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template>  


                    <td *ngIf="formatNumber(skuCustomer['bapc_ly']) != 'NaN'; else bapc_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['bapc_ly'])}}</td>
                    <ng-template #bapc_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template>    
                    
                    
                    <td *ngIf="formatNumber(skuCustomer['bapc_per_ly']) != 'NaN'; else bapc_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['bapc_per_ly'])}}</td>
                    <ng-template #bapc_per_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                    </ng-template>   


                    <td *ngIf="formatNumber(skuCustomer['nsr_ty']) != 'NaN'; else nsr_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ty'])}}</td>
                    <ng-template #nsr_ty_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 


                    <td *ngIf="formatNumber(skuCustomer['nsr_ly']) != 'NaN'; else nsr_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ly'])}}</td>
                    <ng-template #nsr_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 

                    <td *ngIf="formatNumber(skuCustomer['nsr_per_ly']) != 'NaN'; else nsr_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['nsr_per_ly'])}}</td>
                    <ng-template #nsr_per_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                    </ng-template> 

                    <td *ngIf="formatNumber(skuCustomer['gp_ty']) != 'NaN'; else gp_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ty'])}}</td>
                    <ng-template #gp_ty_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 


                    <td *ngIf="formatNumber(skuCustomer['gp_ly']) != 'NaN'; else gp_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ly'])}}</td>
                    <ng-template #gp_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 


                    <td *ngIf="formatNumber(skuCustomer['gp_per_ly']) != 'NaN'; else gp_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['gp_per_ly'])}}</td>
                    <ng-template #gp_per_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                    </ng-template> 
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>

          <ng-template #show_all>
              <table class="w-full rounded shadow relative" id="skuId">
                <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
                  <tr class="text-xs font-normal text-center border-b border-gray-500">
                    <th class="whitespace-nowrap px-2 py-1 border-l border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Jan Code</th>
                    <th class="whitespace-nowrap px-2 py-1 border-l border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Product Name En</th>
                    <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Product Name JP</th>
                    <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Package Lev1</th>
                    <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Brand Name</th>
                    <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Category</th>
                    <!-- <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Container</th> -->
        
                    <th style="cursor: pointer;" (click)="sort('bapc_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC TY </div> <div style="display: inline-block;"><i id="bapc_tyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_tyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div> </th>
                    <th style="cursor: pointer;" (click)="sort('bapc_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC LY </div> <div style="display: inline-block;"><i id="bapc_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>             
                    <th style="cursor: pointer;" (click)="sort('bapc_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC % vs LY </div> <div style="display: inline-block;"><i id="bapc_per_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_per_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                    <th style="cursor: pointer;" (click)="sort('nsr_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR TY </div> <div style="display: inline-block;"><i id="nsr_tyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_tyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                    <th style="cursor: pointer;" (click)="sort('nsr_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR LY </div> <div style="display: inline-block;"><i id="nsr_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                    <th style="cursor: pointer;" (click)="sort('nsr_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR % vs LY </div> <div style="display: inline-block;"><i id="nsr_per_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_per_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                    <th style="cursor: pointer;" (click)="sort('gp_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP TY </div> <div style="display: inline-block;"><i id="gp_tyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_tyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                    <th style="cursor: pointer;" (click)="sort('gp_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP LY </div> <div style="display: inline-block;"><i id="gp_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                    <th style="cursor: pointer;" (click)="sort('gp_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> GP % vs LY </div> <div style="display: inline-block;"><i id="gp_per_lyASC" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_per_lyDESC" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                    <th class="whitespace-nowrap px-2 py-1 border-l border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Sales Area</th>
                    <th class="whitespace-nowrap px-2 py-1 border-l border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Sales District</th>
                    <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Channel Level 1</th>
                  </tr>
                </thead>
                <tbody class="text-xs">
                  <ng-container *ngIf="!displayData || displayData.length === 0">
                    <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'skuPage', itemsPerPage: pagination.sizeOnPage, currentPage: 1, totalItems: 0 }">
                    </ng-container>
                    <tr>
                      <td class="px-2 py-1 border-l border-gray-500 whitespace-nowrap border-r" colspan="23">No items founds</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="displayData && displayData.length > 0">
                    <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'skuPage', itemsPerPage: pagination.sizeOnPage, currentPage: pagination.current, totalItems: pagination.totalItem }; index as i">
                      <tr>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['janCd']}}</td>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['janNmEn']}}</td>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['janNmJp']}}</td>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['pkgLev1']}}</td>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['brand']}}</td>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['category']}}</td>
                  
      
                        <td *ngIf="formatNumber(skuCustomer['bapc_ty']) != 'NaN'; else bapc_ty_NA" class="text-right px-2 py-1 border-l border-b border-gray-500">{{formatNumber(skuCustomer['bapc_ty'])}}</td>
                        <ng-template #bapc_ty_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                        </ng-template>  
      
      
                        <td *ngIf="formatNumber(skuCustomer['bapc_ly']) != 'NaN'; else bapc_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['bapc_ly'])}}</td>
                        <ng-template #bapc_ly_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                        </ng-template>    
                        
                        
                        <td *ngIf="formatNumber(skuCustomer['bapc_per_ly']) != 'NaN'; else bapc_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['bapc_per_ly'])}}</td>
                        <ng-template #bapc_per_ly_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                        </ng-template>   
      
      
                        <td *ngIf="formatNumber(skuCustomer['nsr_ty']) != 'NaN'; else nsr_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ty'])}}</td>
                        <ng-template #nsr_ty_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                        </ng-template> 
      
      
                        <td *ngIf="formatNumber(skuCustomer['nsr_ly']) != 'NaN'; else nsr_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ly'])}}</td>
                        <ng-template #nsr_ly_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                        </ng-template> 
      
                        <td *ngIf="formatNumber(skuCustomer['nsr_per_ly']) != 'NaN'; else nsr_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['nsr_per_ly'])}}</td>
                        <ng-template #nsr_per_ly_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                        </ng-template> 
      
                        <td *ngIf="formatNumber(skuCustomer['gp_ty']) != 'NaN'; else gp_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ty'])}}</td>
                        <ng-template #gp_ty_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                        </ng-template> 
      
      
                        <td *ngIf="formatNumber(skuCustomer['gp_ly']) != 'NaN'; else gp_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ly'])}}</td>
                        <ng-template #gp_ly_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                        </ng-template> 
      
      
                        <td *ngIf="formatNumber(skuCustomer['gp_per_ly']) != 'NaN'; else gp_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['gp_per_ly'])}}</td>
                        <ng-template #gp_per_ly_NA>
                          <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                        </ng-template> 
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['salesAreaNm']}}</td>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['salesDistrictNm']}}</td>
                        <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['channelLev1Nm']}}</td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
            </table>
          </ng-template>          
      </div>
    </div>
  </div>
</div>