import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { HQProgressHelper } from 'src/app/components/pages/hq-progress/hq_progress_helper';
import { DistrictRouterProgressHelper } from 'src/app/components/pages/district-route-progress/district_route_progress_helper';
import { ProgressRankingHelper } from 'src/app/components/pages/progress-ranking/progress-ranking-helper';
import { FILTER_CONFIGS_HQ } from 'src/app/model/constants';
import { FilterHelper } from './filter-helper-hq';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'app-add-more-filter-hq',
  templateUrl: './filters-hq.html',
  styleUrls: ['./filters-hq.scss']
})

export class AddMoreFilterHQ implements OnInit, OnChanges {
  @Input() isAlwaysShowApplyBtn?: boolean = false;
  @Input() filterModuleName : string = '';
  @Input() className?: string = '';
  @Input() filters: any;
  @Input() lstOption_Selected: any = {};
  @Input() lstOption_Parrent_Selected: any = {};
  @Input() lstOption_States: any   = {};
  @Input() listData_Filter: any    = [];
  @Input() lstOption_IsUsed: any   = {};
  @Input() defaultVisibleFilters: number;
  @Output() onUpdateChart = new EventEmitter<{ lstOption_States: any, lstOption_Selected: any }>();
  @Output() onResetFiltersGroup = new EventEmitter<{ data: any }>();

  @Input() lstAllOptionsCalendarData?: any;
  lstAllOptionsFilteredCalendarData: any;

  @Input() lstAllOptionsAreaData?: any;
  lstAllOptionsFilteredAreaData: any;

  @Input() lstAllOptionsAccountData?: any;
  lstAllOptionsFilteredAccountData: any;

  @Input() lstAllOptionsProductData?: any;
  lstAllOptionsFilteredProductData: any;

  lstOptions: any;
  lstOptionsVisibleDefault: any;
  isFirstLoad: boolean = true;
  isShowAddMoreFilter: boolean;
  isAddMoreFilter: boolean;
  isResetFilter: boolean;
  isShowApplyBtn: boolean;

  lstOptionsHQ: any = [];
  lstOptionsDistrict: any = [];
  listOptionsProgressRanking : any = [];
  userGroupName: string;

  private searchSubject = new Subject<string>();
  searchObj: any = {
    loading: false,
    keyName: '',
    data: [],
  }

  pageName: string = '';
  CONFIGS: any = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS || {GROUP_NAME: '', KEY_NAME: ''};

  constructor(private userService: UserService) {}

  ngOnInit() {
    this.pageName                 = FilterHelper.getPageName(this.filterModuleName);
    this.lstOptionsVisibleDefault = FilterHelper.getDefaultVisibleFilterListHQ(this.pageName);
    this.lstOptions               = this.listData_Filter?.lstOptions_IndexDB ? this.listData_Filter?.lstOptions_IndexDB : FilterHelper.getDefaultFilterList(this.filters);
    this.isShowApplyBtn           = this.defaultVisibleFilters > 0;
    this.isShowAddMoreFilter      = Object.keys(this.lstOption_States).length > this.defaultVisibleFilters;
    this.isResetFilter            = FilterHelper.isShowBtnResetFilter(this.lstOption_States, this.lstOptionsVisibleDefault);
    this.userGroupName            = this.userService.getUserInfo();

    if (!this.listData_Filter?.lstOptions_IndexDB) {
      if (this.pageName === 'HQProgress' ||this.pageName === 'HQProgressLevel1' ||this.pageName === 'HQProgressLevel2'||this.pageName === 'HQProgressTimeLine') {
        this._initAllHQOptionSelect();
      }

      if (this.pageName === 'DistrictProgress'|| this.pageName === 'DistrictProgressByRoute') {
        this._initAllDistrictOptionSelect();
      }

      if (this.pageName === 'ProgressRanking'){
        this._initAllProgressRankingOptionSelect();
      }
    } else {
      this.isFirstLoad = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['lstOption_Parrent_Selected'] && this.lstOption_Parrent_Selected)
      {
        this.onSaveItemsChange(this.lstOption_Parrent_Selected);
        this.updateChart();
      }
  }

  updateChart() {
    this.onUpdateChart.emit(
      {
        lstOption_States: this.lstOption_States,
        lstOption_Selected: this.lstOption_Selected
      }
    );

    FilterHelper.store_lstOption_States(
      this.filterModuleName,
      this.lstOption_States,
      this.lstOption_Selected ,
      this.lstOptions,
      this.lstAllOptionsFilteredCalendarData,
      this.lstAllOptionsFilteredAreaData,
      this.lstAllOptionsFilteredAccountData,
      this.lstAllOptionsFilteredProductData,
      null,
      null,
      null,
      this.userGroupName
    );
  }

  private _resetOptions(childArr: any = []) {
    if (Array.isArray(childArr) && childArr.length > 0) {
      childArr.map((elt: string) => {
        if (this.lstOption_IsUsed[elt]) {
          this.lstOption_Selected[elt] = ['All'];
        }
      })
    }
  }

  private _initAllHQOptionSelect() { // HQ
    if (this.lstOptionsHQ.length === 0) {
      HQProgressHelper.getLstAllOptionsData().subscribe((response) => {
        if (response) {
          const filters = response.filters;
          const data = response.data.Filters;

          if (Array.isArray(filters) && filters.length > 0 && Array.isArray(data) && data.length > 0) {
            this.lstOptionsHQ = [...data];

            if (this.isFirstLoad) {
              filters.map(filterName => {
                const listOption            = data.map((item: any) => {return item[filterName] });
                const uniqueListOption      =  [...new Set(listOption)].sort();
                this.lstOptions[filterName] = [{ value: 'All', text: 'All' }, ...uniqueListOption?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];
              });
              this.isFirstLoad = false;
            }
          }
        }
      });
    }
  }

  private _initHQOptionSelect(keyName: string, selectedItems: any = []) {
    if (keyName.length > 0 && selectedItems.length > 0) {
      let allFilterData   = [...this.lstOptionsHQ];
      const childFilters  = FilterHelper.getHQFiltersChild(keyName) || [];
      const parentFilters = FilterHelper.getHQFiltersClosestParent(keyName);

      if (!FilterHelper.isAllSelected(selectedItems)) {
        parentFilters.push(keyName);
      } else {
        childFilters.push(keyName);
      }

      parentFilters.map(filterName => {
        if (filterName) {
          if (!FilterHelper.isAllSelected(this.lstOption_Selected[filterName])) {
            allFilterData = allFilterData.filter((p) => {
              const value = this.lstOption_Selected[filterName];
              return value.includes(p[filterName]);
            });
          }
        }
      });

      childFilters.map(filterName => {
        const listOption            = allFilterData.map((item: any) => {return item[filterName] });
        const uniqueListOption      = [...new Set(listOption)].sort();
        this.lstOptions[filterName] = [];
        this.lstOptions[filterName] = [{ value: 'All', text: 'All' }, ...uniqueListOption?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];;
      });
    }
  }

  private _initAllDistrictOptionSelect() {
    if (this.lstOptionsDistrict.length === 0) {
      DistrictRouterProgressHelper.getLstAllOptionsData().subscribe((response) => {
        if (response) {
          const { filters, data } = response;

          if (Array.isArray(filters) && filters.length > 0 && Array.isArray(data) && data.length > 0) {
            this.lstOptionsDistrict = [...data];

            if (this.isFirstLoad) {
              filters.map(filterName => {
                const listOption            = data.map((item: any) => {return item[filterName] });
                const uniqueListOption      =  [...new Set(listOption)].sort();
                this.lstOptions[filterName] = [{ value: 'All', text: 'All' }, ...uniqueListOption?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];;;
              });
            }

            this.isFirstLoad = false;
          }
        }
      });
    }
  }

///
  private _initAllProgressRankingOptionSelect(){
    if (this.listOptionsProgressRanking.length === 0) {
      ProgressRankingHelper.getLstAllOptionsData().subscribe((response) => {
        if (response) {
          const { filters, data } = response;

          if (Array.isArray(filters) && filters.length > 0 && Array.isArray(data) && data.length > 0) {
            this.listOptionsProgressRanking = [...data];

            if (this.isFirstLoad) {
              filters.map(filterName => {
                const listOption            = data.map((item: any) => {return item[filterName] });
                const uniqueListOption      =  [...new Set(listOption)].sort();
                this.lstOptions[filterName] = [{ value: 'All', text: 'All' }, ...uniqueListOption?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];;;
              });
            }

            this.isFirstLoad = false;
          }
        }
      });
    }
  }

  private _initDistrictOptionSelect(keyName: string, selectedItems: any = []) {
    if (keyName.length > 0 && selectedItems.length > 0) {
      let allFilterData   = [...this.lstOptionsDistrict];
      const childFilters  = FilterHelper.getDistrictFiltersChild(keyName) || [];
      const parentFilters = FilterHelper.getDistrictFiltersClosestParent(keyName) || [];

      if (!FilterHelper.isAllSelected(selectedItems)) {
        parentFilters.push(keyName);
      } else {
        childFilters.push(keyName);
      }

      parentFilters.map(filterName => {
        if (filterName) {
          if (!FilterHelper.isAllSelected(this.lstOption_Selected[filterName])) {
            allFilterData = allFilterData.filter((p) => {
              const value = this.lstOption_Selected[filterName];
              return value.includes(p[filterName]);
            });
          }
        }
      });

      childFilters.map(filterName => {
        const listOption            = allFilterData.map((item: any) => {return item[filterName] });
        const uniqueListOption      = [...new Set(listOption)].sort();
        this.lstOptions[filterName] = [];
        this.lstOptions[filterName] = [{ value: 'All', text: 'All' }, ...uniqueListOption?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];;
      });
    }
  }

  private _initProgressRankingOptionSelect(keyName: string, selectedItems: any = []) {
    if (keyName.length > 0 && selectedItems.length > 0) {
      let allFilterData   = [...this.listOptionsProgressRanking];
      const childFilters  = FilterHelper.getProgressRankingFiltersChild(keyName) || [];
      const parentFilters = FilterHelper.getProgressRankingFiltersClosestParent(keyName) || [];

      if (!FilterHelper.isAllSelected(selectedItems)) {
        parentFilters.push(keyName);
      } else {
        childFilters.push(keyName);
      }

      parentFilters.map(filterName => {
        if (filterName) {
          if (!FilterHelper.isAllSelected(this.lstOption_Selected[filterName])) {
            allFilterData = allFilterData.filter((p) => {
              const value = this.lstOption_Selected[filterName];
              return value.includes(p[filterName]);
            });
          }
        }
      });

      childFilters.map(filterName => {
        const listOption            = allFilterData.map((item: any) => {return item[filterName] });
        const uniqueListOption      = [...new Set(listOption)].sort();
        this.lstOptions[filterName] = [];
        this.lstOptions[filterName] = [{ value: 'All', text: 'All' }, ...uniqueListOption?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];;
      });
    }
  }

  public onSaveItemsChange(event: any):void {
    const { keyName, selectedItems } = event;

    this.lstOption_Selected[keyName] = selectedItems;
    this.searchObj.data = [];

    if (this.pageName === 'HQProgress' ||this.pageName === 'HQProgressLevel1' ||this.pageName === 'HQProgressLevel2'|| this.pageName === 'HQProgressTimeLine') {
      this._initAllHQOptionSelect();
      this._initHQOptionSelect(keyName, selectedItems);
      this._resetOptions(FilterHelper.getHQFiltersChild(keyName));
    }

    if (this.pageName === 'DistrictProgress'|| this.pageName === 'DistrictProgressByRoute') {
      this._initAllDistrictOptionSelect();
      this._initDistrictOptionSelect(keyName, selectedItems);
      this._resetOptions(FilterHelper.getDistrictFiltersChild(keyName));
    }

    if (this.pageName === 'ProgressRanking'){
      this._initAllProgressRankingOptionSelect();
      this._initProgressRankingOptionSelect(keyName, selectedItems);
      this._resetOptions(FilterHelper.getProgressRankingFiltersChild(keyName));
    }
  }

  public onFetchDataOnSearch(event: any): void {
    const {keyName, keySearch} = event;
    this.searchObj.keyName     = keyName;
    this.searchSubject.next(keySearch);
  }

  private _getFiltersChildGroup(keyName: string = '') {
    if (this.pageName === 'HQProgress' ||this.pageName === 'HQProgressLevel1' ||this.pageName === 'HQProgressLevel2'||this.pageName === 'HQProgressTimeLine') {
      return FilterHelper.getHQFiltersChild(keyName);
    }

    if (this.pageName === 'DistrictProgress'|| this.pageName === 'DistrictProgressByRoute') {
      return FilterHelper.getDistrictFiltersChild(keyName);
    }

    if(this.pageName === 'ProgressRanking'){
      return FilterHelper.getProgressRankingFiltersChild(keyName);
    }

    return [];
  }

  public onConvertTentativeToEnableTemp(event: any): void {
    const { keyName } = event;

    if (keyName) {
      FilterHelper.convertTentativeToEnableTemp(this.lstOption_States, keyName);
      this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States, this.lstOptionsVisibleDefault);
    }
  }

  public onConvertEnableTempToTentative(event: any): void {
    const { keyName } = event;

    if (keyName) {
      if (!FilterHelper.hasMultipleEnableTemp(this.lstOption_States)) {
        return;
      }

      const selectedArr = this.lstOption_Selected[keyName] || [];

      if (FilterHelper.isAllSelected(selectedArr)) {
        FilterHelper.convertEnableTempToTentative(this.lstOption_States, keyName);
        this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States, this.lstOptionsVisibleDefault);
      } else {
        const selectedArr = this.lstOption_Selected[keyName] || [];

        if (FilterHelper.isAllSelected(selectedArr)) {
          FilterHelper.convertEnableTempToTentative(this.lstOption_States, keyName);
        } else {
          let childFilters = this._getFiltersChildGroup(keyName);

          if (childFilters) {
            childFilters.unshift(keyName);
            this.onResetFiltersGroup.emit({ data: childFilters });
          }

          FilterHelper.convertEnableTempToTentative(this.lstOption_States, keyName);
          if (this.pageName === 'HQProgress' ||this.pageName === 'HQProgressLevel1' ||this.pageName === 'HQProgressLevel2'||this.pageName === 'HQProgressTimeLine') {
            this._resetOptions(FilterHelper.getHQFiltersChild(keyName));
            this._initHQOptionSelect(keyName, ['All']);
          }

          if (this.pageName === 'DistrictProgress' || this.pageName === 'DistrictProgressByRoute') {
            this._resetOptions(FilterHelper.getDistrictFiltersChild(keyName));
            this._initDistrictOptionSelect(keyName, ['All']);
          }

          if(this.pageName === 'ProgressRanking'){
            this._resetOptions(FilterHelper.getProgressRankingFiltersChild(keyName));
            this._initProgressRankingOptionSelect(keyName, ['All']);
          }
        }

        this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States, this.lstOptionsVisibleDefault);
      }
    }
  }

  public onAddMoreFilter(): void {
    this.isAddMoreFilter = true;
    this.isShowApplyBtn  = true;
    FilterHelper.convertAllEnableToEnableTemp(this.lstOption_States);
    FilterHelper.convertAllDisabledToTentative(this.lstOption_States);

    if (this.lstOptionsHQ.length === 0 && this.pageName === 'HQProgress' ||this.pageName === 'HQProgressLevel1' ||this.pageName === 'HQProgressLevel2'||this.pageName === 'HQProgressTimeLine') {
      this._initAllHQOptionSelect();
    }

    if (this.lstOptionsDistrict.length === 0 && this.pageName === 'DistrictProgress'|| this.pageName === 'DistrictProgressByRoute') {
      this._initAllDistrictOptionSelect();
    }

    if(this.listOptionsProgressRanking.length === 0 && this.pageName ==='ProgressRanking'){
      this._initAllProgressRankingOptionSelect();
    }
  }

  public onClose(): void {
    this.isAddMoreFilter = false;
    this.isShowApplyBtn  = FilterHelper.isShowBtnApply(this.lstOption_States);
    FilterHelper.convertAllEnableTempToEnable(this.lstOption_States);
    FilterHelper.convertAllTentativeToDisable(this.lstOption_States);
  }

  public onResetFilter() {
    if (this.pageName === 'HQProgress') {
      if (this.isAddMoreFilter) {
        FilterHelper.resetHQFilterConfigs1(this.lstOption_States);
      } else {
        FilterHelper.resetHQFilterConfigs2(this.lstOption_States);
      }
      this._resetOptions(['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL']);
      this._initAllHQOptionSelect();
      this._initHQOptionSelect('SALES_HQ_LEV1_NAME', ['All']);
    }


    if (this.pageName === 'HQProgressLevel1' ) {

      let hqProgressLevel1_default= ['SALES_HQ_LEV1_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_NL'];
      if (this.isAddMoreFilter) {
        FilterHelper.resetHQFilterConfigs1(this.lstOption_States,hqProgressLevel1_default);
      } else {
        FilterHelper.resetHQFilterConfigs2(this.lstOption_States,hqProgressLevel1_default);
      }
      this._resetOptions(['SALES_HQ_LEV1_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_NL', 'CHANNEL_LEV3_NAME']);
      this._initAllHQOptionSelect();
      this._initHQOptionSelect('SALES_HQ_LEV1_NAME', ['All']);
    }


    if (this.pageName === 'HQProgressLevel2') {
      let hqProgressLevel2_default= ['SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_NL'];
      if (this.isAddMoreFilter) {
        FilterHelper.resetHQFilterConfigs1(this.lstOption_States,hqProgressLevel2_default);
      } else {
        FilterHelper.resetHQFilterConfigs2(this.lstOption_States,hqProgressLevel2_default);
      }
      this._resetOptions(['SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_NL', 'CHANNEL_LEV3_NAME']);
      this._initAllHQOptionSelect();
      this._initHQOptionSelect('SALES_HQ_LEV2_NAME', ['All']);
    }

    if (this.pageName === 'HQProgressTimeLine' ) {

      let hqProgressTimeLine_default= ['SALES_HQ_LEV1_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_NL'];
      if (this.isAddMoreFilter) {
        FilterHelper.resetHQFilterConfigs1(this.lstOption_States,hqProgressTimeLine_default);
      } else {
        FilterHelper.resetHQFilterConfigs2(this.lstOption_States,hqProgressTimeLine_default);
      }
      this._resetOptions(['SALES_HQ_LEV1_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_NL', 'CHANNEL_LEV3_NAME']);
      this._initAllHQOptionSelect();
      this._initHQOptionSelect('SALES_HQ_LEV1_NAME', ['All']);
    }

    if(this.pageName)

    if (this.pageName === 'DistrictProgress') {
      let districtRouteProgress_default = ['SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      if (this.isAddMoreFilter) {
        FilterHelper.resetDistrictFilterConfigs1(this.lstOption_States, districtRouteProgress_default);
      } else {
        FilterHelper.resetDistrictFilterConfigs2(this.lstOption_States, districtRouteProgress_default);
      }
      this._resetOptions(['SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL','SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV2_NAME']);
      this._initAllDistrictOptionSelect();
      this._initDistrictOptionSelect('SALES_HQ_LEV1_NAME', ['All']);
    }

    if (this.pageName === 'DistrictProgressByRoute') {
      let districtRouteProgress_default = ['SALES_HQ_LEV2_NAME','SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV3_NAME'];
      if (this.isAddMoreFilter) {
        FilterHelper.resetDistrictFilterConfigs1(this.lstOption_States, districtRouteProgress_default);
      } else {
        FilterHelper.resetDistrictFilterConfigs2(this.lstOption_States, districtRouteProgress_default);
      }
      this._resetOptions(['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV3_NAME','SALES_HQ_LEV1_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV2_NAME', 'CHANNEL_NL']);
      this._initAllDistrictOptionSelect();
      this._initDistrictOptionSelect('SALES_HQ_LEV1_NAME', ['All']);
    }


    if(this.pageName === 'ProgressRanking'){
      if (this.isAddMoreFilter) {
        FilterHelper.resetProgressRankingFilterConfigs1(this.lstOption_States);
      } else {
        FilterHelper.resetProgressRankingFilterConfigs2(this.lstOption_States);
      }

      this._resetOptions(['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME','CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL']);
      this._initAllProgressRankingOptionSelect();
      this._initProgressRankingOptionSelect('SALES_HQ_LEV1_NAME', ['All']);
    }

    this.isResetFilter  = FilterHelper.isShowBtnResetFilter(this.lstOption_States, this.lstOptionsVisibleDefault);
    this.isShowApplyBtn = FilterHelper.isShowBtnApply(this.lstOption_States);
  }
}