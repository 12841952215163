import { Component, EventEmitter, OnChanges, OnInit, OnDestroy, SimpleChanges, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import lodashClonedeep from 'lodash.clonedeep';
import moment from "moment";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { SkuDeepdiveHelper } from 'src/app/components/pages/sku-deepdive/sku-deepdive-helper';
import { HQProgressHelper } from 'src/app/components/pages/hq-progress/hq_progress_helper';
import { DistrictRouterProgressHelper } from 'src/app/components/pages/district-route-progress/district_route_progress_helper';
import { ProgressRankingHelper } from 'src/app/components/pages/progress-ranking/progress-ranking-helper';
import { FaceShareHelper } from 'src/app/components/pages/faceshare/faceshare.helper';
import { PocHelper } from 'src/app/components/pages/poc/poc.helper';
import { SalesDateHelper } from './helper';
import { SalesDateService } from './sales-date-filter.service';

@Component({
  selector   : 'sales-date-filter',
  templateUrl: './sales-date-filter.component.html',
  styleUrls  : ['./sales-date-filter.component.scss']
})

export class SalesDateComponent implements OnInit, OnDestroy {
  @Input() pageName: string = '';
  @Output() onDateTypeSaveItemsChange = new EventEmitter<{ selectedDateType: string, selectedSalesDate: string[] }>();
  private salesDateSubscription: Subscription;

  lstOption_DateType : any = [];
  lstOption_Selected_DateType: any = '';
  lstOption_Aggregated_DateType: any = '';

  lstOption_SalesDate$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  lstOption_Selected_SalesDate$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  lstOption_FromTo: any = [];
  lstOption_From: any = [];
  lstOption_To: any = [];
  lstOption_Selected_From: string = '';
  lstOption_Selected_To: string = '';
  lstOption_Aggregated_From: string = '';
  lstOption_Aggregated_To: string = '';
  lstOption_Aggregated: any = [];

  dailySalesDateRange: any = {
    ready: false,
    fromDate: null,
    toDate : null,
    maxDate: null,
    minDate: null,
    previousFromDate: null,
    previousToDate: null
  }

  isCalendar: boolean = false;
  isFromToDropdown: boolean = false;
  isSingleMonthDropdown: boolean = false;
  isOnlyView: boolean = true;
  dailySalesDate: string = '';

  sePaths   = ['/hq-progress', '/district-route-progress', '/sku-deepdive', '/area-deepdive', '/progress-ranking'];
  traxPaths = ['/faceshare', '/poc'];

  constructor(private salesDateService: SalesDateService) {}

  ngOnInit() {
    if (this.isCurrentPathIncluded(this.sePaths)) {
      this.salesDateSubscription = this.salesDateService.getSESalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          this.dailySalesDate = date[0] || '';
          this._initSalesDateOptions();
        }
      });

      this.salesDateService.getDateInJobClearCache();
    }

    if (this.isCurrentPathIncluded(this.traxPaths)) {
      this.salesDateSubscription = this.salesDateService.getTraxSalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          this.dailySalesDate = date[0] || '';
          this._initSalesDateOptions();
        }
      });

      this.salesDateService.getTraxDateInJobClearCache();
    }
  }

  ngOnDestroy() {
    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  public onDateTypeSave = (rollBack: boolean) => {
    if (!rollBack) {
      this.lstOption_Aggregated_DateType = lodashClonedeep(this.lstOption_Selected_DateType);
      this._setOptionsSaleDate();
      this._emitDataToParent();
    } else {
      this.lstOption_Selected_DateType = lodashClonedeep(this.lstOption_Aggregated_DateType);
    }
  }

  public onFromToDropdownSave = (rollBack: boolean, dropdownType: string) => {
    if (!rollBack) {
      if (dropdownType === 'from') {
        this.lstOption_Aggregated_From = this.lstOption_Selected_From;
        this.updateFilteredToArray();
      }

      if (dropdownType === 'to') {
        this.lstOption_Aggregated_To = this.lstOption_Selected_To;
        this.updateFilteredFromArray();
      }

      this._emitDataToParent();
    } else {
      if (dropdownType === 'from') {
        this.lstOption_Selected_From = this.lstOption_Aggregated_From;
      }

      if (dropdownType === 'to') {
        this.lstOption_Selected_To = this.lstOption_Aggregated_To;
      }
    }
  }

  public onSalesDateSaveItemsChange(e: any) {
    const {selectedItems} = e;

    this.lstOption_Selected_SalesDate$.next([...selectedItems]);
    this._emitDataToParent();
  }

  public onSingSalesDateSaveItemsChange(e: string) {
    this.lstOption_Selected_SalesDate$.next([e]);
    this._emitDataToParent();
  }

  public onDateSelect(value: any) {
    if (value) {
      const { from, to } = value;

      if (from && to) {
        const startDate = moment(new Date(from)).format("YYYY-MM-DD");
        const endDate   = moment(new Date(to)).format("YYYY-MM-DD");

        if (!moment(startDate).isSame(this.dailySalesDateRange.previousFromDate) || !moment(endDate).isSame(this.dailySalesDateRange.previousToDate)) {
          this.onDateTypeSaveItemsChange.emit({
            selectedDateType: this.lstOption_Aggregated_DateType,
            selectedSalesDate: [startDate, endDate]
          });
        }
      }
    }
  }

  private _initSalesDateOptions() {
    switch (this.pageName) {
      case 'HQProgress':
        HQProgressHelper.getSalesDate().subscribe((salesDate: string[]) => {
          if (salesDate && salesDate.length > 0) {
            this.lstOption_DateType = salesDate.map((item: string) => { return { value: item, text: item}; });
            this._setOptionsSaleDate(true);
            this.onDateTypeSave(false);
          }
        });
        break;

      case 'DistrictRouterProgress':
        DistrictRouterProgressHelper.getSalesDate().subscribe((salesDate: string[]) => {
          if (salesDate && salesDate.length > 0) {
            this.lstOption_DateType = salesDate.map((item: string) => { return { value: item, text: item}; });
            this._setOptionsSaleDate(true);
            this.onDateTypeSave(false);
          }
        });
        break;

      case 'ProgressRanking':
        ProgressRankingHelper.getSalesDate().subscribe((salesDate: string[]) => {
          if (salesDate && salesDate.length > 0) {
            this.lstOption_DateType = salesDate.map((item: string) => { return { value: item, text: item}; });
            this._setOptionsSaleDate(true);
            this.onDateTypeSave(false);
          }
        });
        break;

      case 'SkuDeepdive':
        SkuDeepdiveHelper.getSalesDate().subscribe((salesDate: string[]) => {
          if (salesDate && salesDate.length > 0) {
            this.lstOption_DateType = salesDate.map((item: string) => { return { value: item, text: item}; });
            this._setOptionsSaleDate(true);
            this.onDateTypeSave(false);
          }
        });
        break;

      case 'FaceShare':
        FaceShareHelper.getSalesDate().subscribe((salesDate: string[]) => {
          if (salesDate && salesDate.length > 0) {
            this.lstOption_DateType   = salesDate.map((item: string) => { return { value: item, text: item}; });
            this._setOptionsSaleDate(true);
            this.onDateTypeSave(false);
          }
        });
        break;

      case 'PoC':
        PocHelper.getSalesDate().subscribe((salesDate: string[]) => {
          if (salesDate && salesDate.length > 0) {
            this.lstOption_DateType   = salesDate.map((item: string) => { return { value: item, text: item}; });
            this._setOptionsSaleDate(true);
            this.onDateTypeSave(false);
          }
        });
        break;

      default:
        break;
    }
  }

  private _emitDataToParent() {
    if (this.isCalendar) {
      this.onDateTypeSaveItemsChange.emit({
        selectedDateType: this.lstOption_Aggregated_DateType,
        selectedSalesDate: this.lstOption_Selected_SalesDate$.value
      });
    }

    if (this.isFromToDropdown) {
      switch (this.lstOption_Selected_DateType) {
        case 'Specific Month':
        case 'YTD':
        case 'QTD':
          if (this.pageName === 'SkuDeepdive') {
            this.onDateTypeSaveItemsChange.emit({
              selectedDateType: this.lstOption_Aggregated_DateType,
              selectedSalesDate: [this.lstOption_Aggregated_From, this.lstOption_Aggregated_To]
            });
          } else {
            this.onDateTypeSaveItemsChange.emit({
              selectedDateType: this.lstOption_Aggregated_DateType,
              selectedSalesDate: SalesDateHelper.getMonthsInRangeByMonth(this.lstOption_Aggregated_From, this.lstOption_Aggregated_To)
            });
          }
          break;

        case 'MTD':
        case 'This Month':
          this.onDateTypeSaveItemsChange.emit({
            selectedDateType: this.lstOption_Aggregated_DateType,
            selectedSalesDate: [this.lstOption_Aggregated_From]
          });
          break;

        default:
          this.onDateTypeSaveItemsChange.emit({
            selectedDateType: this.lstOption_Aggregated_DateType,
            selectedSalesDate: [this.lstOption_Aggregated_From, this.lstOption_Aggregated_To]
          });
          break;
      }
    }

    if (this.isSingleMonthDropdown) {
      switch (this.lstOption_Selected_DateType) {
        case 'MTD':
          this.onDateTypeSaveItemsChange.emit({
            selectedDateType: this.lstOption_Aggregated_DateType,
            selectedSalesDate: [...this.lstOption_Selected_SalesDate$.value]
          });
          break;

        default:
          break;
      }
    }
  }

  private _convertToNgbDateStruct(date: Date): NgbDateStruct {
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    };
  }

  private _initOptionSaleDate(value1: string, value2: string, isInit: boolean) {
    const fromObj = new Date(value1);
    const toObj = new Date(value2);

    let todate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
    let fromdate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
    this.dailySalesDateRange.toDate   = todate;
    this.dailySalesDateRange.fromDate = fromdate;
    this.dailySalesDateRange.previousToDate = todate;
    this.dailySalesDateRange.previousFromDate = fromdate;

    if (isInit) {
      let maxDate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
      let minDate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
      this.dailySalesDateRange.maxDate = this._convertToNgbDateStruct(maxDate);
      this.dailySalesDateRange.minDate = this._convertToNgbDateStruct(minDate);
    }

    this.dailySalesDateRange.ready  = true;
  }

  private _setOptionsSaleDate(firstLoad: boolean = false) {
    if (firstLoad) {
      this.lstOption_Selected_DateType = this.lstOption_DateType[0].value;
      this.lstOption_Aggregated_DateType = this.lstOption_DateType[0].value;
    }

    this.isCalendar       = SalesDateHelper.isShowCalendar(this.pageName, this.lstOption_Aggregated_DateType);
    this.isFromToDropdown = SalesDateHelper.isFromToDropdown(this.pageName, this.lstOption_Aggregated_DateType);
    this.isSingleMonthDropdown = SalesDateHelper.isPhotoReport(this.pageName, this.lstOption_Aggregated_DateType);
    this.isOnlyView       = SalesDateHelper.isOnlyView(this.pageName, this.lstOption_Aggregated_DateType);

    if (this.isCalendar) {
      const dates = SalesDateHelper.getSalesDate(this.pageName, this.lstOption_Aggregated_DateType, this.dailySalesDate);

      if (dates && Array.isArray(dates) && dates.length >= 2) {
        this.dailySalesDateRange.ready = false;
        this._initOptionSaleDate(dates[0], dates[1], true);
        this.lstOption_Selected_SalesDate$.next([dates[0], dates[1]]);
      }
    }

    if (this.isFromToDropdown) {
      switch (this.lstOption_Aggregated_DateType) {
        case 'This Month':
        case 'MTD':
          const currentMonth = SalesDateHelper.getCurrentMonth(this.dailySalesDate);
          this.lstOption_From = [{ value: currentMonth, name: currentMonth }];
          this.lstOption_To   = [{ value: currentMonth, name: currentMonth }];
          break;

        case 'QTD':
          this.lstOption_From = SalesDateHelper.getCurrentQuarterMonthsUntilYesterday(this.dailySalesDate);
          this.lstOption_To = SalesDateHelper.getCurrentQuarterMonthsUntilYesterday(this.dailySalesDate);
          break;

        case 'YTD':
          this.lstOption_From = SalesDateHelper.getMonthsUntilYesterday(this.dailySalesDate);
          this.lstOption_To = SalesDateHelper.getMonthsUntilYesterday(this.dailySalesDate);
          break;

        default:
          this.lstOption_FromTo = SalesDateHelper.getMonthsUntilYesterday(this.dailySalesDate);
          this.lstOption_From   = [...this.lstOption_FromTo];
          this.lstOption_To     = [...this.lstOption_FromTo];
          break;
      }

      this.lstOption_Selected_From = this.lstOption_From[0]?.value || '';
      this.lstOption_Selected_To   = this.lstOption_From[this.lstOption_From.length - 1]?.value || '';
      this.lstOption_Aggregated_From = this.lstOption_From[0]?.value || '';
      this.lstOption_Aggregated_To   = this.lstOption_From[this.lstOption_From.length - 1]?.value || '';
    }


    if (this.isSingleMonthDropdown) {
      const months = [...SalesDateHelper.getPhotoReportMonths(this.pageName)];

      if (months && Array.isArray(months) && months.length > 0) {
        this.lstOption_SalesDate$.next(months);
        this.lstOption_Selected_SalesDate$.next([months[0].value]);
      }
    }
  }

  private updateFilteredFromArray() {
    if (this.lstOption_Aggregated_From) {
      this.lstOption_From = [...this.lstOption_FromTo].filter(date => date.value <= this.lstOption_Aggregated_To);
    } else {
      this.lstOption_From = [...this.lstOption_FromTo];
    }
  }

  private updateFilteredToArray() {
    if (this.lstOption_Aggregated_To) {
      this.lstOption_To = [...this.lstOption_FromTo].filter(date => date.value >= this.lstOption_Aggregated_From);
    } else {
      this.lstOption_To = [...this.lstOption_FromTo];
    }
  }

  private isCurrentPathIncluded(paths: string[]): boolean {
    return paths.some(path => location.href.includes(path));
  }
}
