import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userInfo: any = null;

  constructor() {}

  getUserInfo(): any {
    if (!this.userInfo) {
      const storedData = localStorage.getItem('userinfo');

      if (storedData) {
        this.userInfo = JSON.parse(storedData);
      }
    }

    return this.userInfo?.customers?.length > 0 ? this.userInfo.customers[0]?.filterOptionsGroup?.name : '';  }
}
