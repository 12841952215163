import { AnalyticsQueries } from 'src/app/model/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import moment from "moment";
import { apiUrl } from 'src/app/model/constants';
import { isObjEmpty, objectToQueryString, openWarningPopup, closeWarningPopup } from 'src/app/helpers/utils';

export class DistrictRouterProgressHelper {
  private static lstAllOptionsData = new BehaviorSubject<any>(null);
  private static salesDateSubject = new BehaviorSubject<any>(null);
  private static dateInJobClearCache = new BehaviorSubject<string>('');
  private static loadingSubject = new BehaviorSubject<boolean>(false);
  private static loadingCount   = 0;
  private static userGroupName = '';

  static setLoading(loading: boolean) {
       return this.loadingSubject.next(loading);
  }

  static isLoading() {
       return this.loadingSubject.asObservable();
  }

  static startLoading() {
       this.loadingCount++;
       this.loadingSubject.next(true);
  }

  static stopLoading() {
       this.loadingCount--;
       if (this.loadingCount === 0) {
              this.loadingSubject.next(false);
       }
  }

  static isAllSelected = (arr1: any) => {
    return JSON.stringify(arr1) === JSON.stringify(['All']);
  }

  static getLstAllOptionsData() {
    return this.lstAllOptionsData.asObservable();
  }

  static setSalesDate(value: string[]) {
       return this.salesDateSubject.next(value);
  }

  static getSalesDate() {
       return this.salesDateSubject.asObservable();
  }

  static setDateInJobClearCache(value: string) {
       return this.dateInJobClearCache.next(value);
 }

 static getDateInJobClearCache() {
       return this.dateInJobClearCache.asObservable();
 }

  static DistrictSetQueryString(lstOptionSelected: object,
                                pagingCurrent: number,
                                sortType: string,
                                sortColumn: string,
                                isExportAllData: boolean = false) {
    const analyticsQuery = {
      IsDefaultPage: false,
      IsExportAllData: isExportAllData,
      Page      : pagingCurrent,
      SortType  : sortType,
      SortBy    : sortColumn,
      Selected  : {},
      UnSelected: {},
    };

    let cacheKey = `IsDefaultPage=false&IsExportAllData=${isExportAllData}&Page=${pagingCurrent}&SortType=${sortType}&SortBy=${sortColumn}`;

    if (lstOptionSelected) {
      for (const key in lstOptionSelected) {
        if (lstOptionSelected.hasOwnProperty(key)) {
          if (!this.isAllSelected(lstOptionSelected[key])) {
            analyticsQuery['Selected'][key] = lstOptionSelected[key];
          }
        }
      }
    }

    if (!isObjEmpty(analyticsQuery?.Selected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
    }

    if (!isObjEmpty(analyticsQuery?.UnSelected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
    }

    analyticsQuery['CacheKey'] = cacheKey;
    return analyticsQuery;
  }

  static RouteSetQueryString(lstOptionSelected: object,
                            pagingCurrent: number,
                            sortType: string,
                            sortColumn: string,
                            isExportAllData: boolean = false) {
    const analyticsQuery = {
      IsDefaultPage: false,
      IsExportAllData: isExportAllData,
      Page      : pagingCurrent,
      SortType  : sortType,
      SortBy    : sortColumn,
      Selected  : {},
      UnSelected: {},
    };

    let cacheKey = `IsDefaultPage=false&IsExportAllData=${isExportAllData}&Page=${pagingCurrent}&SortType=${sortType}&SortBy=${sortColumn}`;

    if (lstOptionSelected) {
      for (const key in lstOptionSelected) {
        if (lstOptionSelected.hasOwnProperty(key)) {
          if (!this.isAllSelected(lstOptionSelected[key])) {
            analyticsQuery['Selected'][key] = lstOptionSelected[key];
          }
        }
      }
    }

    if (!isObjEmpty(analyticsQuery?.Selected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
    }

    if (!isObjEmpty(analyticsQuery?.UnSelected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
    }

    analyticsQuery['CacheKey'] = cacheKey;
    return analyticsQuery;
  }

  static DistrictTimelineSetQueryString(aggregateBy: string, lstOptionSelected: object) {
    const analyticsQuery = {
      IsDefaultPage: false,
      AggregateBy  : aggregateBy || 'daily',
      Selected  : {},
      UnSelected: {},
    };

    let cacheKey = `IsDefaultPage=false&AggregateBy=${aggregateBy || 'daily'}`;

    if (lstOptionSelected) {
      for (const key in lstOptionSelected) {
        if (lstOptionSelected.hasOwnProperty(key) && key !='AggregateBy') {
          if (!this.isAllSelected(lstOptionSelected[key])) {
            analyticsQuery['Selected'][key] = lstOptionSelected[key];
          }
        }
      }
    }
    if (!isObjEmpty(analyticsQuery?.Selected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
    }

    if (!isObjEmpty(analyticsQuery?.UnSelected)) {
      cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
    }

    analyticsQuery['CacheKey'] = cacheKey;
    return analyticsQuery;
  }

  static GetHQTimelineDataByAggregateKey(http: HttpClient, analyticsQuery: object,isExport: boolean = false) : Promise<any>{
    const bodyObj = {
      ...analyticsQuery,
      Code: AnalyticsQueries.progressByTimeline,
      CacheKey: `?code=${AnalyticsQueries.progressByTimeline}&IsExport=${isExport}&${analyticsQuery['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      IsExport : isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    if (isExport) {
       openWarningPopup();
     }

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
            const timelineData = JSON.parse(response);
            resolve(timelineData);

            if (isExport) {
              closeWarningPopup();
            }
          },
          error => {
              console.log(error);

              if (isExport) {
                     closeWarningPopup();
              }
          }
      );
    });
  }

  static GetProgressbyDistrictAggregateKey(http: HttpClient, analyticsQuery: object, selectedChannelLevel: string, isExport: boolean = false): Promise<any> {
    const bodyObj = {
      ...analyticsQuery,
       // NoCache: true, // only on Develop
      Code: AnalyticsQueries.progressByDistrict,
      CacheKey: `?code=${AnalyticsQueries.progressByDistrict}&IsExport=${isExport}&${analyticsQuery['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
      IsExport: isExport
    };

    if (selectedChannelLevel && selectedChannelLevel.length > 0) {
       bodyObj['GroupByLevel'] = selectedChannelLevel;
       bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&GroupByLevel=${selectedChannelLevel}&LatestUpdate=${this.dateInJobClearCache.value}`;
     } else {
       bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&LatestUpdate=${this.dateInJobClearCache.value}`;
     }

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    if (isExport) {
       openWarningPopup();
     }

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
                let district = JSON.parse(response);
                resolve(district);

                if (isExport) {
                     closeWarningPopup();
                }
          },
          error => {
              console.log(error);

              if (isExport) {
                     closeWarningPopup();
              }
          }
        );
    });
  }

  static GetProgressbyRouteAggregateKey(http: HttpClient, analyticsQuery: object, isExport: boolean = false): Promise<any> {
    const bodyObj = {
      ...analyticsQuery,
      Code: AnalyticsQueries.progressByRoute,
      CacheKey: `?code=${AnalyticsQueries.progressByRoute}&IsExport=${isExport}&${analyticsQuery['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}&LatestUpdate=${this.dateInJobClearCache.value}`,
      IsExport: isExport
    };

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    if (isExport) {
       openWarningPopup();
     }

    return new Promise<any>((resolve, reject) => {
      http.post<any>(apiUrl, bodyObj, httpOptions)
        .subscribe(
          response => {
                let data = JSON.parse(response);
                resolve(data);

                if (isExport) {
                     closeWarningPopup();
                }
          },
          error => {
              console.log(error);

              if (isExport) {
                     closeWarningPopup();
              }
          }
        );
    });
  }


       static GetAllFilterOptions(http: HttpClient, dateInJobClearCache: any, queryObj: object, groupName: string): Promise<any> {
              if (this.dateInJobClearCache.value.length <= 0 && dateInJobClearCache.length > 0) {
                     this.dateInJobClearCache.next(dateInJobClearCache);
              }

              if (!this.userGroupName.length && groupName.length > 0) {
                     this.userGroupName = groupName;
              }

              let bodyObj = {};

              if (isObjEmpty(queryObj)) {
                     bodyObj = {
                            // NoCache: true,
                            Code: AnalyticsQueries.progressAllFilterOptions,
                            CacheKey: `?code=${AnalyticsQueries.progressAllFilterOptions}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
                            Selected: {}
                     }
              } else {
                     bodyObj = {
                            // NoCache: true,
                            ...queryObj,
                            Code: AnalyticsQueries.progressAllFilterOptions,
                            CacheKey: `?code=${AnalyticsQueries.progressAllFilterOptions}&${queryObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`,
                     };
                     this.startLoading();
              }

              const httpOptions: Object = {
                headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
                responseType: 'text'
              };

              return new Promise<any>((resolve, reject) => {
                http.post<any>(apiUrl, bodyObj, httpOptions)
                  .subscribe(
                    response => {
                     const data = JSON.parse(response);
                     const data2 = { DATE_TYPE: ['This Month', 'Specific Month']};

                     if (isObjEmpty(queryObj)) {
                            this.setSalesDate(data2?.DATE_TYPE);
                     } else {
                            this.stopLoading();
                     }

                     resolve(data);
                    },
                    error => {
                     if (!isObjEmpty(queryObj)) {
                            this.stopLoading();
                     }
                      console.log(error);
                    }
                  );
              });
       }

       
       static getFirstDayOfWeekAsString(inputString: string): string {
              const yearString: string = inputString.slice(0, 4);
              const weekString: string = inputString.slice(4);

              const year: number = parseInt(yearString, 10);
              const week: number = parseInt(weekString, 10);

              const januaryFirst = moment(`${year}-01-01`);
              const firstWeekDay = januaryFirst.clone().add((week - 1) * 7 - januaryFirst.day() + 1, 'days');
              const saturday = firstWeekDay.clone().day(6);
              return saturday.format('YYYY/MM/DD');
          }


       static GetTotalRow(listGroupNameRoot: string[], rawData: any[], rootLevel: number, keyOfRoot1: string, keyOfRoot2: string, keyOfRoot3: string, searchby: string) {
              let totalRoot = [];
              listGroupNameRoot.forEach(element => {
                     let SALES_HQ_LEV2_NAME = '';
                     let SALES_HQ_LEV3_NAME = '';
                     let CHANNEL_LEV2_NAME = '';
                     let CHANNEL_LEV3_NAME = '';
                     let SALES_CENTER_NAME = '';
                     let SALES_CONTROL_BASE_NAME = '';
                     let SALES_CONTROL_BASE_ROUTE_CODE = '';

                     if (searchby == 'DISTRICT') {
                            SALES_HQ_LEV2_NAME = rootLevel == 1 ? element : '';
                            SALES_HQ_LEV3_NAME = rootLevel == 1 ? 'Total' : (rootLevel == 2 ? element.split("||")[1]: '');
                            CHANNEL_LEV2_NAME = rootLevel == 1 ? '' : (rootLevel == 2 ? 'Total' : element.split("||")[2]);
                            CHANNEL_LEV3_NAME = (rootLevel == 1 || rootLevel == 2) ? '' : (rootLevel == 3 ? 'Total' : element.split("||")[3]);
                     } else if (searchby == 'ROUTE') {
                            SALES_HQ_LEV3_NAME = rootLevel == 1 ? element : '';
                            SALES_CENTER_NAME = rootLevel == 1 ? 'Total' : (rootLevel== 2 ? element.split("||")[1]: '' ) ;
                            SALES_CONTROL_BASE_NAME = rootLevel == 1 ? '' : (rootLevel == 2 ? 'Total' : element.split("||")[2]);
                            SALES_CONTROL_BASE_ROUTE_CODE = (rootLevel == 1 || rootLevel == 2) ? '' : (rootLevel == 3 ? 'Total' : element.split("||")[3]);
                     } else {
                            SALES_HQ_LEV2_NAME = rootLevel == 1 ? element : '';
                            SALES_HQ_LEV3_NAME = rootLevel == 1 ? 'Total' : (rootLevel == 2? element.split("||")[1] : '') ;
                            CHANNEL_LEV2_NAME = rootLevel == 1 ? '' : (rootLevel == 2 ? 'Total' : element.split("||")[2]);
                            CHANNEL_LEV3_NAME = (rootLevel == 1 || rootLevel == 2) ? '' : (rootLevel == 3 ? 'Total' : element.split("||")[3]);
                     }

                     let returnModel = {
                            IS_TOTALROW: true,
                            KEYOFROOT: element,
                            ROOTLEVEL: rootLevel,
                            SALES_HQ_LEV1_NAME: '',
                            SALES_HQ_LEV2_NAME: SALES_HQ_LEV2_NAME,
                            SALES_HQ_LEV3_NAME: SALES_HQ_LEV3_NAME,
                            CHANNEL_LEV2_NAME: CHANNEL_LEV2_NAME,
                            CHANNEL_LEV3_NAME: CHANNEL_LEV3_NAME,
                            SALES_CENTER_NAME: SALES_CENTER_NAME,
                            SALES_CONTROL_BASE_NAME: SALES_CONTROL_BASE_NAME,
                            SALES_CONTROL_BASE_ROUTE_CODE: SALES_CONTROL_BASE_ROUTE_CODE,
                            BAPC_TY: null,
                            BAPC_VS_BP: 0,
                            BAPC_DIFF_VS_BP: null,
                            BAPC_VS_LY: 0,
                            NSR_TY: null,
                            NSR_VS_BP: 0,
                            NSR_DIFF_VS_BP: null,
                            NSR_VS_LY: 0,
                            NSR_PER_CASE_TY: 0,
                            NSR_PER_CASE_BP: 0,
                            NSR_PER_CASE_LY: 0,
                            NSR_PER_CASE_DIFF_VS_BP: null,
                            NSR_PER_CASE_DIFF_VS_LY: null,
                            GP_TY: null,
                            GP_VS_BP: 0,
                            GP_DIFF_VS_BP: null,
                            GP_VS_LY: 0,
                            GP_PER_CASE_TY: 0,
                            GP_PER_CASE_BP: 0,
                            GP_PER_CASE_DIFF_VS_BP: null,
                            GP_PER_CASE_DIFF_VS_LY: null,
                            GP_PER_CASE_LY: 0,
                            BAPC_BP: 0,
                            BAPC_LY: 0,
                            NSR_BP: 0,
                            NSR_LY: 0,
                            GP_BP: 0,
                            GP_LY: 0
                     };
                     rawData.forEach(hqitem => {
                            if ((rootLevel == 1 && hqitem['KEYOFROOT'].includes(element))
                                   || (rootLevel == 2 && hqitem['KEYOFROOT'].includes(element))
                                   || (rootLevel == 3 && element.includes(hqitem[keyOfRoot1]) && element.includes(hqitem[keyOfRoot2]) && element.includes(hqitem[keyOfRoot3]))
                            ) {

                                   if (hqitem["BAPC_TY"] !== '') {
                                          returnModel.BAPC_TY += !hqitem["BAPC_TY"] ? 0 : parseFloat(hqitem["BAPC_TY"]);
                                   }

                                   // if (hqitem["BAPC_DIFF_VS_BP"] !== '' && hqitem["BAPC_DIFF_VS_BP"] != null) {
                                   //        returnModel.BAPC_DIFF_VS_BP += !hqitem["BAPC_DIFF_VS_BP"] ? 0 : parseFloat(hqitem["BAPC_DIFF_VS_BP"]);
                                   // }

                                   if (hqitem["NSR_TY"] !== '') {
                                          returnModel.NSR_TY += !hqitem["NSR_TY"] ? 0 : parseFloat(hqitem["NSR_TY"]);
                                   }

                                   // if (hqitem["NSR_DIFF_VS_BP"] !== '' && hqitem["NSR_DIFF_VS_BP"] !== null) {
                                   //        returnModel.NSR_DIFF_VS_BP += !hqitem["NSR_DIFF_VS_BP"] ? 0 : parseFloat(hqitem["NSR_DIFF_VS_BP"]);
                                   // }

                                   //returnModel.NSR_PER_CASE_TY +=!hqitem["NSR_PER_CASE_TY"]? 0: parseFloat(hqitem["NSR_PER_CASE_TY"]);
                                   //returnModel.NSR_PER_CASE_BP +=!hqitem["NSR_PER_CASE_BP"]? 0: parseFloat(hqitem["NSR_PER_CASE_BP"]);
                                   //returnModel.NSR_PER_CASE_LY +=!hqitem["NSR_PER_CASE_LY"]? 0: parseFloat(hqitem["NSR_PER_CASE_LY"]);
                                   //returnModel.NSR_PER_CASE_DIFF_VS_BP +=!hqitem["NSR_PER_CASE_DIFF_VS_BP"]? 0: parseFloat(hqitem["NSR_PER_CASE_DIFF_VS_BP"]);
                                   //returnModel.NSR_PER_CASE_DIFF_VS_LY +=!hqitem["NSR_PER_CASE_DIFF_VS_LY"]? 0: parseFloat(hqitem["NSR_PER_CASE_DIFF_VS_LY"]);

                                   if (hqitem["GP_TY"] !== '') {
                                          returnModel.GP_TY += !hqitem["GP_TY"] ? 0 : parseFloat(hqitem["GP_TY"]);
                                   }

                                   // if (hqitem["GP_DIFF_VS_BP"] !== '' && hqitem["GP_DIFF_VS_BP"] !== null) {
                                   //        returnModel.GP_DIFF_VS_BP += !hqitem["GP_DIFF_VS_BP"] ? 0 : parseFloat(hqitem["GP_DIFF_VS_BP"]);
                                   // }

                                   //returnModel.GP_PER_CASE_TY +=!hqitem["GP_PER_CASE_TY"]? 0: parseFloat(hqitem["GP_PER_CASE_TY"]);
                                   //returnModel.GP_PER_CASE_BP +=!hqitem["GP_PER_CASE_BP"]? 0: parseFloat(hqitem["GP_PER_CASE_BP"]);
                                   //returnModel.GP_PER_CASE_DIFF_VS_BP +=!hqitem["GP_PER_CASE_DIFF_VS_BP"]? 0: parseFloat(hqitem["GP_PER_CASE_DIFF_VS_BP"]);
                                   //returnModel.GP_PER_CASE_DIFF_VS_LY +=!hqitem["GP_PER_CASE_DIFF_VS_LY"]? 0: parseFloat(hqitem["GP_PER_CASE_DIFF_VS_LY"]);

                                   if (hqitem["BAPC_BP"] !== '') {
                                          returnModel.BAPC_BP += !hqitem["BAPC_BP"] ? 0 : parseFloat(hqitem["BAPC_BP"]);
                                   }

                                   if (hqitem["BAPC_LY"] !== '') {
                                          returnModel.BAPC_LY += !hqitem["BAPC_LY"] ? 0 : parseFloat(hqitem["BAPC_LY"]);
                                   }

                                   if (hqitem["NSR_BP"] !== '') {
                                          returnModel.NSR_BP += !hqitem["NSR_BP"] ? 0 : parseFloat(hqitem["NSR_BP"]);
                                   }

                                   if (hqitem["NSR_LY"] !== '') {
                                          returnModel.NSR_LY += !hqitem["NSR_LY"] ? 0 : parseFloat(hqitem["NSR_LY"]);
                                   }

                                   if (hqitem["GP_BP"] !== '') {
                                          returnModel.GP_BP += !hqitem["GP_BP"] ? 0 : parseFloat(hqitem["GP_BP"]);
                                   }

                                   if (hqitem["GP_LY"] !== '') {
                                          returnModel.GP_LY += !hqitem["GP_LY"] ? 0 : parseFloat(hqitem["GP_LY"]);
                                   }
                            }
                     });

                     returnModel.BAPC_DIFF_VS_BP = !returnModel.BAPC_BP ? null :  returnModel.BAPC_TY - returnModel.BAPC_BP;

                     returnModel.NSR_DIFF_VS_BP = !returnModel.NSR_BP ?   null :  returnModel.NSR_TY - returnModel.NSR_BP;
                     returnModel.GP_DIFF_VS_BP = !returnModel.GP_BP?   null : returnModel.GP_TY - returnModel.GP_BP;

                     returnModel.NSR_PER_CASE_TY = !returnModel.BAPC_TY ? null : returnModel.NSR_TY / returnModel.BAPC_TY;
                     returnModel.NSR_PER_CASE_BP = !returnModel.BAPC_BP ? null : returnModel.NSR_BP / returnModel.BAPC_BP;
                     returnModel.NSR_PER_CASE_LY = !returnModel.BAPC_LY ? null : returnModel.NSR_LY / returnModel.BAPC_LY;





                     returnModel.GP_PER_CASE_TY = !returnModel.BAPC_TY ? null : returnModel.GP_TY / returnModel.BAPC_TY;
                     returnModel.GP_PER_CASE_BP = !returnModel.BAPC_BP ? null : returnModel.GP_BP / returnModel.BAPC_BP;
                     returnModel.GP_PER_CASE_LY = !returnModel.BAPC_LY ? null : returnModel.GP_LY / returnModel.BAPC_LY;




                     returnModel.BAPC_VS_BP = !returnModel.BAPC_BP ? null : returnModel.BAPC_TY / returnModel.BAPC_BP - 1;
                     returnModel.BAPC_VS_LY = !returnModel.BAPC_LY ? null : returnModel.BAPC_TY / returnModel.BAPC_LY - 1;
                     returnModel.NSR_VS_BP = !returnModel.NSR_BP ? null : returnModel.NSR_TY / returnModel.NSR_BP - 1;
                     returnModel.NSR_VS_LY = !returnModel.NSR_LY ? null : returnModel.NSR_TY / returnModel.NSR_LY - 1;
                     returnModel.GP_VS_BP = !returnModel.GP_BP ? null : returnModel.GP_TY / returnModel.GP_BP - 1;
                     returnModel.GP_VS_LY = !returnModel.GP_LY ? null : returnModel.GP_TY / returnModel.GP_LY - 1;



                     if (returnModel.NSR_PER_CASE_TY !== null && returnModel.NSR_PER_CASE_BP !== null) {
                            returnModel.NSR_PER_CASE_DIFF_VS_BP = returnModel.NSR_PER_CASE_TY - returnModel.NSR_PER_CASE_BP;
                     }

                     if (returnModel.NSR_PER_CASE_TY !== null && returnModel.NSR_PER_CASE_LY !== null) {
                            returnModel.NSR_PER_CASE_DIFF_VS_LY = returnModel.NSR_PER_CASE_TY - returnModel.NSR_PER_CASE_LY;
                     }


                     if (returnModel.GP_PER_CASE_TY !== null && returnModel.GP_PER_CASE_BP !== null) {
                            returnModel.GP_PER_CASE_DIFF_VS_BP = returnModel.GP_PER_CASE_TY - returnModel.GP_PER_CASE_BP;
                     }

                     if (returnModel.GP_PER_CASE_TY !== null && returnModel.GP_PER_CASE_LY !== null) {
                            returnModel.GP_PER_CASE_DIFF_VS_LY = returnModel.GP_PER_CASE_TY - returnModel.GP_PER_CASE_LY;
                     }

                     totalRoot.push(returnModel);
              });
              return totalRoot;
       }

       static GetQuarters(){
              const currentQuarter = moment().format('YYYY-[Q]Q');
              const lastQuarter = moment().subtract(1, 'quarters').format('YYYY-[Q]Q');
              const twoQuartersAgo = moment().subtract(2, 'quarters').format('YYYY-[Q]Q');
              return [currentQuarter, lastQuarter, twoQuartersAgo];
       }


       static FormartNumber(category: string, number: any) {
              if ((number == null || number == 'null' || number === '')
                     && (category != 'SALES_HQ_LEV1_NAME' &&
                            category != "SALES_HQ_LEV2_NAME" &&
                            category != "CHANNEL_LEV2_NAME" &&
                            category != "CHANNEL_LEV3_NAME"  &&
                            category != "SALES_HQ_LEV3_NAME"  &&
                            category != "SALES_CENTER_NAME" &&
                            category != "SALES_CONTROL_BASE_NAME"  &&
                            category != "SALES_CONTROL_BASE_ROUTE_CODE")) {
                     return " - ";
              }
              switch (category) {

                     case 'BAPC_TY':
                            var numNoSign = parseFloat(number);
                            if (isNaN(numNoSign)) {
                                   return number;
                            }

                            numNoSign = Math.round(numNoSign);

                            if (numNoSign == 0) {
                                   return "0";
                            }
                            if (numNoSign < 1000 && numNoSign > -1000) {
                                   return numNoSign.toString();
                            }
                            const formattedSignValue = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(numNoSign);
                            return `${formattedSignValue}`;

                     case 'BAPC_DIFF_VS_BP':
                            const num = parseFloat(number);
                            if (isNaN(num)) {
                            return number;
                            }
                            if (num < 1000 && num > -1000) {
                            return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.round(num));
                            }
                            const formattedValue = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(num));
                            const signK =num== 0 ? '' : num < 0 ? '-' : '+';
                            return `${signK}${formattedValue}`;

                     case 'BAPC_VS_BP':
                     case 'BAPC_VS_LY':
                     case 'NSR_VS_BP':
                     case 'NSR_VS_LY':
                     case 'GP_VS_BP':
                     case 'GP_VS_LY':
                            const numper = parseFloat(number);
                            if (isNaN(numper)) {
                                   return number;
                            }
                            const formattedPer: string = (numper * 100).toFixed(1) + "%";
                            const sign1 = numper == 0 ? '' : numper < 0 ? '' : '+';
                            return sign1 + formattedPer;



                     case 'NSR_PER_CASE_TY':
                     case 'NSR_PER_CASE_DIFF_VS_LY':
                     case 'GP_PER_CASE_DIFF_VS_LY':
                     case 'GP_PER_CASE_TY':
                     case 'NSR_PER_CASE_DIFF_VS_BP':
                     case 'GP_PER_CASE_DIFF_VS_BP':
                            const numperYen = parseFloat(number);
                            if (isNaN(numperYen)) {
                                   return number;
                            }

                            const formattedYen = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(numperYen));
                            const signYen = numperYen == 0 ? '' : numperYen < 0 ? '-' : '+';
                            return `${signYen}¥${formattedYen}`;

                     case 'GP_TY':
                     case 'NSR_TY':
                            const numperYenMnoesign = parseFloat(number);
                            if (isNaN(numperYenMnoesign)) {
                                   return number;
                            }
                            const formattedYenMnosign = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(numperYenMnoesign) / 1000);
                            return `¥${formattedYenMnosign}K`;
                     case 'NSR_DIFF_VS_BP':
                     case 'GP_DIFF_VS_BP':
                            const numperYenM = parseFloat(number);
                            if (isNaN(numperYenM)) {
                                   return number;
                            }
                            const formattedYenM = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Math.abs(numperYenM) / 1000);
                            const sign = numperYenM == 0 ? '' : numperYenM < 0 ? '-' : '+';
                            return `${sign}¥${formattedYenM}K`;
                     case 'GP_DIFF_VS_BP':
                            const numperfix1 = parseFloat(number);
                            if (isNaN(numperfix1)) {
                                   return number;
                            }
                            const formattedfix1: string = (numperfix1).toFixed(1);
                            return formattedfix1;
                     default:
                            return number;
              }
       }

       static formartClassForColumn(isLunisolarColor: boolean, value: any) {
              if (!isLunisolarColor || !value) {
                     return '';
              }
              else {
                     return value.toString().indexOf('-') == 0 ? 'text-red' : 'text-green';
              }
       }

       static distinctName(value, index, self) {
              return self.indexOf(value) === index;
       }

       static distinctArray(inputArray: any[]): any[] {
              const uniqueValues = new Set(inputArray.map(item => item.value));

              const resultArray: any[] = Array.from(uniqueValues).map(value => {
                     const correspondingItem = inputArray.find(item => item.value === value);
                     return correspondingItem || { value, text: '' };
              });

              return resultArray;
       }

       static ListColumnProgressByDistrict() {
              return [
                     {
                            category: "SALES_HQ_LEV2_NAME",
                            displayName: "Sales HQ Lev2 Name",
                            color: "#8B90A3",
                            index: 1,
                            isSummarySum: true,
                            enableSort: false
                     },
                     {
                            category: "SALES_HQ_LEV3_NAME",
                            displayName: "Sales HQ Lev3 Name",
                            color: "#8B90A3",
                            index: 1,
                            isSummarySum: true,
                            enableSort: false,
                            showTotalInLevel: 2,
                            root2hasJapaneseCharacters: true
                     },
                     {
                            category: "CHANNEL_LEV1_NAME",
                            displayName: "Channel Lev1 Name",
                            color: "#C69C83",
                            index: 2,
                            isSummarySum: true,
                            enableSort: false,
                            showTotalInLevel: 3
                     },
                     {
                            category: "CHANNEL_LEV2_NAME",
                            displayName: "Channel Lev2 Name",
                            color: "#C69C83",
                            index: 3,
                            isSummarySum: true,
                            enableSort: false,
                            showTotalInLevel: 3
                     },
                     {
                            category: "CHANNEL_LEV3_NAME",
                            displayName: "Channel Lev3 Name",
                            color: "#93AFB9",
                            index: 4,
                            enableSort: false,
                            showTotalInLevel: 4
                     },
                     {
                            category: "BAPC_TY",
                            displayName: "BAPC TY",
                            color: "#4DA2D6",
                            index: 5
                     },
                     {
                            category: "BAPC_VS_BP",
                            displayName: "BAPC%<br/>VS BP",
                            color: "#FCCF4D",
                            index: 6,
                            isLunisolarColor: true
                     },
                     {
                            category: "BAPC_DIFF_VS_BP",
                            displayName: "BAPC<br/>DIFF VS<br/>BP",
                            color: "#9A64A7",
                            index: 7,
                            isLunisolarColor: true
                     },
                     {
                            category: "BAPC_VS_LY",
                            displayName: "BAPC%<br/>VS LY",
                            color: "#4FB9C1",
                            index: 8,
                            isLunisolarColor: true
                     },

                     {
                            category: "NSR_TY",
                            displayName: "NSR TY",
                            color: "#F08258",
                            index: 9
                     },
                     {
                            category: "NSR_VS_BP",
                            displayName: "NSR%<br/>VS BP",
                            color: "#F40009",
                            index: 10,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_DIFF_VS_BP",
                            displayName: "NSR<br/>DIFF VS<br/>BP",
                            color: "#A09498",
                            index: 11,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_VS_LY",
                            displayName: "NSR%<br/>VS LY",
                            color: "#BCC3D1",
                            index: 11,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_PER_CASE_TY",
                            displayName: "NSR<br/>PER CASE<br/>TY",
                            color: "#BCC3D1",
                            index: 12
                     },
                     {
                            category: "NSR_PER_CASE_BP",
                            displayName: "NSR<br/>PER CASE BP",
                            color: "#BCC3D1",
                            index: 13,
                            isDisplay: false
                     },
                     {
                            category: "NSR_PER_CASE_LY",
                            displayName: "NSR PER CASE LY",
                            color: "#BCC3D1",
                            index: 14,
                            isDisplay: false
                     },
                     {
                            category: "NSR_PER_CASE_DIFF_VS_BP",
                            displayName: "NSR<br/>PER CASE<br/>DIFF<br/>VS BP",
                            color: "#BCC3D1",
                            index: 15,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_PER_CASE_DIFF_VS_LY",
                            displayName: "NSR<br/>PER CASE<br/>DIFF<br/>VS LY",
                            color: "#BCC3D1",
                            index: 16,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_TY",
                            displayName: "GP TY",
                            color: "#BCC3D1",
                            index: 17
                     },
                     {
                            category: "GP_VS_BP",
                            displayName: "GP%<br/>VS BP",
                            color: "#BCC3D1",
                            index: 18,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_DIFF_VS_BP",
                            displayName: "GP<br/>DIFF VS<br/>BP",
                            color: "#BCC3D1",
                            index: 19,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_VS_LY",
                            displayName: "GP% VS LY",
                            color: "#BCC3D1",
                            index: 20,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_PER_CASE_TY",
                            displayName: "GP<br/>PER CASE<br/>TY",
                            color: "#BCC3D1",
                            index: 21
                     },
                     {
                            category: "GP_PER_CASE_BP",
                            displayName: "GP PER CASE BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "GP_PER_CASE_DIFF_VS_BP",
                            displayName: "GP<br/>PER CASE<br/>DIFF<br/>VS BP",
                            color: "#BCC3D1",
                            index: 23,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_PER_CASE_DIFF_VS_LY",
                            displayName: "GP<br/>PER CASE<br/>DIFF<br/>VS LY",
                            color: "#BCC3D1",
                            index: 24,
                            isLunisolarColor: true
                     },
                     {
                            category: "BAPC_BP",
                            displayName: "BAPC BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "BAPC_LY",
                            displayName: "BAPC LY",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "NSR_BP",
                            displayName: "NSR BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "NSR_LY",
                            displayName: "NSR LY",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "GP_BP",
                            displayName: "GP BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "GP_LY",
                            displayName: "GP LY",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     }
              ];
       }

       static ListColumnByRoute() {
              return [
                     {
                            category: "SALES_HQ_LEV3_NAME",
                            displayName: "Sales HQ Lev3 Name",
                            color: "#8B90A3",
                            index: 1,
                            isSummarySum: true,
                            textAlign: 'left',
                            enableSort: false,
                            root1hasJapaneseCharacters: true
                     },
                     {
                            category: "SALES_CENTER_NAME",
                            displayName: "Sales Center Name",
                            color: "#C69C83",
                            index: 2,
                            isSummarySum: true,
                            textAlign: 'left',
                            enableSort: false,
                            showTotalInLevel: 2,
                            root2hasJapaneseCharacters: true
                     },
                     {
                            category: "SALES_CONTROL_BASE_NAME",
                            displayName: "Sales Control Base Name",
                            color: "#93AFB9",
                            index: 3,
                            textAlign: 'left',
                            isSummarySum: true,
                            enableSort: false,
                            showTotalInLevel: 3,
                            root3hasJapaneseCharacters: true
                     },
                     {
                            category: "SALES_CONTROL_BASE_ROUTE_CODE",
                            displayName: "Sales Control Base Route Code",
                            color: "#93AFB9",
                            index: 3,
                            textAlign: 'left',
                            enableSort: false,
                            showTotalInLevel: 4
                     },
                     {
                            category: "BAPC_TY",
                            displayName: "BAPC TY",
                            color: "#4DA2D6",
                            index: 4
                     },
                     {
                            category: "BAPC_VS_BP",
                            displayName: "BAPC%<br/>VS BP",
                            color: "#FCCF4D",
                            index: 5,
                            isLunisolarColor: true
                     },
                     {
                            category: "BAPC_DIFF_VS_BP",
                            displayName: "BAPC<br/>DIFF VS<br/>BP",
                            color: "#9A64A7",
                            index: 6,
                            isLunisolarColor: true
                     },
                     {
                            category: "BAPC_VS_LY",
                            displayName: "BAPC%<br/>VS LY",
                            color: "#4FB9C1",
                            index: 7,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_TY",
                            displayName: "NSR TY",
                            color: "#F08258",
                            index: 8
                     },
                     {
                            category: "NSR_VS_BP",
                            displayName: "NSR%<br/>VS BP",
                            color: "#F40009",
                            index: 9,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_DIFF_VS_BP",
                            displayName: "NSR<br/>DIFF VS<br/>BP",
                            color: "#A09498",
                            index: 11,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_VS_LY",
                            displayName: "NSR%<br/>VS LY",
                            color: "#BCC3D1",
                            index: 11,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_PER_CASE_TY",
                            displayName: "NSR<br/>PER CASE<br/>TY",
                            color: "#BCC3D1",
                            index: 12
                     },
                     {
                            category: "NSR_PER_CASE_BP",
                            displayName: "NSR<br/>PER CASE BP",
                            color: "#BCC3D1",
                            index: 13,
                            isDisplay: false
                     },
                     {
                            category: "NSR_PER_CASE_LY",
                            displayName: "NSR PER CASE LY",
                            color: "#BCC3D1",
                            index: 14,
                            isDisplay: false
                     },
                     {
                            category: "NSR_PER_CASE_DIFF_VS_BP",
                            displayName: "NSR<br/>PER CASE<br/>DIFF<br/>VS BP",
                            color: "#BCC3D1",
                            index: 15,
                            isLunisolarColor: true
                     },
                     {
                            category: "NSR_PER_CASE_DIFF_VS_LY",
                            displayName: "NSR<br/>PER CASE<br/>DIFF<br/>VS LY",
                            color: "#BCC3D1",
                            index: 16,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_TY",
                            displayName: "GP TY",
                            color: "#BCC3D1",
                            index: 17
                     },
                     {
                            category: "GP_VS_BP",
                            displayName: "GP%<br/>VS BP",
                            color: "#BCC3D1",
                            index: 18,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_DIFF_VS_BP",
                            displayName: "GP<br/>DIFF VS<br/>BP",
                            color: "#BCC3D1",
                            index: 19,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_VS_LY",
                            displayName: "GP% VS LY",
                            color: "#BCC3D1",
                            index: 20,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_PER_CASE_TY",
                            displayName: "GP<br/>PER CASE<br/>TY",
                            color: "#BCC3D1",
                            index: 21
                     },
                     {
                            category: "GP_PER_CASE_BP",
                            displayName: "GP PER CASE BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "GP_PER_CASE_DIFF_VS_BP",
                            displayName: "GP<br/>PER CASE<br/>DIFF<br/>VS BP",
                            color: "#BCC3D1",
                            index: 23,
                            isLunisolarColor: true
                     },
                     {
                            category: "GP_PER_CASE_DIFF_VS_LY",
                            displayName: "GP<br/>PER CASE<br/>DIFF<br/>VS LY",
                            color: "#BCC3D1",
                            index: 24,
                            isLunisolarColor: true
                     },
                     {
                            category: "BAPC_BP",
                            displayName: "BAPC BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "BAPC_LY",
                            displayName: "BAPC LY",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "NSR_BP",
                            displayName: "NSR BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "NSR_LY",
                            displayName: "NSR LY",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "GP_BP",
                            displayName: "GP BP",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
                     {
                            category: "GP_LY",
                            displayName: "GP LY",
                            color: "#BCC3D1",
                            index: 22,
                            isDisplay: false
                     },
              ];
       }


       static ListColumnTimeline() {
              return [
                     {
                            category: "FIRST_DAY_OF_WEEK",
                            displayName: "FIRST_DAY_OF_WEEK",
                            color: "#8B90A3",
                            index: 1
                     },
                     {
                            category: "WEEK_OF_YEAR",
                            displayName: "WEEK OF YEAR",
                            color: "#C69C83",
                            index: 2
                     },
                     {
                            category: "GP_VS_LY",
                            displayName: "GP VS LY",
                            color: "#93AFB9",
                            index: 3
                     },
                     {
                            category: "GP_TY",
                            displayName: "GP TY",
                            color: "#4DA2D6",
                            index: 4
                     },
                     {
                            category: "NSR_VS_LY",
                            displayName: "NSR VS LY",
                            color: "#FCCF4D",
                            index: 5
                     },

                     {
                            category: "NSR_TY",
                            displayName: "NSR TY",
                            color: "#4FB9C1",
                            index: 7
                     },
                     {
                            category: "BAPC_VS_LY",
                            displayName: "BAPC VS LY",
                            color: "#F08258",
                            index: 8
                     },

                     {
                            category: "BAPC_TY",
                            displayName: "BAPC TY",
                            color: "#A09498",
                            index: 11
                     }
              ];
       }

       static getStartAndEndDateOfMonth(inputDate: string) {
              const [year, month]   = inputDate?.split('-')?.map(Number);
              const firstDayOfMonth = new Date(year, month - 1, 1);
              const lastDayOfMonth  = new Date(year, month, 0);
              const firstDayFormatted = `${firstDayOfMonth?.getFullYear()}-${(firstDayOfMonth?.getMonth() + 1)?.toString()?.padStart(2, '0')}-${firstDayOfMonth?.getDate()?.toString()?.padStart(2, '0')}`;
              const lastDayFormatted  = `${lastDayOfMonth?.getFullYear()}-${(lastDayOfMonth?.getMonth() + 1)?.toString()?.padStart(2, '0')}-${lastDayOfMonth?.getDate()?.toString()?.padStart(2, '0')}`;

              return [firstDayFormatted, lastDayFormatted];
       }

       static getQuarterOfYear(inputDate: string) {
              const [year, month] = inputDate.split('-')?.map(Number);
              const quarter = Math?.ceil(month / 3);
              const result = `${year}${quarter}`;

              return result;
       }

       static getYearFromDate(inputDate: string) {
              const [year] = inputDate?.split('-');
              return year;
       }
}
