// sales-date.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { AnalyticsQueries } from 'src/app/model/constants';

import {HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesDateService {
  private seDateInJobClearCache = new BehaviorSubject<string[]>([]);
  private traxDateInJobClearCache = new BehaviorSubject<string[]>([]);

  constructor(private http: HttpClient) {}

  // SE Report
  setSalesDate(value: string[]) {
    return this.seDateInJobClearCache.next(value);
  }

  getSESalesDate() {
    return this.seDateInJobClearCache.asObservable();
  }

  getDateInJobClearCache() {
    if (this.seDateInJobClearCache.value.length > 0) {
      return;
    }

    const queryUrl = environment.hygraphGetSEJobUpdate;
    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    }

    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
        response => {
            let res = JSON.parse(response);
            if (res.data && res.data[environment.clearCacheSEJobStatus] != null && res.data[environment.clearCacheSEJobStatus].length > 0) {
              const latest_date = res.data[environment.clearCacheSEJobStatus][0].date;
              const year = latest_date.substring(0, 4) || '';
              const month = latest_date.substring(4, 6) || '';
              const day = latest_date.substring(6, 8) || '';
              localStorage.setItem('se.dateinjobclearcache', `${year}-${month}-${day}`);
              this.setSalesDate([`${year}-${month}-${day}`]);
            } else {
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const year  = yesterday.getFullYear();
              const month = (yesterday.getMonth() + 1).toString().padStart(2, '0');
              const day  = yesterday.getDate().toString().padStart(2, '0');
              localStorage.setItem('se.dateinjobclearcache', `${year}-${month}-${day}`);
              this.setSalesDate([`${year}-${month}-${day}`]);
            }
        },
        error =>
        {
            console.log(error);
        }
    );
  }

  // Trax Report
  getTraxDateInJobClearCache() {
    const maxChecks = 50;
    let checks      = 0

    const checkDataInterval = setInterval(() => {
      const lastUpdated = JSON.parse(localStorage.getItem('lastUpdated')) || {};

      if (lastUpdated && this.isValidTraxLastUpdated(lastUpdated)) {
        this.setTraxSalesDate([lastUpdated]);
        clearInterval(checkDataInterval);
      } else if (checks >= maxChecks) {
        clearInterval(checkDataInterval);
      }

      checks++;
    }, 1000);
  }

  setTraxSalesDate(value: string[]) {
    return this.traxDateInJobClearCache.next(value);
  }

  getTraxSalesDate() {
    return this.traxDateInJobClearCache.asObservable();
  }

  // Helper
  isValidTraxLastUpdated(lastUpdate: { name: string, date: string }): boolean {
    const { name, date } = lastUpdate;

    if (!AnalyticsQueries || !AnalyticsQueries.lastUpdatedTraxReport) {
      return false;
    }

    if (name === AnalyticsQueries.lastUpdatedTraxReport && typeof date === 'string' && date.length > 0) {
      return true;
    }

    return false;
  }
}
