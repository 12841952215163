<div id="nsr-sales-trend">
    <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h3>NSR Trend (vs. Previous Years)</h3>
        </div>

        <div class="filter-radio">
            <div class="items">
                <div class="item">
                    <input class="form-check-input" type="radio" name="vsRadios" id="vs2019" value="vs2019"
                        [checked]="displayVs2019" (change)="onVsRadioSelect(true)">
                    <label for="vs2019">2019</label>
                </div>
                <div class="item">
                    <input class="form-check-input" type="radio" name="vsRadios" id="vsBp" value="vsBp"
                        [checked]="!displayVs2019" (change)="onVsRadioSelect(false)">
                    <label for="vsBp">BP</label>
                </div>        
            </div>
        </div>

        <div class="filter-section">
            <div class="ng-section summary-section">
                <b>Channel</b>
                <ng-select #ngFilter class="filter-by-channel" [searchable]="false" [virtualScroll]="true"
                    [(ngModel)]="salesTrendChannel" [multiple]="true" [closeOnSelect]="false"
                    [clearable]="salesTrendChannel.length > 1" (remove)="onRemoveFilter($event)"
                    (change)="onRemoveFilter($event);onChangeCheckBox($event)" (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)" (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilter($event)">
                    <ng-option *ngFor="let item of channels" [value]="item.name">
                        <input type="checkbox" class="cb-multi-select"/> {{isSmddChild(item.name) ? "&nbsp;&nbsp;&nbsp;" : ""}}{{item.name}}
                    </ng-option>
                    <ng-template ng-footer-tmp>
                        <button type="submit" class="btn btn-primary" [disabled]="salesTrendChannel.length == 0"
                            (click)="ngFilter.close();SaveOptionValueTemp(false);renderNsrSalesTrendChart()">Aggregate</button>
                    </ng-template>
                    <ng-template ng-multi-label-tmp let-items="items">
                        <ng-container *ngFor="let item of items; index as i">
                            <ng-container *ngIf="i==0">
                                {{item}}
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="items.length > 1">
                            ...
                        </ng-container>
                    </ng-template>
                </ng-select>                
            </div>
        </div>
        <p></p>
        <div class="card-body">
            <div id="nsr-sales-trend-chart"></div>

            <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                <!-- <comment-button
                    title1="NSR Trend (vs. Previous Years)"
                    title2="Display Vs - {{displayVs2019 ? '2019' : 'BP'}}"
                    chartId="nsr-sales-trend-chart"
                    [chartRef]="nsrSalesTrendChart"
                    [chatIdDependency]="[{selectedDate, state: {displayVs2019,displayBP,salesTrendChannel}}]"
                ></comment-button> -->

                <download-button>
                    <button ngbDropdownItem (click)="downloadPng()">Download PNG</button>
                    <button ngbDropdownItem (click)="downloadCSV()">Download CSV</button>
                </download-button>
            </div>
        </div>
    </div>
</div>