<div *ngIf="!isCategoryReady || !isAccountReady">
  <div class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="loading-overlay">
  <div class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</div>

<div class="poc-component" [class]="isCategoryReady && isAccountReady ? '' : 'not-show'">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card mb-30">
        <div class="title-header">
          <h2>PoC</h2>
        </div>

        <sales-date-filter
          pageName="PoC"
          (onDateTypeSaveItemsChange)="onDateTypeSaveItemsChange($event)"></sales-date-filter>
      </div>

      <div>
        <poc-by-category
          [lstOptionSelectedDateType]="lstOptionAggregatedDateType"
          [lstOptionSelectedSalesDate]="lstOptionSelectedSalesDate"
          (ready)="categoryReady($event)"
          (filterReady)="categoryFilterReady($event)"></poc-by-category>

        <poc-by-account
          [lstOptionSelectedDateType]="lstOptionAggregatedDateType"
          [lstOptionSelectedSalesDate]="lstOptionSelectedSalesDate"
          (ready)="accountReady($event)"
          (filterReady)="accountFilterReady($event)"></poc-by-account>
      </div>
    </div>
  </div>
</div>
