<div class="position-relative">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs se-nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>{{markerTitle}}</a>
      <ng-template ngbNavContent>
        <div class="se-ngb-nav-content">
          <div class="card">
            <div class="card-header">
              <h3 class="title">{{markerTitle}}</h3>
            </div>

            <app-photo-report-filter
              [filterModuleName]="'faceshare-by-maker-category'"
              [filters]="filters"
              [lstOption_IsUsed]="lstOptionIsUsed.MARKET_CATEGORY"
              [lstAllOptionsData]="lstAllOptionsData"
              [lstOption_Selected]="lstOptionSelected.MARKET_CATEGORY"
              (onUpdateChart)="onUpdateChart($event, 'MARKET_CATEGORY')"
            ></app-photo-report-filter>

            <div *ngIf="isLoading" class="text-center spinner-wrapper">
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <!-- start Marker table -->
            <div class="row" *ngIf="!isLoading">
              <div class="col-lg-12 col-md-12">
                <div #scroll class="market-size">
                  <div class="market-size__content">
                    <table #screen>
                      <ng-container *ngIf="chartData['MARKET_CATEGORY']?.length">
                        <thead>
                          <tr *ngIf="categories['MARKET_CATEGORY']?.length">
                            <th class="totals" colspan="2">
                              (Sample: {{ chartData['MARKET_CATEGORY'][0]?.STORE_TM_Display }})
                            </th>

                            <th *ngFor="let category of categories['MARKET_CATEGORY']; let i = index" [ngClass]="category.value.toLowerCase()">
                              {{ category.label }}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container *ngFor="let item of chartData['MARKET_CATEGORY']; let i = index">
                            <tr>
                              <td class="col__first" *ngIf="item[manufactureKey]?.length && item['row_span']" [attr.rowspan]="item['row_span']">
                                <ng-container *ngIf="['KO', 'Asahi', 'Itoen', 'Kirin', 'Suntory'].includes(item[manufactureKey]); else defaultLabel">
                                  <img [class]="item[manufactureKey].toLowerCase()" [alt]="item[manufactureKey]" [src]="'assets/img/maker/' + getMarkerImgUrl(item[manufactureKey])" [attr.loading]="'lazy'" />
                                </ng-container>

                                <ng-template #defaultLabel>
                                  <b>{{ formatManufactureName(item[manufactureKey]) }}</b>
                                </ng-template>
                              </td>

                              <td class="col__second">{{ getTypeDisplay(item[manufactureKey], item['TYPE']) }}</td>

                              <td *ngFor="let category of categories['MARKET_CATEGORY']" class="col">
                                <span [ngClass]="{ 'text-red': markerShouldHighlight(item, category.value) }">
                                  {{ getMarkerValueDisplay(item, category.value) }}
                                </span>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </ng-container>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- end Marker table -->
          </div>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="2">
      <a ngbNavLink>{{areaTitle}}</a>
      <ng-template ngbNavContent>
        <div class="se-ngb-nav-content">
          <div class="card">
            <div class="card-header">
              <h3 class="title">{{areaTitle}}</h3>
            </div>

            <app-photo-report-filter
              [filters]="filters"
              [lstOption_IsUsed]="lstOptionIsUsed.AREA"
              [lstAllOptionsData]="lstAllOptionsData"
              [lstOption_Selected]="lstOptionSelected.AREA"
              (onUpdateChart)="onUpdateChart($event, 'AREA')"
            ></app-photo-report-filter>

            <div *ngIf="isLoading" class="text-center spinner-wrapper">
              <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
              </div>
            </div>

            <!-- start Area table -->
            <div class="row" *ngIf="!isLoading">
              <div class="col-lg-12 col-md-12">
                <div #scroll class="market-size">
                  <div class="area-size__content">
                    <table #screen>
                      <ng-container *ngIf="chartData['AREA']?.length">
                        <thead>
                          <tr>
                            <th rowspan="2" class="col__first" scope="col"></th>
                            <th rowspan="2" class="col__second" scope="col">Sample</th>
                            <th colspan="6" scope="col">Share</th>
                            <th colspan="6" scope="col">Diff vs LY</th>
                          </tr>
                          <tr>
                            <th *ngFor="let category of categories['AREA']; let i = index" [class]="category.value.toLowerCase()" scope="col">
                              <ng-container *ngIf="['KO_SHARE', 'KO_DIFF_VS_LY', 'ASAHI_SHARE', 'ASAHI_DIFF_VS_LY', 'ITOEN_SHARE', 'ITOEN_DIFF_VS_LY', 'KIRIN_SHARE', 'KIRIN_DIFF_VS_LY', 'SUNTORY_SHARE', 'SUNTORY_DIFF_VS_LY'].includes(category.value); else defaultLabel">
                                <img [alt]="category.label" [src]="'assets/img/maker/' + category.image" [attr.loading]="'lazy'" />
                              </ng-container>

                              <ng-template #defaultLabel>
                                <b class="text-uppercase">{{ category.label }}</b>
                              </ng-template>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <ng-container *ngFor="let item of chartData['AREA']; let i = index">
                            <tr>
                              <td class="col__first">{{ item['SALES_DISTRICT_GROUP'] }}</td>
                              <td class="col__second">{{ item['STORE_TM_Display'] }}</td>
                              <td *ngFor="let category of categories[areaKey]; index as i" [class]="category.value.toLowerCase()">
                                <span [ngClass]="{ 'text-red': item[category.value] <= 0 }">{{ getAreaValueDisplay(category.value, item[category.value], item[category.value + '_Display']) }}</span>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </ng-container>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- end Area table -->
          </div>
        </div>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
  <a #downloadLink style="display: none;"></a>
  <div class="d-inline-flex section-download">
    <button type="button" class="btn btn-download-excel download-button-tracking" data-section="by CCBJI" (click)="downloadExcel()">
      <span>Download Excel</span>
      <ng-icon name="IonDownloadOutline" size="16px" strokeWidth="46px"></ng-icon>
    </button>
  </div>
</div>
