import { Component, OnInit, OnDestroy, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

import moment from "moment";

import { authorize } from 'src/app/helpers/AuthorizePage';
import { SkuDeepdiveHelper} from './sku-deepdive-helper';
import { FILTER_CONFIGS } from 'src/app/model/constants';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
import { SalesDateService } from '../../elements/sales-date-filter/sales-date-filter.service';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'app-sku-deepdive',
  templateUrl: './sku-deepdive.component.html',
  styleUrls: ['./sku-deepdive.component.scss'],
  host     : {
    'class': 'se-report-content'
  }
})

export class SkuDeepdiveComponent implements OnInit, OnDestroy {
  FILTERS: any = FILTER_CONFIGS?.SKU_DEEPDIVE_DATE;

  isLoading: boolean = false;
  private loadingSubscription: Subscription;
  private salesDateSubscription: Subscription

  isVsLyCategoryReady: boolean;
  isVsLyBrandReady: boolean;
  isVsLyPackageReady: boolean;
  isVsLySKUReady: boolean;
  isVsLyTimelineReady: boolean;

  lstAllOptionsCalendarData: any;
  lstAllOptionsAreaData: any;
  lstAllOptionsAccountData: any;
  lstAllOptionsProductData: any;

  lstOption_DateType : any = [];
  lstOption_Selected_DateType: any = '';
  lstOption_Aggregated_DateType: any = '';
  lstOption_Selected_SalesDate: any = [];
  userGroupName: string;

  dailySalesDateRange: any = {
    ready: false,
    fromDate: null,
    toDate : null,
    maxDate: null,
    minDate: null,
    previousFromDate: null,
    previousToDate: null
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private salesDateService: SalesDateService,
    private userService: UserService,
  ) {}

  async ngOnInit() {
    if(!authorize()){
      this.router.navigateByUrl('unauthorized');
    } else {
      this.loadingSubscription = SkuDeepdiveHelper.isLoading().subscribe((loading: boolean) => {
        this.isLoading = loading;
      });

      this.userGroupName = this.userService.getUserInfo() || '';

      this.salesDateSubscription = this.salesDateService.getSESalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          SkuDeepdiveHelper.GetAllFilterCalendarOptions(this.http, date[0], this.userGroupName).then((data) => {
            if (data) {
              this.lstAllOptionsCalendarData = data;
            }
          });

          SkuDeepdiveHelper.GetAllFilterAreaOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsAreaData = data;
          });

          SkuDeepdiveHelper.GetAllFilterAccountOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsAccountData = data;
          });

          SkuDeepdiveHelper.GetAllFilterProductOptions(this.http, date[0], this.userGroupName).then((data) => {
            this.lstAllOptionsProductData = data;
          });
        }
      });

    }
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  convertToNgbDateStruct(date: Date): NgbDateStruct {
    return {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
    };
 }

 private getOptionSaleDate(value1: string, value2: string, isInit: boolean) {
    const fromObj = new Date(value1);
    const toObj = new Date(value2);

    let todate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
    let fromdate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
    this.dailySalesDateRange.toDate   = todate;
    this.dailySalesDateRange.fromDate = fromdate;
    this.dailySalesDateRange.previousToDate = todate;
    this.dailySalesDateRange.previousFromDate = fromdate;

    if (isInit) {
      let maxDate = new Date(toObj.getFullYear(), toObj.getMonth(), toObj.getDate());
      let minDate = new Date(fromObj.getFullYear(), fromObj.getMonth(), fromObj.getDate());
      this.dailySalesDateRange.maxDate = this.convertToNgbDateStruct(maxDate);
      this.dailySalesDateRange.minDate = this.convertToNgbDateStruct(minDate);
    }

    this.dailySalesDateRange.ready  = true;
  }

  public onDateTypeSaveItemsChange = (e: any) => {

    dispatchDateSelectChange({ ...e, ...{ element: "sku-deepdive" }, ...{ isDateRange: true } });
    const {selectedDateType, selectedSalesDate} = e;

    if (selectedDateType) {
      this.lstOption_Aggregated_DateType = selectedDateType;
    }

    if (selectedSalesDate) {
      this.lstOption_Selected_SalesDate = [...selectedSalesDate];
    }
  }

  public storeFilterOption(){
    FilterHelper.store_lstOption_States('SkuDeepdiveComponent',
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.lstOption_Selected_DateType,
        this.lstOption_Selected_SalesDate,
        this.dailySalesDateRange,
        this.userGroupName);
  }

  public onDateSelect(value: any) {
    if (value) {
      const { from, to } = value;

      if (from && to) {
        const startDate = moment(new Date(from)).format("YYYY-MM-DD");
        const endDate   = moment(new Date(to)).format("YYYY-MM-DD");

        if (!moment(startDate).isSame(this.dailySalesDateRange.previousFromDate) || !moment(endDate).isSame(this.dailySalesDateRange.previousToDate)) {
          this.lstOption_Selected_SalesDate = [startDate, endDate];
          this.getOptionSaleDate(startDate, endDate, false);
          this.storeFilterOption();
        }
      }
    }
  }

  public vsLyCategoryReady(ready: boolean){
    this.isVsLyCategoryReady = ready;
  }
  public vsLyBrandReady(ready: boolean){
    this.isVsLyBrandReady = ready;
  }
  public vsLyPackageReady(ready: boolean){
    this.isVsLyPackageReady = ready;
  }

  public vsLySKUReady(ready: boolean){
    this.isVsLySKUReady = ready;
  }

  public vsLyTimeLineReady(ready: boolean){
    this.isVsLyTimelineReady = ready;
  }
}
