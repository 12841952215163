import { FILTER_CONFIGS } from 'src/app/model/constants';
import { localStorageHelper } from 'src/app/helpers/localStorage';

export class FilterHelper {
  static isAllSelected = (arr1: any) => {
    return JSON.stringify(arr1) === JSON.stringify(['All']);
  }

  // Only for SkuDeepdive, AreaDeepdive
  static getQueryObj(filters: any, keyName: string, lstOptionSelected: any) {
    let queryObj = {};

    switch (keyName) {
      case filters.CHANNEL_LEV1_NAME:
        break;

      case filters.CHANNEL_LEV2_NAME:
        if (!isAllSelected(lstOptionSelected.CHANNEL_LEV1_NAME)) {
          queryObj['CHANNEL_LEV1_NAME'] = lstOptionSelected.CHANNEL_LEV1_NAME;
        }
        break;

      case filters.CHANNEL_LEV3_NAME:
        break;

      case filters.SALES_HQ_LEV1_NAME:
        break;

      case filters.SALES_HQ_LEV2_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryObj['SALES_HQ_LEV1_NAME'] = lstOptionSelected.SALES_HQ_LEV1_NAME;
        }
        break;

      case filters.SALES_HQ_LEV3_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryObj['SALES_HQ_LEV1_NAME'] = lstOptionSelected.SALES_HQ_LEV1_NAME;

        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryObj['SALES_HQ_LEV2_NAME'] = lstOptionSelected.SALES_HQ_LEV2_NAME;
        }
        break;

      case filters.SALES_CENTER_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryObj['SALES_HQ_LEV1_NAME'] = lstOptionSelected.SALES_HQ_LEV1_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryObj['SALES_HQ_LEV2_NAME'] = lstOptionSelected.SALES_HQ_LEV2_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV3_NAME)) {
          queryObj['SALES_HQ_LEV3_NAME'] = lstOptionSelected.SALES_HQ_LEV3_NAME;
        }
        break;

      case filters.SALES_ROUTE_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryObj['SALES_HQ_LEV1_NAME'] = lstOptionSelected.SALES_HQ_LEV1_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryObj['SALES_HQ_LEV2_NAME'] = lstOptionSelected.SALES_HQ_LEV2_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV3_NAME)) {
          queryObj['SALES_HQ_LEV3_NAME'] = lstOptionSelected.SALES_HQ_LEV3_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_CENTER_NAME)) {
          queryObj['SALES_CENTER_NAME'] = lstOptionSelected.SALES_CENTER_NAME;
        }
        break;


      case filters.COM_DIST_SALES_REP_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryObj['SALES_HQ_LEV1_NAME'] = lstOptionSelected.SALES_HQ_LEV1_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryObj['SALES_HQ_LEV2_NAME'] = lstOptionSelected.SALES_HQ_LEV2_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV3_NAME)) {
          queryObj['SALES_HQ_LEV3_NAME'] = lstOptionSelected.SALES_HQ_LEV3_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_CENTER_NAME)) {
          queryObj['SALES_CENTER_NAME'] = lstOptionSelected.SALES_CENTER_NAME;
        }

        if (!isAllSelected(lstOptionSelected.SALES_ROUTE_NAME)) {
          queryObj['SALES_ROUTE_NAME'] = lstOptionSelected.SALES_ROUTE_NAME;
        }
        break;

      case filters.DEALER_NAME:
        break;

      case filters.KAM_DEPT_LV2_NAME:
        break;

      case filters.KAM_DEPT_LV3_NAME:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryObj['KAM_DEPT_LV2_NAME'] = lstOptionSelected.KAM_DEPT_LV2_NAME;
        }
        break;

      case filters.KAM_DEPT_LV4_NAME:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryObj['KAM_DEPT_LV2_NAME'] = lstOptionSelected.KAM_DEPT_LV2_NAME;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV3_NAME)) {
          queryObj['KAM_DEPT_LV3_NAME'] = lstOptionSelected.KAM_DEPT_LV3_NAME;
        }
        break;

      case filters.KAM_CONTACT_PERSON_NAME:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryObj['KAM_DEPT_LV2_NAME'] = lstOptionSelected.KAM_DEPT_LV2_NAME;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV3_NAME)) {
          queryObj['KAM_DEPT_LV3_NAME'] = lstOptionSelected.KAM_DEPT_LV3_NAME;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV4_NAME)) {
          queryObj['KAM_DEPT_LV4_NAME'] = lstOptionSelected.KAM_DEPT_LV4_NAME;
        }
        break;

      case filters.ACCOUNT_LEV2_NAME_EN:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryObj['KAM_DEPT_LV2_NAME'] = lstOptionSelected.KAM_DEPT_LV2_NAME;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV3_NAME)) {
          queryObj['KAM_DEPT_LV3_NAME'] = lstOptionSelected.KAM_DEPT_LV3_NAME;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV4_NAME)) {
          queryObj['KAM_DEPT_LV4_NAME'] = lstOptionSelected.KAM_DEPT_LV4_NAME;
        }

        if (!isAllSelected(lstOptionSelected.KAM_CONTACT_PERSON_NAME)) {
          queryObj['KAM_CONTACT_PERSON_NAME'] = lstOptionSelected.KAM_CONTACT_PERSON_NAME;
        }
        break;

      case filters.ACCOUNT_LEV3_NAME_EN:
        break;

      case filters.CATEGORY_NAME_EN:
        break;

      case filters.SUBCATEGORY_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryObj['CATEGORY_NAME_EN'] = lstOptionSelected.CATEGORY_NAME_EN;
        }
        break;

      case filters.BRAND_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryObj['CATEGORY_NAME_EN'] = lstOptionSelected.CATEGORY_NAME_EN;
        }

        if (!isAllSelected(lstOptionSelected.SUBCATEGORY_NAME_EN)) {
          queryObj['SUBCATEGORY_NAME_EN'] = lstOptionSelected.SUBCATEGORY_NAME_EN;
        }
        break;

      case filters.PACKAGE_LEV1_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryObj['CATEGORY_NAME_EN'] = lstOptionSelected.CATEGORY_NAME_EN;
          
        }

        if (!isAllSelected(lstOptionSelected.SUBCATEGORY_NAME_EN)) {
          queryObj['SUBCATEGORY_NAME_EN'] = lstOptionSelected.SUBCATEGORY_NAME_EN;
        }

        if (!isAllSelected(lstOptionSelected.BRAND_NAME_EN)) {
          queryObj['BRAND_NAME_EN'] = lstOptionSelected.BRAND_NAME_EN;
        }
        break;

      case filters.PACKAGE_LEV2_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryObj['CATEGORY_NAME_EN'] = lstOptionSelected.CATEGORY_NAME_EN;
        }

        if (!isAllSelected(lstOptionSelected.SUBCATEGORY_NAME_EN)) {
          queryObj['SUBCATEGORY_NAME_EN'] = lstOptionSelected.SUBCATEGORY_NAME_EN;
        }

        if (!isAllSelected(lstOptionSelected.BRAND_NAME_EN)) {
          queryObj['BRAND_NAME_EN'] = lstOptionSelected.BRAND_NAME_EN;
        }

        if (!isAllSelected(lstOptionSelected.PACKAGE_LEV1_NAME_EN)) {
          queryObj['PACKAGE_LEV1_NAME_EN'] = lstOptionSelected.PACKAGE_LEV1_NAME_EN;
        }
        break;

      case filters.PACKAGE_DTL_NAME:
        break;

      default:
        break;
    }

    return queryObj;
  }

  // Only for District
  static getQueryObj2(filters: any, keyName: string, lstOptionSelected: any) {
    let queryObj = {};
    let parents = [];

    switch (keyName) {
      case filters.SALES_HQ_LEV1_NAME:
        parents = [];
        break;

      case filters.SALES_HQ_LEV2_NAME:
        parents = ['SALES_HQ_LEV1_NAME'];
        break;

      case filters.SALES_HQ_LEV3_NAME:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME'];
        break;

      case filters.SALES_CENTER_NAME:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME'];
        break;

      case filters.SALES_CONTROL_BASE_NAME:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME'];
        break;

      case filters.SALES_ROUTE_NAME:
      case filters.SALES_CONTROL_BASE_ROUTE_CODE:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME'];
        break;

      case filters.CHANNEL_LEV1_NAME:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE'];
        break;

      case filters.CHANNEL_LEV2_NAME:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME'];
        break;

      case filters.CHANNEL_LEV3_NAME:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];
        break;

      case filters.CHANNEL_NL:
        parents = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'];
        break;

      default:
        break;
    }

    parents.map(parentKey => {
      if (!isAllSelected(lstOptionSelected[parentKey])) {
        queryObj[parentKey] = lstOptionSelected[parentKey];
      }
    });

    return queryObj;
  }

  static getFilterConfigs = (config: any, visibleLimit: number): { [key: string]: string } => {
    const result: { [key: string]: string } = {};
    let count = 0;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (count < visibleLimit) {
          result[key] = 'enable';
        } else {
          result[key] = 'disable';
        }

        count++;
      }
    }

    return result;
  }

  static resetFilterConfigs1 = (config: { [key: string]: string }, visibleLimit: number) => {
    let count = 0;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (count < visibleLimit) {
          config[key] = 'enableTemp';
        } else {
          config[key] = 'tentative';
        }

        count++;
      }
    }
  }

  static resetFilterConfigs2 = (config: { [key: string]: string }, visibleLimit: number) => {
    let count = 0;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (count < visibleLimit) {
          config[key] = 'enable';
        } else {
          config[key] = 'disable';
        }

        count++;
      }
    }
  }

  static sortObjectByVisibleItems = (obj, visibleItems) => {
    const entries = Object.entries(obj);

    entries.sort(([keyA], [keyB]) => {
      const indexA = visibleItems.indexOf(keyA);
      const indexB = visibleItems.indexOf(keyB);

      if (indexA === -1 && indexB === -1) {
        return 0; // Both keys are not in visibleItems
      } else if (indexA === -1) {
        return 1; // keyA is not in visibleItems, so it comes after keyB
      } else if (indexB === -1) {
        return -1; // keyB is not in visibleItems, so it comes after keyA
      } else {
        return indexA - indexB; // Both keys are in visibleItems, sort by their indices
      }
    });

    const sortedObject = {};
    for (const [key, value] of entries) {
      sortedObject[key] = value;
    }

    return sortedObject;
  };

  static hasMultipleEnableTemp(obj: { [key: string]: any }): boolean {
    let count = 0;

    for (const key in obj) {
      if (obj[key] === 'enableTemp') {
        count++;
        if (count > 1) {
          return true;
        }
      }
    }

    return false;
  }

  static convertAllDisabledToTentative = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'disable') {
        config[key] = 'tentative';
      }
    }
  }

  static convertAllTentativeToDisable = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'tentative') {
        config[key] = 'disable';
      }
    }
  }

  static convertAllEnableTempToEnable = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'enableTemp') {
        config[key] = 'enable';
      }
    }
  }

  static convertAllEnableToEnableTemp = (config: { [key: string]: string }) => {
    let count = 0;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (config[key] === 'enable') {
          config[key] = 'enableTemp';
        }
      }
    }
  }

  static convertTentativeToEnableTemp = (config: { [key: string]: string }, specifiedKey: string) => {
    if (config.hasOwnProperty(specifiedKey) && config[specifiedKey] === 'tentative') {
      config[specifiedKey] = 'enableTemp';
    }
  }

  static convertEnableTempToTentative = (config: { [key: string]: string }, specifiedKey: string) => {
    if (config.hasOwnProperty(specifiedKey) && config[specifiedKey] === 'enableTemp') {
      config[specifiedKey] = 'tentative';
    }
  }

  static isShowBtnResetFilter = ( lstOption_States: { [key: string]: string },config: { [key: string]: string },defaultVisibleFilters : number) => {
    const defaultProperties = Object.keys(config).slice(0, defaultVisibleFilters);
    for (const key in lstOption_States) {
      if (lstOption_States.hasOwnProperty(key) && (lstOption_States[key] === 'enableTemp' || lstOption_States[key] === 'enable')) {
        if(!defaultProperties.includes(key)) {
          return true;
        }
      }

      if (lstOption_States.hasOwnProperty(key) && (lstOption_States[key] === 'tentative' || lstOption_States[key] === 'disable')) {
        if(defaultProperties.includes(key)) {
          return true;
        }
      }
    }
    return false;
  }

  static isShowBtnApply = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && (config[key] === 'enableTemp' || config[key] === 'enable')) {
        return true;
      }
    }

    return false;
  }

  static convertGroupEnableTempToTentative(config: { [key: string]: string }, visibleLimit:number, keyName: string) {
    let count        = 0;
    let filtersGroup = getFiltersChild(keyName);
    let { data: childFilters } = filtersGroup;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (count < visibleLimit) {
          config[key] = 'enable';
        } else {
          if (keyName === key || childFilters.includes(key)) {
            config[key] = 'tentative';
          }
        }

        count++;
      }
    }
  }

  static getFiltersChildGroup(keyName: string) {
    return getFiltersChild(keyName);
  }

  static getFiltersChildGroup2(keyName: string) {
    return getFiltersChild2(keyName);
  }

  static getDefaultSelectedFilter(config: any) {
    const obj = {};

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        obj[key] = ['All'];
      }
    }

    return obj;
  }

  static getDefaultFilterList(config: any) {
    const obj = {};

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        obj[key] = [];
      }
    }

    return obj;
  }

  static getDefaultVisibleFilterList(config: any) {
    const arr = [];

    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'enable') {
        arr.push(key);
      }
    }

    return arr;
  }

  static getListIsUsedFilter(config: any, arr: any) {
    const obj = {};

    for (const key in config) {
      obj[key] = arr.includes(key) ? true : false;
    }

    return obj;
  }

  static init_lstOption_States(filterModuleName:string,config: any, visibleLimit: number, userGroupName: string){
    let option_data = localStorageHelper.get_Option_Filter_States(filterModuleName);
    let name = '';

    if (option_data) {
      name = option_data?.userGroupName;
    }

    if (option_data && name === userGroupName) {
      return option_data;
    } else {
      return {
        lstOption_States : this.getFilterConfigs(config, visibleLimit) || {},
        lstOption_Selected : this.getDefaultSelectedFilter(config) || {},
        lstOptions_IndexDB : null
      }
    }
  }

  static get_DateTypeAndSaleDate_States(filterModuleName:string){
    let option_data = localStorageHelper.get_Option_Filter_States(filterModuleName);
    if(option_data){
      return option_data;
    }
    return null;
  }

  static store_lstOption_States(filterModuleName:string, 
    lstOption_States: any = null ,
    lstOption_Selected: any = null ,
    lstOptions: any = null,
    lstAllOptionsFilteredCalendarData : any = null,
    lstAllOptionsFilteredAreaData : any = null,
    lstAllOptionsFilteredAccountData : any = null,
    lstAllOptionsFilteredProductData : any = null,
    lstOption_Selected_DateType: any = null ,
    lstOption_Selected_SalesDate : any = null,
    dailySalesDateRange: any = null,
    userGroupName: string)
    
    {
    localStorageHelper.set_Option_Filter_States(filterModuleName, 
        lstOption_States,
        lstOption_Selected,
        lstOptions ,
        lstAllOptionsFilteredCalendarData ,
        lstAllOptionsFilteredAreaData,
        lstAllOptionsFilteredAccountData,
        lstAllOptionsFilteredProductData,
        lstOption_Selected_DateType,
        lstOption_Selected_SalesDate,
        dailySalesDateRange,
        userGroupName);
  }
}

const isAllSelected = (arr1: any) => {
  return JSON.stringify(arr1) === JSON.stringify(['All']);
}

const getFiltersChild = (keyName: string) => {
  const filtersGroup: any = {};

  switch (keyName) {
    case 'CHANNEL_LEV1_NAME':
      filtersGroup.data = ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.calendarFilter || '';
      break;

    case 'CHANNEL_LEV2_NAME':
      filtersGroup.data = ['CHANNEL_LEV3_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.calendarFilter || '';
      break;

    case 'CHANNEL_LEV3_NAME':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.calendarFilter || '';
      break;

    case 'SALES_HQ_LEV1_NAME':
      filtersGroup.data = ['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_HQ_LEV2_NAME':
      filtersGroup.data = ['SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_HQ_LEV3_NAME':
      filtersGroup.data = ['SALES_CENTER_NAME', 'SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_CENTER_NAME':
      filtersGroup.data = ['SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_ROUTE_NAME':
      filtersGroup.data = ['COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';
      break;

    case 'COM_DIST_SALES_REP_NAME':
      filtersGroup.data = ['DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';
      break;

    case 'DEALER_NAME':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';
      break;

    case 'KAM_DEPT_LV2_NAME':
      filtersGroup.data = ['KAM_DEPT_LV3_NAME', 'KAM_DEPT_LV4_NAME', 'KAM_CONTACT_PERSON_NAME', 'ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.accountFilter || '';
      break;

    case 'KAM_DEPT_LV3_NAME':
      filtersGroup.data = ['KAM_DEPT_LV4_NAME', 'KAM_CONTACT_PERSON_NAME', 'ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.accountFilter || '';
      break;

    case 'KAM_DEPT_LV4_NAME':
      filtersGroup.data = ['KAM_CONTACT_PERSON_NAME', 'ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.accountFilter || '';
      break;

    case 'KAM_CONTACT_PERSON_NAME':
      filtersGroup.data = ['ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.accountFilter || '';
      break;

    case 'ACCOUNT_LEV2_NAME_EN':
      filtersGroup.data = ['ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.accountFilter || '';
      break;

    case 'ACCOUNT_LEV3_NAME_EN':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.accountFilter || '';
      break;

    case 'CATEGORY_NAME_EN':
      filtersGroup.data = ['SUBCATEGORY_NAME_EN', 'BRAND_NAME_EN', 'PACKAGE_LEV1_NAME_EN', 'PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';
      break;

    case 'SUBCATEGORY_NAME_EN':
      filtersGroup.data = ['BRAND_NAME_EN', 'PACKAGE_LEV1_NAME_EN', 'PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';
      break;

    case 'BRAND_NAME_EN':
      filtersGroup.data = ['PACKAGE_LEV1_NAME_EN', 'PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';
      break;

    case 'PACKAGE_LEV1_NAME_EN':
      filtersGroup.data = ['PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';
      break;

    case 'PACKAGE_LEV2_NAME_EN':
      filtersGroup.data = ['PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';
      break;

    case 'PACKAGE_DTL_NAME':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';
      break;

    default:
      filtersGroup.data = [];
      filtersGroup.name = '';
      break;
  }

  return filtersGroup;
}

const getFiltersChild2 = (keyName: string) => {
  const filtersGroup: any = {};

  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      filtersGroup.data = ['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'SALES_HQ_LEV2_NAME':
      filtersGroup.data = ['SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'SALES_HQ_LEV3_NAME':
      filtersGroup.data = ['SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'SALES_CENTER_NAME':
      filtersGroup.data = ['SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'SALES_CONTROL_BASE_NAME':
      filtersGroup.data = ['SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'SALES_CONTROL_BASE_ROUTE_CODE':
      filtersGroup.data = ['CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'CHANNEL_LEV1_NAME':
      filtersGroup.data = ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'CHANNEL_LEV2_NAME':
      filtersGroup.data = ['CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
      break;

    case 'CHANNEL_LEV3_NAME':
      filtersGroup.data = ['CHANNEL_NL'];
      break;

    case 'CHANNEL_NL':
      filtersGroup.data = [];
      break;

    default:
      break;
  }

  return filtersGroup;
}


