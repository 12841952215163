import { BehaviorSubject } from 'rxjs';

export class SalesDateHelper {
  private static lstFaceshareYMData = new BehaviorSubject<any>(null);
  private static lstPocYMData = new BehaviorSubject<any>(null);

  static setFaceshareSalesDate(value: any) {
    return this.lstFaceshareYMData.next(value);
  }

  static setPocSalesDate(value: any) {
    return this.lstPocYMData.next(value);
  }

  static getSalesDate(pageName: string, dateType: any, dateInJob: string) {
    let data = null;

    switch (dateType) {
      case 'Daily':
        data = _getDailyRange(dateInJob);
        break;

      case 'MTD':
        data = _getCurrentMonthAndYesterdayRange(dateInJob);
        break;

      case 'QTD':
        if (pageName === 'HQProgress') {
          data = _getCurrentQuarterAndYesterdayRange(dateInJob);
        } else {
          data = _getCurrentQuarterRange(dateInJob);
        }
        break;

      case 'YTD':
        data = _getCurrentYearAndYesterdayRange(dateInJob);
        break;

      case 'Daily TYTM':
        break;

      case 'Daily TYLM':
        break;

      case 'Daily LYNM':
        break;

      case 'This Month':
        if (pageName === 'DistrictProgress') {
          data = _getCurrentMonth(dateInJob);
        } else {
          data = _getCurrentMonthAndYesterdayRange(dateInJob);
        }
        break;

      case 'Specific Date':
        data = _getCurrentYearRange(dateInJob)
        break;

      case 'Specific Date (This Month)':
        data = _getCurrentMonthAndYesterdayRange(dateInJob);
        break;

      case 'Specific Date (Last Month)':
        data = _getLastMonthRange(dateInJob);
        break;

      case 'Specific Date (Next Month in Last Year)':
        data = _getLastYearNextMonthRange(dateInJob);
        break;

      case 'Specific Month':
        data = _getPrevious13thMonthAndYesterdayRange(dateInJob);
        break;

      case 'Monthly':
        data = _getCurrentMonthRange(dateInJob);
        break;

      default:
        break;
    }

    return data;
  }

  static isShowCalendar(pageName: string, selectedDateType: string) {
    if (['Specific Month', 'This Month'].includes(selectedDateType) || (['SkuDeepdive'].includes(pageName) && ['MTD', 'QTD', 'YTD'].includes(selectedDateType)) || (['FaceShare', 'PoC']).includes(pageName)) {
      return false;
    }

    return true;
  }

  static isFromToDropdown(pageName: string, selectedDateType: string) {
    if (['Specific Month', 'This Month'].includes(selectedDateType) || (['SkuDeepdive'].includes(pageName) && ['MTD', 'QTD', 'YTD'].includes(selectedDateType))) {
      return true;
    }

    return false;
  }

  static isPhotoReport(pageName: string, selectedDateType: string) {
    if ((['FaceShare', 'PoC'].includes(pageName))) {
      return true;
    }

    return false;
  }

  static isOnlyView(pageName: string, selectedDateType: string) {
    if (selectedDateType.includes('Specific')) {
      return false;
    }

    return true;
  }

  static getLatest13Months() {
    return _getLatest13Months();
  }



  static getLatest13MonthsTBD(dateInJob: string) {
    return _getLatest13MonthsTBD(dateInJob);
  }


  static getLatest12MonthsTBD(dateInJob: string) {
    return _getLatest12MonthsTBD(dateInJob);
  }


    static getFaceshareMonths() {
    return this.lstFaceshareYMData.value;
  }
  

 static getPhotoReportMonths(pageName: string) {
    let data = null;

    if (pageName.toLowerCase() === 'faceshare') {
      data = this.lstFaceshareYMData.value;
    }

    if (pageName.toLowerCase() === 'poc') {
      data = this.lstPocYMData.value;
    }

    return data;
  }

  static getMonthsInRangeByMonth(start: string, end: string): string[] {
    const startDate = new Date(start + '-01');
    const endDate = new Date(end + '-01');

    const result: string[] = [];
    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      result.push(`${year}-${month}`);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return result;
  }

  static getMonthsInRangeByDate(start: string, end: string): string[] {
    const startDate = new Date(start);
    const endDate = new Date(end);

    return [`${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}`, `${endDate.getFullYear()}-${(endDate.getMonth() + 1).toString().padStart(2, '0')}`];
  }

  static getCurrentMonth(dateInJob: string) {
    const currentDate = new Date(dateInJob);

    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');

    return `${currentYear}-${currentMonth}`;
  }

  static getMonthsUntilYesterday(dateInJob: string) {
    const today        = new Date(dateInJob);
    const currentYear  = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // getMonth() returns 0-based month
    const months       = [];

    // Loop from January to the previous month if today is the first day of the month
    const endMonth = currentMonth;

    for (let month = 1; month <= endMonth; month++) {
      const monthString = month < 10 ? `0${month}` : `${month}`;
      months.push({value: `${currentYear}-${monthString}`, name: `${currentYear}-${monthString}`});
    }

    return months;
  }

  static getFirstMonthOfCurrentQuarter() {
    const today = new Date();
    const month = today.getMonth(); // Get the month (0-based, so January is 0, February is 1, etc.)

    // Determine the first month of the current quarter
    let firstMonthOfQuarter;
    if (month >= 0 && month <= 2) {
      firstMonthOfQuarter = 0; // January
    } else if (month >= 3 && month <= 5) {
      firstMonthOfQuarter = 3; // April
    } else if (month >= 6 && month <= 8) {
      firstMonthOfQuarter = 6; // July
    } else if (month >= 9 && month <= 11) {
      firstMonthOfQuarter = 9; // October
    }

    // Return the first month of the current quarter in 'yyyy-mm' format
    const year = today.getFullYear();
    const monthString = (firstMonthOfQuarter + 1).toString().padStart(2, '0');
    return [{value: `${year}-${monthString}`, name: `${year}-${monthString}`}];
  }

  static getCurrentQuarterMonthsUntilYesterday(dateInJob: string) {
    const today = new Date(dateInJob);
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // getMonth() returns 0-based month
    const currentDate = today.getDate();

    // Determine the start month of the current quarter
    let startMonth;
    if (currentMonth <= 3) {
      startMonth = 1;
    } else if (currentMonth <= 6) {
      startMonth = 4;
    } else if (currentMonth <= 9) {
      startMonth = 7;
    } else {
      startMonth = 10;
    }

    // If today is the first day of the month, exclude the current month
    let endMonth;
    if (currentDate === 1) {
      endMonth = currentMonth - 1;
      if (endMonth < startMonth) {
        endMonth = startMonth; // Ensure endMonth is at least startMonth
      }
    } else {
      endMonth = currentMonth;
    }

    // Generate an array of months in the current quarter until yesterday
    const months = [];
    for (let month = startMonth; month <= endMonth; month++) {
      const monthString = month < 10 ? `0${month}` : `${month}`;
      months.push({value: `${currentYear}-${monthString}`, name: `${currentYear}-${monthString}`});
    }

    return months;
  }
}

const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const _getDailyRange = (dateInJob: string) => {
  const date = new Date(dateInJob);

  return [formatDate(date), formatDate(date)];
}

const _getCurrentMonthAndYesterdayRange = (dateInJob: string) => {
  const toDate = new Date(dateInJob);
  const fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);

  return [formatDate(fromDate), formatDate(toDate)];
}

const _getCurrentMonthRange = (dateInJob: string) => {
  const currentDate = new Date(dateInJob);
  const fromDate    = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const toDate      = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  return [formatDate(fromDate), formatDate(toDate)];
}

const _getLastMonthRange = (dateInJob) => {
  const currentDate = new Date(dateInJob);
  const fromDate    = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const lastMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
  const toDate      = new Date(lastMonthEndDate.getFullYear(), lastMonthEndDate.getMonth(), lastMonthEndDate.getDate());

  return [formatDate(fromDate), formatDate(toDate)];
}

const _getCurrentQuarterAndYesterdayRange = (dateInJob: string) => {
  const currentDate = new Date(dateInJob);
  const currentQuarter = Math.floor((currentDate.getMonth() + 3) / 3);

  const quarterStartMonth = (currentQuarter - 1) * 3;
  const fromDate = new Date(currentDate.getFullYear(), quarterStartMonth, 1);

  return [formatDate(fromDate), formatDate(currentDate)];
}

const _getCurrentQuarterRange = (dateInJob) => {
  const currentDate       = new Date(dateInJob);
  const currentQuarter    = Math.floor((currentDate.getMonth() + 3) / 3);
  const quarterStartMonth = (currentQuarter - 1) * 3;
  const fromDate          = new Date(currentDate.getFullYear(), quarterStartMonth, 1);

  const firstMonthEndDate = new Date(currentDate.getFullYear(), quarterStartMonth + 1, 0);
  const toDate            = new Date(firstMonthEndDate.getFullYear(), firstMonthEndDate.getMonth(), firstMonthEndDate.getDate());

  return [formatDate(fromDate), formatDate(toDate)];
}

const _getCurrentYearAndYesterdayRange = (dateInJob: string) => {
  const currentDate = new Date(dateInJob);
  const fromDate = new Date(currentDate.getFullYear(), 0, 1);

  return [formatDate(fromDate), formatDate(currentDate)];
}

const _getCurrentYearRange = (dateInJob: string) => {
  const currentDate = new Date(dateInJob);
  const fromDate    = new Date(currentDate.getFullYear(), 0, 1);

  return [formatDate(fromDate), formatDate(currentDate)];
}

const _getPrevious13thMonthAndYesterdayRange = (dateInJob: string) => {
  const currentDate = new Date(dateInJob);
  const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 12, 1);
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  return [formatDate(fromDate), formatDate(yesterday)];
}


const _getLastYearNextMonthRange = (dateInJob: string) => {
  const currentDate = new Date(dateInJob);

  const lastYear = currentDate.getFullYear() - 1;
  const nextMonth = currentDate.getMonth() === 11 ? 0 : currentDate.getMonth() + 1;
  const nextMonthYear = currentDate.getMonth() === 11 ? lastYear + 1 : lastYear;

  const firstDayOfNextMonth = new Date(nextMonthYear, nextMonth, 1);
  const lastDayOfNextMonth = new Date(nextMonthYear, nextMonth + 1, 0);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return [formatDate(firstDayOfNextMonth), formatDate(lastDayOfNextMonth)];
}

const _getLatest13Months = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const currentYear = yesterday.getFullYear();
  const currentMonth = yesterday.getMonth() + 1;
  const latestMonths = [];

  for (let i = 0; i < 13; i++) {
    let month = currentMonth - i;
    let year = currentYear;

    if (month <= 0) {
        month += 12;
        year--;
    }

    const formattedMonth = month.toString().padStart(2, '0');
    latestMonths.push({ text: `${year}-${formattedMonth}`, value: `${year}-${formattedMonth}`});
  }

  return latestMonths.reverse();
}

const _getLatest13MonthsTBD = (dateInJob: string) => {
  const monthNames  = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentDate = new Date(dateInJob);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const latestMonths = [];

  for (let i = 0; i < 13; i++) {
    let month = currentMonth - i;
    let year = currentYear;

    if (month <= 0) {
        month += 12;
        year--;
    }

    const formattedMonth = month.toString().padStart(2, '0');
    const formattedMonthName = monthNames[month - 1];
    // latestMonths.push({ name: `${year} ${formattedMonthName}`, value: `${year}-${formattedMonth}`});
    latestMonths.push({ name: `${year}-${formattedMonth}`, value: `${year}-${formattedMonth}`});
  }

  return latestMonths;
}

const _getLatest12MonthsTBD = (dateInJob) => {
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentDate = new Date(dateInJob);
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // Note: getMonth() returns 0-based month index (0 for January)
  const latestMonths = [];

  for (let i = 1; i <= 12; i++) { // Start from 1 to exclude the current month
    let month = currentMonth - i;
    let year = currentYear;

    if (month < 0) {
      month += 12;
      year--;
    }

    const formattedMonth = (month + 1).toString().padStart(2, '0'); // +1 to adjust for 0-based index
    const formattedMonthName = monthNames[month];
    latestMonths.push({ name: `${year}-${formattedMonth}`, value: `${year}-${formattedMonth}` });
  }

  return latestMonths;
}

const _getCurrentMonth = (dateInJob: string) => {
  const currentDate = new Date(dateInJob);

  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  return [`${currentYear}-${currentMonth}`];
}
