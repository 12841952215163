import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, interval, Subject } from 'rxjs';
import { switchMap, catchError, takeWhile, tap, startWith, take } from 'rxjs/operators';
import { openWarningPopup, closeWarningPopup } from 'src/app/helpers/utils';
import { apiUrl, AnalyticsQueries } from 'src/app/model/constants';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  private apiUrl = apiUrl;
  private csvResponseSubject = new Subject<any>();
  private userGroupName = '';
  sePaths   = ['/hq-progress', '/district-route-progress', '/sku-deepdive', '/area-deepdive', '/progress-ranking'];
  traxPaths = ['/faceshare', '/poc'];

  constructor(private http: HttpClient) {}

  checkCondition(response: any): boolean {
    return response && (response.Status === 0 || response.Status === '0');
  }

  isCurrentPathIncluded(paths: string[]): boolean {
    return paths.some(path => location.href.includes(path));
  }

  getUserGroupname() {
    const storedData = localStorage.getItem('userinfo');

    if (storedData) {
      const userInfo = JSON.parse(storedData);
      this.userGroupName = userInfo?.customers?.length > 0 ? userInfo.customers[0]?.filterOptionsGroup?.name : '';
    }

    return;
  }

  getCsvUrl(code: string, analyticsQuery: object, selectedGroupByLevel: string, isOpenWarningPopup: boolean = true): Observable<any> {
    if (isOpenWarningPopup) {
      openWarningPopup();
    }

    // get LatestDate
    let dateInJobClearCache = '';

    if (this.isCurrentPathIncluded(this.sePaths)) {
      dateInJobClearCache = localStorage.getItem('se.dateinjobclearcache')?.split(' ')[0] || '';
    }

    if (this.isCurrentPathIncluded(this.traxPaths)) {
      const lastUpdatedStr = localStorage.getItem('lastUpdated');
      let traxLastUpdated: any;

      try {
        traxLastUpdated = lastUpdatedStr ? JSON.parse(lastUpdatedStr) : null;
      } catch (e) {
        console.error('Failed to parse lastUpdated from localStorage:', e);
        traxLastUpdated = null;
      }

      if (traxLastUpdated && traxLastUpdated.name === AnalyticsQueries?.lastUpdatedTraxReport && typeof traxLastUpdated.date === 'string') {
        dateInJobClearCache = traxLastUpdated.date.split(' ')[0] || '';
      } else {
        dateInJobClearCache = '';
      }
    }

    // get catcheKey
    let catcheKey = `?code=${code}&IsExport=true`;

    if (analyticsQuery['CacheKey']?.length > 0) {
      catcheKey += `&${analyticsQuery['CacheKey']}`;
    }

    if (!this.userGroupName?.length) {
      this.getUserGroupname();
    }

    const bodyObj = {
      ...analyticsQuery,
      Code: code,
      CacheKey: catcheKey,
      IsExport: true
    };

    if (this.isCurrentPathIncluded(this.sePaths)) {
      bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&filterOptionsGroup=${encodeURIComponent(this.userGroupName)}`;
    }


    if (selectedGroupByLevel && selectedGroupByLevel.length > 0) {
       bodyObj['GroupByLevel'] = selectedGroupByLevel;
       bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&GroupByLevel=${selectedGroupByLevel}&LatestUpdate=${dateInJobClearCache}`;
    } else {
       bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&LatestUpdate=${dateInJobClearCache}`;
    }

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    interval(5000).pipe(
      startWith(0),
      switchMap(() => this.http.post(this.apiUrl, bodyObj, httpOptions).pipe(
        catchError(() => {
          if (isOpenWarningPopup) {
            closeWarningPopup();
          }

          return of({ status: 'error' });
        })
      )),
      takeWhile((response: any) => {
        const data = JSON.parse(response);
        return this.checkCondition(data);
      }, true),
      tap((response: any) => {
        if (response) {
          const data = JSON.parse(response);
          const { Csv_Url } = data;

          if (Csv_Url && Csv_Url.length > 0) {
            if (isOpenWarningPopup) {
              closeWarningPopup();
            }

            this.csvResponseSubject.next(data);
          }
        }
      }),
    ).subscribe();

    return this.csvResponseSubject.asObservable();
  }
}
