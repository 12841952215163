<div>
  <div class="filter-section even-wrapper">
    <ng-container *ngIf="lstOption_IsUsed?.CHANNEL">
      <ng-container *ngIf="['faceshare-by-account', 'poc-by-category', 'poc-by-account'].includes(filterModuleName)">
        <div class="ng-section summary-section select-single-dropdown">
          <b>Channel</b>
          <ng-select #ngFilterDateType class="form-control filter-by-category"
            [searchable]="false"
            [virtualScroll]="true"
            [(ngModel)]="lstOption_Selected?.CHANNEL[0]"
            [multiple]="false"
            [closeOnSelect]="false"
            (blur)="onSingleDropdownBlur(true, 'CHANNEL')"
            [clearable]="false">
                <ng-option *ngFor="let item of lstOptions?.CHANNEL" [value]="item.value">
                    <input type="checkbox" class="cb-multi-select"/>
                    <ng-container *ngIf="item?.child">&nbsp;&nbsp;&nbsp;</ng-container>
                    {{item.value}}
                </ng-option>
                <ng-template ng-footer-tmp>
                    <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected?.CHANNEL.length == 0"
                    (click)="onSingleDropdownBlur(false, 'CHANNEL');ngFilterDateType.close()">Aggregate</button>
                </ng-template>
                <ng-template ng-multi-label-tmp let-items="items">
                    <ng-container *ngFor="let item of items; index as i">
                        <ng-container *ngIf="i==0">
                            <div class="text-over-dropdown">{{item}}</div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="items.length > 1">
                        ...
                    </ng-container>
                </ng-template>
            </ng-select>
          </div>
      </ng-container>

      <ng-container *ngIf="!['faceshare-by-account', 'poc-by-category', 'poc-by-account'].includes(filterModuleName)">
        <multi-dropdown
          class="multi-dropdown"
          dataTitle="Channel"
          dataDescription="Channel"
          [showTooltip]="false"
          [keyName]="filters?.CHANNEL"
          [initialData]="lstOptions?.CHANNEL"
          [initialSelected]="lstOption_Selected?.CHANNEL"
          [isHierarchical]="true"
          (saveItemsChange)="onSaveItemsChange($event)"></multi-dropdown>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="lstOption_IsUsed?.SALES_DISTRICT_GROUP">
      <div class="ng-section summary-section select-single-dropdown">
        <b>Area</b>
        <ng-select #ngFilterDateType class="form-control filter-by-category"
          [searchable]="false"
          [virtualScroll]="true"
          [(ngModel)]="lstOption_Selected?.SALES_DISTRICT_GROUP[0]"
          [multiple]="false"
          [closeOnSelect]="false"
          (blur)="onSingleDropdownBlur(true, 'SALES_DISTRICT_GROUP')"
          [clearable]="false">
              <ng-option *ngFor="let item of lstOptions?.SALES_DISTRICT_GROUP" [value]="item.value">
                  <input type="checkbox" class="cb-multi-select"/>{{item.value}}
              </ng-option>
              <ng-template ng-footer-tmp>
                  <button type="submit" class="btn btn-primary" [disabled]="lstOption_Selected?.SALES_DISTRICT_GROUP.length == 0"
                  (click)="onSingleDropdownBlur(false, 'SALES_DISTRICT_GROUP');ngFilterDateType.close()">Aggregate</button>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items; index as i">
                      <ng-container *ngIf="i==0">
                          <div class="text-over-dropdown">{{item}}</div>
                      </ng-container>
                  </ng-container>
                  <ng-container *ngIf="items.length > 1">
                      ...
                  </ng-container>
              </ng-template>
          </ng-select>
        </div>
    </ng-container>

    <multi-dropdown *ngIf="lstOption_IsUsed?.SALES_LOCATION_TYPE_NAME_EN"
      class="multi-dropdown"
      dataTitle="Teiban Type"
      dataDescription="Teiban Type"
      [showTooltip]="false"
      [keyName]="filters?.SALES_LOCATION_TYPE_NAME_EN"
      [initialData]="lstOptions?.SALES_LOCATION_TYPE_NAME_EN"
      [initialSelected]="lstOption_Selected?.SALES_LOCATION_TYPE_NAME_EN"
      [isHierarchical]="true"
      (saveItemsChange)="onSaveItemsChange($event)"></multi-dropdown>
  </div>
</div>
