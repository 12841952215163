import Swal from 'sweetalert2';

export const isObjEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const objectToQueryString = (obj: Object) => {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export const openWarningPopup = () => {
  try {
    Swal.fire({
      title: '',
      text: 'We are preparing the report, please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {}
    }).then(result => {
      if (result.dismiss === Swal.DismissReason.backdrop || result.dismiss === Swal.DismissReason.esc) {
        console.warn('Popup was dismissed by user interaction');
      }
    });
  } catch (error) {
    console.error('Error opening SweetAlert2 popup:', error);
  }
}

export const closeWarningPopup = () => {
  try {
    setTimeout(() => {
      Swal.close();
    }, 1000);
  } catch (error) {
    console.log(error);
  }
};

export const getPageName = (name: string) => {
  let page = '';

  switch (name) {
    case 'VsLyBrandComponent':
    case 'VsLyCategoryComponent':
    case 'VsLyPackageComponent':
    case 'VsLySKUComponent':
    case 'VsLyTimelineComponent':
      page = 'sku-deepdive';
      break;

    case 'VsLyAreaComponent':
    case 'VsLyChannelComponent':
    case 'VsLyDealerComponent':
    case 'VsLyRouteComponent':
    case 'VsLyAreaTimelineComponent':
      page = 'area-deepdive';
      break;

    case 'ProgressByDistrictComponent':
    case 'ProgressByRouteComponent':
    case 'DistrictTimelineComponent':
      page = 'progress-district';
      break;

    default:
      break;
  }

  return page;
}

export const isAllSelected = (arr1: any) => {
  return JSON.stringify(arr1) === JSON.stringify(['All']);
}

export const faceshareIsAllSelected = (keyName: string, arr1: any) => {
  switch (keyName) {
    case 'SALES_LOCATION_TYPE_NAME_EN':
      return JSON.stringify(arr1) === JSON.stringify(['Teiban Total']);

    default:
      return JSON.stringify(arr1) === JSON.stringify(['All']);
  }
}

export const skuSetQueryString = (lstOptionSelected: object) => {
  const analyticsQuery = {
    Selected  : {},
    UnSelected: {},
  };

  let cacheKey = '';

  if (lstOptionSelected) {
    for (const key in lstOptionSelected) {
      if (lstOptionSelected.hasOwnProperty(key)) {
        if (!isAllSelected(lstOptionSelected[key])) {
          analyticsQuery['Selected'][key] = lstOptionSelected[key];
        }
      }
    }
  }

  if (!isObjEmpty(analyticsQuery?.Selected)) {
    cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
  }

  if (!isObjEmpty(analyticsQuery?.UnSelected)) {
    cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
  }
  analyticsQuery['CacheKey'] = cacheKey;
  return analyticsQuery;
}

export const faceshareSetQueryString = (lstOptionSelected: object) => {
  const analyticsQuery = {
    Selected  : {},
    UnSelected: {},
  };

  let cacheKey = '';

  if (lstOptionSelected) {
    for (const key in lstOptionSelected) {
      if (lstOptionSelected.hasOwnProperty(key)) {
        if (!faceshareIsAllSelected(key, lstOptionSelected[key])) {
          analyticsQuery['Selected'][key] = lstOptionSelected[key];
        }
      }
    }
  }

  if (!isObjEmpty(analyticsQuery?.Selected)) {
    cacheKey += `&${objectToQueryString(analyticsQuery['Selected'])}`;
  }

  if (!isObjEmpty(analyticsQuery?.UnSelected)) {
    cacheKey += `&${objectToQueryString(analyticsQuery['UnSelected'])}`;
  }
  analyticsQuery['CacheKey'] = cacheKey;
  return analyticsQuery;
}
