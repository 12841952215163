<!-- <div class="row">
    <div class="col-lg-2 col-md-6 col-xs-90">
        <input [(ngModel)]="selectedDate" (dateSelect)="onDateSelect($event)" type="text" ngbDatepicker
            [minDate]="{year: 2023, month: 1, day: 1}"
            [maxDate]="{year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate()}"
            #d="ngbDatepicker" class="form-control" readonly placeholder="Choose a date" />
    </div>
    <div class="col-lg-2 col-md-6 col-xs-10">
        <span class="icon" (click)="d.toggle()"><i class="bx bx-calendar"
                style="font-size: 35px;left: -20px;position: relative;"></i></span>
    </div>
</div> -->

<div class="filter-section no-title mega-wrapper">
    <div class="ng-section summary-section date-picker">
        <b>Date</b>
        <input [(ngModel)]="selectedDate" (dateSelect)="onDateSelect($event)" type="text" ngbDatepicker
        [minDate]="{year: 2024, month: 1, day: 1}"
        [maxDate]="{year: startDate.year, month: startDate.month, day: startDate.day}"
        #d="ngbDatepicker" class="form-control" readonly placeholder="Choose a date" (click)="d.toggle()" />
    </div>
</div>

<p></p>

<div [class]="ready ? 'not-show' : '' " class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
<div [class]="ready ? '' : 'not-show' " class="analytics-data-wrapper">
    <!-- <div id="analytics-data"> -->
    <!-- FPT-MyNT15: [2022/11/23][SENSE][1203406614097422] SENSE Daily Report page corrections -->
    <iframe [src]="dailyReportHistUrl" frameborder="0" (load)="reportLoaded()"></iframe>
    <!-- </div> -->
</div>