import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as htmlToImage from 'html-to-image';
import lodashClonedeep from 'lodash.clonedeep';
import { HttpClient } from '@angular/common/http';

import { SkuDeepdiveHelper } from 'src/app/components/pages/sku-deepdive/sku-deepdive-helper';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import { FILTER_CONFIGS, SkuDeepdiveQueries } from 'src/app/model/constants';
import { downloadAsPDF } from 'src/app/helpers/pdfExtend';
import { PDFProperty } from 'src/app/model/pdfProperty.model';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { Router } from '@angular/router';
import { PRIORITY_ORDER } from 'src/app/helpers/sort';
import { sortArrayByPriority } from 'src/app/helpers/sort';
import { CsvService } from 'src/app/services/csv.services';
import { UserService } from 'src/app/services/user.services';

@Component({
  selector: 'app-sku-deepdive-vs-ly-category',
  templateUrl: './vs-ly-category.component.html',
  styleUrls: ['./vs-ly-category.component.scss']
})

export class VsLyCategoryComponent implements OnInit, OnChanges {
  FILTERS: any                  = [];
  SKU_DEEPDIVE_FILTERS          = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.SKU_DEEPDIVE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT ?? 4;

  @Input() lstOption_Selected_DateType:any;
  @Input() lstOption_Selected_SalesDate: any;

  @Input() lstAllOptionsCalendarData: any;
  @Input() lstAllOptionsAreaData: any;
  @Input() lstAllOptionsAccountData: any;
  @Input() lstAllOptionsProductData: any;
  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;

  vsLYByCategoryData: any = null;
  listGroupCategoryName : any[] = null;
  listGroupCategoryDetail : any[] = null;

  vsLYByCategoryData_CSV: any = null;

  isLoading: boolean = false;
  isNoDataMode = true;
  listData_Filter: any = null;
  lstOption_States: any = {};
  lstOption_States_Applied: any = {};
  lstOption_Selected: any = {};
  lstOption_IsUsed: any = {};
  lstOption_Selected_Applied: any = {};

  lstOption_ChannelLev = [{value: 'CATEGORY_NAME_EN', name: 'Category'}, {value: 'SUBCATEGORY_NAME_EN', name: 'Subcategory'}];
  lstOption_Selected_ChannelLev = this.lstOption_ChannelLev[0].value;
  skuFilterQuery: object = {};

  public listChart : any[] = [];

  public maxBAPC_TY:number;
  public maxNSR_TY:number;
  public maxGP_TY:number;

  public BAPC_TYHalfColumn: any;
  public BAPC_TYFullColumn: any;
  public NSR_TYHalfColumn: any;
  public NSR_TYFullColumn: any;
  public GP_TYHalfColumn: any;
  public GP_TYFullColumn: any;

  sortType : string      = "desc";
  //sortColumn: string     = "NSR_TY";
  sortColumn: string     = "NSR_TY";
  chartBAPC_Category: any;
  chartNRS_Category: any;
  chartGP_Category: any;

  isHasFistLoad: boolean = false;

  constructor(private http: HttpClient, private router: Router, private csvService: CsvService, private userService: UserService) {}

  ngOnInit() {
    this.FILTERS    = FilterHelper.sortObjectByVisibleItems(FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT, this.SKU_DEEPDIVE_FILTERS.slice(0, this.defaultVisibleFilters)) || [];
    const lstOption = FilterHelper.init_lstOption_States('VsLyCategoryComponent', this.FILTERS, this.defaultVisibleFilters, this.userService.getUserInfo()) || {};
    this.lstOption_States   = lstOption.lstOption_States;
    this.lstOption_Selected = lstOption.lstOption_Selected;
    this.listData_Filter  =lstOption.listData_Filter;
    this.skuFilterQuery     = SkuDeepdiveHelper.skuSetQueryString(lstOption.lstOption_Selected);
    this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.SKU_DEEPDIVE_FILTERS) || {};
    this.onUpdateCurrent_Filter_Applied();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (((changes['lstOption_Selected_DateType'] && this.lstOption_Selected_DateType && this.lstOption_Selected_DateType.length > 0)
        || (changes['lstOption_Selected_SalesDate'] && this.lstOption_Selected_SalesDate && this.lstOption_Selected_SalesDate.length > 0))) {
      if(this.isHasFistLoad){
        this.onSearchData();
      } 
      this.handleonReady();
    }
    this.isHasFistLoad = true;
  }

  ngOnDestroy(){
    if (this.chartNRS_Category) {
      this.chartNRS_Category.destroy();
    }
    if (this.chartGP_Category) {
      this.chartGP_Category.destroy();
    }

    if (this.chartBAPC_Category) {
      this.chartBAPC_Category.destroy();
    }

    this.listChart.forEach(item => {
      item.chartBAPC.destroy();
      item.chartNRS.destroy();
      item.chartGP.destroy();
    });
  }


  onUpdateChart(event: any) {
    const {lstOption_States, lstOption_Selected} = event;

    if (lstOption_States && lstOption_Selected) {
      this.lstOption_Selected = {};
      this.lstOption_Selected = lstOption_Selected;
      this.lstOption_States   = {};
      this.lstOption_States   = lstOption_States;
      this.onUpdateCurrent_Filter_Applied();
      this.updateVsLyByCategoryData();
    }
  }

  onUpdateCurrent_Filter_Applied() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }

  // Init Filter
  public updateVsLyByCategoryData(): void {
    this.onSearchData();
  }

  onResetFiltersGroup($event: any) {
    const {data} = $event;

    if (data && Array.isArray(data) && data.length > 0) {
      data.map(key => {
        if (key && key.length > 0) {
          this.lstOption_Selected[key] = ['All'];
        }
      });
    }
  }

  onSearchData() {
    if (!this.lstOption_Selected_DateType || !this.lstOption_Selected_DateType?.length || !this.lstOption_Selected_SalesDate || !this.lstOption_Selected_SalesDate.length) {
      return;
    }

      this.isLoading = true;
      this.skuFilterQuery = SkuDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);
      SkuDeepdiveHelper.GetDataByCategory(this.http, this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD', !SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.skuFilterQuery, this.lstOption_Selected_ChannelLev).then((data) => {
        if (data) {
          const { Data } = data;

          if (Data) {
             this.vsLYByCategoryData = Data;
            if(this.lstOption_Selected_ChannelLev == 'CATEGORY_NAME_EN'){
              this.processData();
            }else
            {
              this.processSubCateogryData();
            }
            
            this.sortTable('');
            this.isLoading = false;
          }
        }
      })
  }

  onSaveChannelLevChange(event: any) {
    this.lstOption_Selected_ChannelLev = event;
    this.onSearchData();
  }

  processData(){
    this.isNoDataMode = this.vsLYByCategoryData.length == 0;
    this.maxBAPC_TY = Math.max(...this.vsLYByCategoryData.map(item => item.BAPC_TY).filter(value => !isNaN(value)));
    this.maxBAPC_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxBAPC_TY);
    this.maxNSR_TY = Math.max(...this.vsLYByCategoryData.map(item => item.NSR_TY).filter(value => !isNaN(value)));
    this.maxNSR_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxNSR_TY);
    this.maxGP_TY = Math.max(...this.vsLYByCategoryData.map(item => item.GP_TY).filter(value => !isNaN(value)));
    this.maxGP_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxGP_TY);
    this.BAPC_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxBAPC_TY/2000);
    this.BAPC_TYFullColumn = AnalyticsHelper.formatNumber(this.maxBAPC_TY/1000);
    this.NSR_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxNSR_TY/2000000);
    this.NSR_TYFullColumn = AnalyticsHelper.formatNumber(this.maxNSR_TY/1000000);
    this.GP_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxGP_TY/2000000);
    this.GP_TYFullColumn = AnalyticsHelper.formatNumber(this.maxGP_TY/1000000);


    this.listGroupCategoryDetail = this.vsLYByCategoryData.map(items => {
      return {
        ...items,
        WithBAPC_TY: Math.abs(items.BAPC_TY / this.maxBAPC_TY * 100).toFixed(2),
        WithNSR_TY: Math.abs(items.NSR_TY / this.maxNSR_TY * 100).toFixed(2),
        WithGP_TY: Math.abs(items.GP_TY > 0 ? items.GP_TY / this.maxGP_TY * 100 : 0).toFixed(2),
        BAPC_TY_Format:  AnalyticsHelper.formatNumber((items.BAPC_TY/1000).toFixed(0)),
        NSR_TY_Format: AnalyticsHelper.formatNumber((items.NSR_TY/1000000).toFixed(0)),
        GP_TY_Format: AnalyticsHelper.formatNumber((items.GP_TY/1000000).toFixed(0))
      };
    });

    this.listGroupCategoryDetail = this.sortCategory(this.listGroupCategoryDetail);
  }

  processSubCateogryData(){
    this.isNoDataMode = this.vsLYByCategoryData.length == 0;
    
    this.maxBAPC_TY = Math.max(...this.vsLYByCategoryData.map(item => item.BAPC_TY).filter(value => !isNaN(value)));
    this.maxBAPC_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxBAPC_TY);
    this.maxNSR_TY = Math.max(...this.vsLYByCategoryData.map(item => item.NSR_TY).filter(value => !isNaN(value)));
    this.maxNSR_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxNSR_TY);
    this.maxGP_TY = Math.max(...this.vsLYByCategoryData.map(item => item.GP_TY).filter(value => !isNaN(value)));
    this.maxGP_TY = SkuDeepdiveHelper.roundMaxValueInChart(this.maxGP_TY);
    this.BAPC_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxBAPC_TY/2000);
    this.BAPC_TYFullColumn = AnalyticsHelper.formatNumber(this.maxBAPC_TY/1000);
    this.NSR_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxNSR_TY/2000000);
    this.NSR_TYFullColumn = AnalyticsHelper.formatNumber(this.maxNSR_TY/1000000);
    this.GP_TYHalfColumn = AnalyticsHelper.formatNumber(this.maxGP_TY/2000000);
    this.GP_TYFullColumn = AnalyticsHelper.formatNumber(this.maxGP_TY/1000000);


    this.listGroupCategoryName = Array.from(new Set(this.vsLYByCategoryData.map(item => item.CATEGORY_NAME_EN)));

    this.listGroupCategoryDetail = this.listGroupCategoryName.map(categoryName => {
      const filteredItems = this.vsLYByCategoryData.filter(item => item.CATEGORY_NAME_EN === categoryName);

      const ChildItems = filteredItems.map(item => ({
          ...item,
          WithBAPC_TY: Math.abs(item.BAPC_TY / this.maxBAPC_TY * 100).toFixed(2),
          WithNSR_TY: Math.abs(item.NSR_TY / this.maxNSR_TY * 100).toFixed(2),
          WithGP_TY: Math.abs(item.GP_TY > 0?  item.GP_TY / this.maxGP_TY * 100 : 0).toFixed(2),
          BAPC_TY_Format:  AnalyticsHelper.formatNumber((item.BAPC_TY/1000).toFixed(0)),
          NSR_TY_Format: AnalyticsHelper.formatNumber((item.NSR_TY/1000000).toFixed(0)),
          GP_TY_Format: AnalyticsHelper.formatNumber((item.GP_TY/1000000).toFixed(0))
      }));

      return {
          CATEGORY_NAME_EN: categoryName,
          subIdChart : categoryName.split(' ').join('_'),
          ChildItems: ChildItems
      };
  });
  }

  groupData(vsLYByCategoryData: any[] = null){
    let listGroupCategoryName = Array.from(new Set(vsLYByCategoryData.map(item => item.CATEGORY_NAME_EN)));

    //listGroupCategoryName = this.sortCategory(listGroupCategoryName);

    // if(this.lstOption_Selected_ChannelLev == 'CATEGORY_NAME_EN'){
    //   this.listGroupCategoryDetail = this.sortCategory(this.listGroupCategoryDetail);
    // }else{
    //   this.listGroupCategoryDetail = this.sortSubCategory(this.listGroupCategoryDetail);
    // }
    
    let listGroupCategoryDetail = listGroupCategoryName.map(categoryName => {
      const filteredItems = vsLYByCategoryData.filter(item => item.CATEGORY_NAME_EN === categoryName);

        const ChildItems = filteredItems.map(item => ({
          ...item,
          WithBAPC_TY: Math.abs(item.BAPC_TY / this.maxBAPC_TY * 100).toFixed(2),
          WithNSR_TY: Math.abs(item.NSR_TY / this.maxNSR_TY * 100).toFixed(2),
          WithGP_TY: Math.abs(item.GP_TY > 0?  item.GP_TY / this.maxGP_TY * 100 : 0).toFixed(2),
          BAPC_TY_Format:  AnalyticsHelper.formatNumber((item.BAPC_TY/1000).toFixed(0)),
          NSR_TY_Format: AnalyticsHelper.formatNumber((item.NSR_TY/1000000).toFixed(0)),
          GP_TY_Format: AnalyticsHelper.formatNumber((item.GP_TY/1000000).toFixed(0))
        }));

        return {
          CATEGORY_NAME_EN: categoryName,
          subIdChart : categoryName.split(' ').join('_'),
          ChildItems: ChildItems
      };
    });



    return this.sortCategory(listGroupCategoryDetail) ;
  }


private sortCategory(lstOption: any[]) {
    let order: string[] = 
    ['SSD',
    'NST',
    'SPORTS DRINK',
    'COFFEE',
    'WATER',
    'JUICE',
    'BLACK TEA',
    'LACTIC',
    'ENERGY',
    'ALCOHOL',
    'OTHER BEVERAGE ',
    'OTHER',
    'FOOD'];

    let orderMap: { [key: string]: number } = {};
    order.forEach((item, index) => {
        orderMap[item] = index;
    });

    let result = lstOption.sort((a, b) => {
        return orderMap[a.CATEGORY_NAME_EN] - orderMap[b.CATEGORY_NAME_EN];
    });

    return result;
}


private sortSubCategory(lstOption: any[]) {
  let order: string[] = 
  ['COLA',
  'FLAVORED CSD',
  'CLEAR CSD',
  'GREEN TEA',
  'BARLEY TEA',
  'OOLONG TEA',
  'OTHER NON SUGAR TEA',
  'SPORTS',
  'BLACK',
  'CAFE AU LAIT',
  'STANDARD',
  'BITO',
  'SEASONAL COFFEE',
  'BOTTLE COFFEE',
  'SWEET&MILKY COFFEE',
  'OTHER',
  'NULL',
  'WATER',
  '0-99% FRUIT JUICE',
  '100% FRUIT JUICE',
  'VEGETABLE MIX JUICE',
  'ROYAL MILK TEA',
  'LEMON TEAS',
  'STRAIGHT TEAS',
  'OTHER FLAVOR TEAS',
  'OTHER MILK TEAS',
  'LACTIC',
  'FORTIFIED',
  'FUNCTIONAL',
  'CHUHI',
  'LIQUEUR',
  'NON-ALC. CHUHI',
  'OTHER_FOODS',
  'パン',
  'スナック',
  'ヌードル',
  'デザート'];

  let orderMap: { [key: string]: number } = {};
  order.forEach((item, index) => {
      orderMap[item] = index;
  });

  let result = lstOption.sort((a, b) => {
      return orderMap[a.SUBCATEGORY_NAME_EN] - orderMap[b.SUBCATEGORY_NAME_EN];
  });

  return result;
}


  handleonReady() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }


  exportSkuCategory_CVS(){
    this.skuFilterQuery = SkuDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);
    SkuDeepdiveHelper.GetDataByCategory(this.http, this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD', !SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.skuFilterQuery, this.lstOption_Selected_ChannelLev, true).then((data) => {
      if (data) {
        const { Data } = data;

        if (Data) {
          this.vsLYByCategoryData_CSV =  this.groupData(Data);
          this.sortTable_CSV('');
          const fileName = "vs LY by Category"
          let data = [];
          let filters: any;
          filters = {
            DateType: this.lstOption_Selected_DateType,
            SaleDateMonth: this.lstOption_Selected_SalesDate,
            channelLv1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
            channelLv2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
            channelLv3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
            saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
            saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
            saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
            SalesCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
            SalesRoute: this.lstOption_Selected_Applied.SALES_ROUTE_NAME,
            ComDistBase: this.lstOption_Selected_Applied.COM_DIST_SALES_REP_NAME,
            DealerName: this.lstOption_Selected_Applied.DEALER_NAME,
            KamDeptLv2: this.lstOption_Selected_Applied.KAM_DEPT_LV2_NAME,
            KamDeptLv3: this.lstOption_Selected_Applied.KAM_DEPT_LV3_NAME,
            KamDeptLv4: this.lstOption_Selected_Applied.KAM_DEPT_LV4_NAME,
            KamContactPerson: this.lstOption_Selected_Applied.KAM_CONTACT_PERSON_NAME,
            AccountLev2: this.lstOption_Selected_Applied.ACCOUNT_LEV2_NAME_EN,
            AccountLev3: this.lstOption_Selected_Applied.ACCOUNT_LEV3_NAME_EN,
            Category: this.lstOption_Selected_Applied.CATEGORY_NAME_EN,
            Subcategory: this.lstOption_Selected_Applied.SUBCATEGORY_NAME_EN,
            Brand: this.lstOption_Selected_Applied.BRAND_NAME_EN,
            PackageLev1: this.lstOption_Selected_Applied.PACKAGE_LEV1_NAME_EN,
            PackageLev2: this.lstOption_Selected_Applied.PACKAGE_LEV2_NAME_EN,
            PackageDtl: this.lstOption_Selected_Applied.PACKAGE_DTL_NAME,
          };

            this.vsLYByCategoryData_CSV.forEach(items => {
              const itemsSort = [...sortArrayByPriority(items.ChildItems, PRIORITY_ORDER?.SUBCATEGORY_NAME_EN, 'SUBCATEGORY_NAME_EN')];
              itemsSort.forEach(childItems => {
                if(this.lstOption_Selected_DateType == "MTD") {
                  data.push({
                    DateType: filters === null ? '' : String(filters.DateType),
                    SaleDateMonth: filters === null ? '' : String(filters.SaleDateMonth),
                    ... this.toRowCVS(childItems, filters)
                  })
                } else {
                  data.push({
                    DateType: filters === null ? '' : String(filters.DateType),
                    SaleDateDay:filters === null ? '' : this.lstOption_Selected_SalesDate[0] + ' to ' + this.lstOption_Selected_SalesDate[1],
                    ... this.toRowCVS(childItems, filters)
                  })
                }
              })
            });
      
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: [
                'Date Type',
                'Sales Date',	
                'Channel Lev1',	
                'Channel Lev2',	
                'Channel Lev3',	
                'Sales HQ Lev1',	
                'Sales HQ Lev2',	
                'Sales HQ Lev3',	
                'Sales Center',	
                'Sales Route',
                'Sales BD',	
                'Dealer',	
                'Kam Dept Lev2',	
                'Kam Dept Lev3',	
                'Kam Dept Lev4',	
                'Kam Account Mgr 4',	
                'Account Lev2',	
                'Account Lev3',	
                'Category',	
                'Subcategory',	
                'Brand',	
                'Package Lev1',	
                'Package Lev2',	
                'Package Detail',	
                'CATEGORY NAME',	
                'SUBCATEGORY NAME',	
                'BAPC TY',	
                'BAPC LY',	
                'BAPC % vs LY',	
                'BAPC Diff LY',	
                'NSR TY',	
                'NSR LY',	
                'NSR % vs LY',	
                'NSR Diff LY',	
                'GP TY',	
                'GP LY',	
                'GP % vs LY',	
                'GP Diff LY'
              ]
            });
        }
      }
    })
  }

  exportSkuCategory_CVS_by_Url(){
    const obj = SkuDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);

    const salesDateQuery = ['MTD', 'QTD', 'YTD', 'Specific Month'].includes(this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType: 'MTD') ? 'SALES_YM' : 'SALES_DATE';
    this.skuFilterQuery = {
      ...obj,
      CacheKey: `${salesDateQuery}=${!SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : []}&${obj['CacheKey']}`,
      Selected: {
        ...obj['Selected'],
        [salesDateQuery]: !SkuDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [],
        DATE_TYPE       : this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD'
      }
    };

    this.csvService.getCsvUrl(SkuDeepdiveQueries.byCategory, this.skuFilterQuery, this.lstOption_Selected_ChannelLev)
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  toRowCVS(row:any, filters:any){
    return {
      channelLv1: filters === null?'': String(filters.channelLv1),
      channelLv2: filters === null?'': String(filters.channelLv2),
      channelLv3: filters === null?'': String(filters.channelLv3),
      saleHQLev1: filters === null?'': String(filters.saleHQLev1),
      saleHQLev2: filters === null?'': String(filters.saleHQLev2),
      saleHQLev3: filters === null?'': String(filters.saleHQLev3),
      SalesCenter: filters === null?'': String(filters.SalesCenter),
      SalesRoute: filters === null?'': String(filters.SalesRoute),
      ComDistBase: filters === null?'': String(filters.ComDistBase),
      DealerName: filters === null?'': String(filters.DealerName),
      KamDeptLv2: filters === null?'': String(filters.KamDeptLv2),
      KamDeptLv3: filters === null?'': String(filters.KamDeptLv3),
      KamDeptLv4: filters === null?'': String(filters.KamDeptLv4),
      KamContactPerson: filters === null?'': String(filters.KamContactPerson),
      AccountLev2: filters === null?'': String(filters.AccountLev2),
      AccountLev3: filters === null?'': String(filters.AccountLev3),
      Category: filters === null?'': String(filters.Category),
      Subcategory: filters === null?'': String(filters.Subcategory),
      Brand: filters === null?'': String(filters.Brand),
      PackageLev1: filters === null?'': String(filters.PackageLev1),
      PackageLev2: filters === null?'': String(filters.PackageLev2),
      PackageDtl: filters === null?'': String(filters.PackageDtl),

      CategoryName: row['CATEGORY_NAME_EN'] === null ? '' : row['CATEGORY_NAME_EN'],
      SubcategoryName: row['SUBCATEGORY_NAME_EN'] === null ? 'NULL' : row['SUBCATEGORY_NAME_EN'],

      BAPC_TY: row['BAPC_TY_Display'] === null ? '' : row['BAPC_TY_Display'],
      BAPC_LY: row['BAPC_LY_Display'] === null ? '' : row['BAPC_LY_Display'],
      BAPC_VS_LY: row['BAPC_VS_LY_Display'] === null ? '' : row['BAPC_VS_LY_Display'],
      BAPC_Diff_LY: row['BAPC_DIFF_LY_Display'] === null ? '' : row['BAPC_DIFF_LY_Display'],
      NSR_TY: row['NSR_TY_Display'] === null ? '' : row['NSR_TY_Display'],
      NSR_LY: row['NSR_LY_Display'] === null ? '' : row['NSR_LY_Display'],
      NSR_VS_LY: row['NSR_VS_LY_Display'] === null ? '' : row['NSR_VS_LY_Display'],
      NSR_Diff_LY: row['NSR_DIFF_LY_Display'] === null ? '' : row['NSR_DIFF_LY_Display'],
      GP_TY: row['GP_TY_Display'] === null ? '' : row['GP_TY_Display'],
      GP_LY: row['GP_LY_Display'] === null ? '' : row['GP_LY_Display'],
      GP_VS_LY: row['GP_VS_LY_Display'] === null ? '' : row['GP_VS_LY_Display'],
      GP_Diff_LY: row['GP_DIFF_LY_Display'] === null ? '' : row['GP_DIFF_LY_Display']
    }
  }

  @ViewChild('pdf_print_area_2', { static: false }) pdf_print_area_2: ElementRef;
  exportPDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing-se pdf-printing");
    $(".table-container").css('height', 'auto');
    $(".table-container").css('overflow-y', 'scroll');
    $(".filter-section").css('display', 'none');

    htmlToImage.toCanvas(this.pdf_print_area_2.nativeElement, { quality: 1 })
      .then(function (canvas) {
        let pdfProperty: PDFProperty = {
          option: { margin: [50, 0, 46, 0] },
          canvas: canvas
        }
        downloadAsPDF(name, null, false, pdfProperty);
      });
  }


  createChart(){
    setTimeout(() => {
      if (this.chartNRS_Category) {
        this.chartNRS_Category.destroy();
      }
      if (this.chartGP_Category) {
        this.chartGP_Category.destroy();
      }

      if (this.chartBAPC_Category) {
        this.chartBAPC_Category.destroy();
      }


      this.listChart.forEach(item => {
        item.chartBAPC.destroy();
        item.chartNRS.destroy();
        item.chartGP.destroy();
      });


      var dataBAPC_lable = this.lstOption_Selected_ChannelLev === 'CATEGORY_NAME_EN' ?  this.listGroupCategoryDetail.map(e => e.CATEGORY_NAME_EN || 'NULL') : this.listGroupCategoryDetail.map(e => e.SUBCATEGORY_NAME_EN || 'NULL');
      var dataBAPC = {
        labels: dataBAPC_lable,
        datasets: [{
          data: this.listGroupCategoryDetail.map(e => (e.BAPC_VS_LY * 100).toFixed(1)), min: -100, max: 100,
          fill: false,
          borderColor: 'rgb(83, 100, 128)',
          tension: 0
        }]
      };
      this.chartBAPC_Category = new Chart("chartBAPC_Category", {
        type: 'line',
        data: dataBAPC,
        plugins: [ChartDataLabels],
        options: {
          maintainAspectRatio: false,
          aspectRatio: 1,
          indexAxis: 'y',
          responsive: true,
          scales: {
            x: {
              display: false,
              min: -200,
              max: 200
            },
            y: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 15,
              bottom: 15
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: 'black',
              align: 'right',
              formatter: function (value) {
                if (value > 0) {
                  return "+" + value;
                } else {
                  return value;
                }
              }
            }
          }
        }
      });


      var dataNRS = {
        labels: dataBAPC_lable,
        datasets: [{
          data: this.listGroupCategoryDetail.map(e => (e.NSR_VS_LY * 100).toFixed(1)), min: -100, max: 100,
          fill: false,
          borderColor: 'rgb(83, 100, 128)',
          tension: 0
        }]
      };

      this.chartNRS_Category = new Chart("chartNRS_Category", {
        type: 'line',
        data: dataNRS,
        plugins: [ChartDataLabels],
        options: {
          maintainAspectRatio: false,
          aspectRatio: 1,
          indexAxis: 'y',
          responsive: true,
          scales: {
            x: {
              display: false,
              min: -200,
              max: 200
            },
            y: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 15,
              bottom: 15
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: 'black',
              align: 'right',
              formatter: function (value) {
                if (value > 0) {
                  return "+" + value;
                } else {
                  return value;
                }
              }
            }
          }
        }
      });

      var dataGP = {
        labels: dataBAPC_lable,
        datasets: [{
          data: this.listGroupCategoryDetail.map(e => (e.GP_VS_LY * 100).toFixed(1)), min: -100, max: 100,
          fill: false,
          borderColor: 'rgb(83, 100, 128)',
          tension: 0
        }]
      };

      this.chartGP_Category = new Chart("chartGP_Category", {
        type: 'line',
        data: dataGP,
        plugins: [ChartDataLabels],
        options: {
          maintainAspectRatio: false,
          aspectRatio: 1,
          indexAxis: 'y',
          responsive: true,
          scales: {
            x: {
              display: false,
              min: -200,
              max: 200
            },
            y: {
              display: false,
            },
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 15,
              bottom: 15
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              color: 'black',
              align: 'right',
              formatter: function (value) {
                if (value > 0) {
                  return "+" + value;
                } else {
                  return value;
                }
              }
            }
          }
        }
      });

    }, 1000);
  }

  createChartSubCategory(){
    setTimeout(() => {

      if (this.chartNRS_Category) {
        this.chartNRS_Category.destroy();
      }
      if (this.chartGP_Category) {
        this.chartGP_Category.destroy();
      }

      if (this.chartBAPC_Category) {
        this.chartBAPC_Category.destroy();
      }

      this.listChart.forEach(item => {
        item.chartBAPC.destroy();
        item.chartNRS.destroy();
        item.chartGP.destroy();
      });


      this.listChart = [];
      this.listGroupCategoryDetail.map((category,index) => {
        let subIdChart =  category.subIdChart;
        let dataBAPC =  {
          labels: category.ChildItems.map(e => e.SUBCATEGORY_NAME_EN|| 'NULL'),
          datasets: [{
            data: category.ChildItems.map(e => (e.BAPC_VS_LY *100).toFixed(1)), min: -100, max: 100,
            fill: false,
            borderColor: 'rgb(83, 100, 128)',
            tension: 0
          }]
        };

        let chartBAPC = new Chart("chartBAPC_"+ subIdChart, {
          type: 'line',
          data: dataBAPC,
          plugins: [ChartDataLabels],
          options: {
            maintainAspectRatio: false,
            aspectRatio: 1,
            indexAxis: 'y',
            responsive: true,
            scales: {
              x: {
                display: false,
                min: -200,
                max: 200
              },
               y: {
                display: false,
              },
            },
            layout: {
              padding: {
                  left: 0,
                  right: 0,
                  top: 15,
                  bottom: 15
              }
            },
            plugins: {
              legend: {
                  display: false
              },
              datalabels: {
                color: 'black',
                align: 'right',
                formatter: function(value) {
                  if (value > 0) {
                    return "+" + value;
                  } else {
                    return value;
                  }
                }
              }
            }
          }
        });


        let dataNRS =  {
          labels: category.ChildItems.map(e => e.SUBCATEGORY_NAME_EN|| 'NULL'),
          datasets: [{
            data: category.ChildItems.map(e => (e.NSR_VS_LY *100).toFixed(1)), min: -100, max: 100,
            fill: false,
            borderColor: 'rgb(83, 100, 128)',
            tension: 0
          }]
        };

        let chartNRS = new Chart("chartNRS_" + subIdChart, {
          type: 'line',
          data: dataNRS,
          plugins: [ChartDataLabels],
          options: {
            maintainAspectRatio: false,
            aspectRatio: 1,
            indexAxis: 'y',
            responsive: true,
            scales: {
              x: {
                display: false,
                min: -200,
                max: 200
              },
               y: {
                display: false,
              },
            },
            layout: {
              padding: {
                  left: 0,
                  right: 0,
                  top: 15,
                  bottom: 15
              }
            },
            plugins: {
              legend: {
                  display: false
              },
              datalabels: {
                color: 'black',
                align: 'right',
                formatter: function(value) {
                  if (value > 0) {
                    return "+" + value;
                  } else {
                    return value;
                  }
                }
              }
            }
          }
        });

        let dataGP =  {
          labels: category.ChildItems.map(e => e.SUBCATEGORY_NAME_EN || 'NULL'),
          datasets: [{
            data: category.ChildItems.map(e => (e.GP_VS_LY *100).toFixed(1)), min: -100, max: 100,
            fill: false,
            borderColor: 'rgb(83, 100, 128)',
            tension: 0
          }]
        };

        let chartGP = new Chart("chartGP_"+ subIdChart, {
          type: 'line',
          data: dataGP,
          plugins: [ChartDataLabels],
          options: {
            maintainAspectRatio: false,
            aspectRatio: 1,
            indexAxis: 'y',
            responsive: true,
            scales: {
              x: {
                display: false,
                min: -200,
                max: 200
              },
               y: {
                display: false,
              },
            },
            layout: {
              padding: {
                  left: 0,
                  right: 0,
                  top: 15,
                  bottom: 15
              }
            },
            plugins: {
              legend: {
                  display: false
              },
              datalabels: {
                color: 'black',
                align: 'right',
                formatter: function(value) {
                  if (value > 0) {
                    return "+" + value;
                  } else {
                    return value;
                  }
                }
              }
            }
          }
        });

       
        this.listChart.push({
          chartBAPC,
          chartNRS,
          chartGP
        });
      });
    }, 1000);
  }


  sortTable(column: string) {
     if(this.lstOption_Selected_ChannelLev == 'CATEGORY_NAME_EN'){
      if(column == this.sortColumn && column){
        this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
      }else{
        this.sortType = "desc";
      }
  
      this.sortColumn = column ? column : 'NSR_TY';
      // this.listGroupCategoryDetail = this.listGroupCategoryDetail.map((category,index) => {
      //   return {
      //       CATEGORY_NAME_EN: category.CATEGORY_NAME_EN,
      //       subIdChart : "category_" + category.CATEGORY_NAME_EN.split(' ').join('_') + index,
      //       ChildItems: this.sortType== 'asc' ?  category.ChildItems.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : category.ChildItems.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn])
      //   };
      // });
  
      if(this.sortColumn){
        this.listGroupCategoryDetail = this.sortType== 'asc' ?  this.listGroupCategoryDetail.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : this.listGroupCategoryDetail.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
      }

      this.createChart();
    }else
    {
      if(column == this.sortColumn && column){
        this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
      }else{
        this.sortType = "desc";
      }
  
      this.sortColumn = column ? column : 'NSR_TY';



      let lstChildItems = [];


      // sort subcategory
      this.listGroupCategoryDetail = this.listGroupCategoryDetail.map((category,index) => {
        let childItems = this.sortType== 'asc' ?  category.ChildItems.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : category.ChildItems.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
        lstChildItems.push(...childItems);
        return {
            CATEGORY_NAME_EN: category.CATEGORY_NAME_EN,
            subIdChart : "category_" + category.CATEGORY_NAME_EN.split(' ').join('_') + index,
            ChildItems: childItems
        };
      });


      // sort category
      lstChildItems = this.sortType== 'asc' ?  lstChildItems.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : lstChildItems.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn]);
      let listGroupCategoryName = Array.from(new Set(lstChildItems.map(item => item.CATEGORY_NAME_EN)));

      let categoryOrderMap = new Map();
      listGroupCategoryName.forEach((name, index) => {
          categoryOrderMap.set(name, index);
      });

      this.listGroupCategoryDetail.sort((a, b) => {
        return categoryOrderMap.get(a.CATEGORY_NAME_EN) - categoryOrderMap.get(b.CATEGORY_NAME_EN);
      });

      this.createChartSubCategory();
    }
  };

  sortTable_CSV(column: string) {
    if(column == this.sortColumn && column){
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    }else{
      this.sortType = "desc";
    }

    this.sortColumn = column ? column : '';
    if(this.sortColumn){
      this.vsLYByCategoryData_CSV = this.vsLYByCategoryData_CSV.map((category,index) => {
        return {
            CATEGORY_NAME_EN: category.CATEGORY_NAME_EN,
            subIdChart : "category_" + category.CATEGORY_NAME_EN.split(' ').join('_') + index,
            ChildItems: this.sortType== 'asc' ?  category.ChildItems.slice().sort((a, b) => a[this.sortColumn] - b[this.sortColumn]) : category.ChildItems.slice().sort((a, b) => b[this.sortColumn] - a[this.sortColumn])
        };
      });
    }else{
      this.vsLYByCategoryData_CSV = this.vsLYByCategoryData_CSV.map((category,index) => {
        return {
            CATEGORY_NAME_EN: category.CATEGORY_NAME_EN,
            subIdChart : "category_" + category.CATEGORY_NAME_EN.split(' ').join('_') + index,
            ChildItems: this.sortSubCategory(category.ChildItems)
        };
      });
    }
  };

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }
}