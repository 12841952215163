<div class="row" (window:resize)="onResize()">
    <div class="col-lg-12 col-md-12">
        <div id="pdf_print_area_intage_flash_summary" #pdf_print_area_intage_flash_summary class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Summary - Value Share</h3>              
            </div>
            <p class="callout">Note : Only Maker filter applies to VM</p>
            <!-- FPT-ThinhLD4: [SENSE] INTAGE page Region Dropdownbox -->
            <div class="filter-section no-title mb-4">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic option-sankey" (change)="onScoreCardAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Week to Date'}, {value: 'mtd', name: 'Month to Date'}, {value: 'qtd', name: 'Quarter to Date'}, {value: 'ytd', name: 'Year to Date'}]"
                    [model]="aggregateBy"
                    (change)="onScoreCardAggregateChange($event)">
                </single-dropdown>

            </div>
            <div class="filter-section filter-item even-wrapper">

                <!-- <div class="ng-section summary-section native">
                    <b>Display</b>
                    <select class="form-control filter-by-category  form-basic" (change)="onSummaryDisplayChange($event.target.value)">
                        <option value="vsLy">Vs LY</option>
                        <option value="TY_Value">TY Value</option>
                        <option value="TY_Volume">TY Volume</option>
                    </select>
                </div> -->
                <single-dropdown title="Display"
                    [items]="[{value: 'vsLy', name: 'Vs LY'}, {value: 'TY_Value', name: 'TY Value'}, {value: 'TY_Volume', name: 'TY Volume'}]"
                    [model]="summaryDisplay"
                    (change)="onSummaryDisplayChange($event)" >
                </single-dropdown>

                <div class="ng-section summary-section">
                    <b>Maker</b>
                    <ng-select #ngFilterDisplayMaker class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="selectedSummaryMaker[0]" [multiple]="false" [closeOnSelect]="false"
                    (change)="onChangeSummaryMaker();onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    [clearable]="selectedSummaryMaker.length > 1">
                        <ng-option *ngFor="let item of makers" [value]="item.maker"><input type="checkbox" class="cb-multi-select"/> {{item.maker}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterDisplayMaker.close();onFilterBySummaryMakerChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterSummaryRegion class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="summarySelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterSummaryRegion($event)"
                    (change)="onRemoveFilterSummaryRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterSummaryRegion($event)"
                    [clearable]="summarySelectedRegion.length > 1">
                        <ng-option *ngFor="let item of summaryRegions" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterSummaryRegion.close();onFilterBySummaryRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <p></p>
            <div #capture class="card-body">
                <div class="summary-container">
                    <div class="h1s">
                        <h1 style="display:inline-block" class="level-1 rectangle">OTC: <span [class]="getSummaryPts('OTC Total').indexOf('-') != -1 ? 'text-red': 'text-green'">{{getSummaryPts('OTC Total')}}</span></h1>   
                        <h1 style="display:inline-block" class="square">VM: <span [class]="(getSummaryPtsVM().indexOf('-') != -1 || getSummaryPtsVM() == 'N/A') ? 'text-red': 'text-green'">{{getSummaryPtsVM()}}</span></h1>   
                    </div>
                    <ol class="level-2-wrapper">
                        <ng-container *ngFor="let channel of summaryData; index as i;">
                            <li [class]="i==summaryData.length-1? 'skip-level2' : ''">
                                <h2 class="level-2 rectangle">{{channel.channel}}: <span [class]="channel.vsLy.indexOf('-') != -1 ? 'text-red': 'text-green'">{{i==summaryData.length-1 ? '' : getSummaryPts(channel.channel)}}</span></h2>
                                <ol class="level-3-wrapper">
                                    <ng-container *ngFor="let packageType of channel.packageTypes; index as j;">
                                        <li>
                                            <h3 class="level-3 rectangle">{{packageType.packageType}}: <span [class]="packageType.vsLy.indexOf('-') != -1 ? 'text-red': 'text-green'">{{getSummaryPts(channel.channel,packageType.packageType)}}</span></h3>
                                            <ng-container *ngIf="this.summaryDisplay == 'vsLy'">
                                                <ol class="level-4-wrapper" id="level-4-{{i}}-{{j}}">
                                                    <li>
                                                        <h4 class="level-4 rectangle positive">
                                                            <div class="top">
                                                                <h5>Positive Categories:</h5>                                                                
                                                            </div>
                                                            <div class="bottom">
                                                                <ng-container *ngFor="let category of packageType.categories; index as k;">
                                                                    <ng-container *ngIf="category.vsLy.indexOf('-') == -1">
                                                                        <p>{{getCategoryDisplayName(category.category)}}: <span class="text-green">+{{category.vsLy}}pts</span></p>
                                                                    </ng-container>
                                                                </ng-container>                                                                
                                                            </div>
                                                        </h4>
                                                    </li>
                                                    <li>
                                                        <h4 class="level-4 rectangle last">
                                                            <div class="top">
                                                                <h5>Negative Categories:</h5>                                                                
                                                            </div>
                                                            <div class="bottom">
                                                                <ng-container *ngFor="let category of packageType.categories; index as k;">
                                                                    <ng-container *ngIf="category.vsLy.indexOf('-') != -1">
                                                                        <p>{{getCategoryDisplayName(category.category)}}: <span class="text-red">{{category.vsLy}}pts</span></p>
                                                                    </ng-container>
                                                                </ng-container>                                                                
                                                            </div>
                                                        </h4>
                                                    </li>
                                                </ol>
                                            </ng-container>
                                            <ng-container *ngIf="this.summaryDisplay == 'TY_Value'">
                                                <ol class="level-4-wrapper" id="level-4-value-{{i}}-{{j}}">
                                                    <li>
                                                        <h4 class="level-4 rectangle positive last">
                                                            <div class="bottom" style="border-radius: 12px;">
                                                                <ng-container *ngFor="let category of packageType.categories; index as k;">
                                                                    <p>{{getCategoryDisplayName(category.category)}}: +{{(category.ty | number: '1.0-0').toLocaleString()}}</p>
                                                                </ng-container>
                                                            </div>
                                                        </h4>
                                                    </li>
                                                </ol>
                                            </ng-container>
                                            <ng-container *ngIf="this.summaryDisplay == 'TY_Volume'">
                                                <ol class="level-4-wrapper" id="level-4-volume-{{i}}-{{j}}">
                                                    <li>
                                                        <h4 class="level-4 rectangle positive last">
                                                            <div class="bottom" style="border-radius: 12px;">
                                                                <ng-container *ngFor="let category of packageType.categories; index as k;">
                                                                    <p>{{getCategoryDisplayName(category.category)}}: +{{(category.tyVolume| number: '1.0-0').toLocaleString()}}</p>
                                                                </ng-container>
                                                            </div>
                                                        </h4>
                                                    </li>
                                                </ol>
                                            </ng-container>
                                        </li>
                                    </ng-container>
                                </ol>
                            </li>
                        </ng-container>
                    </ol>
                    </div>
            </div>
            <div class="d-flex flex-two-ends hide-pdf-print">
                <comment-button title1="Summary - Value Share" [screen]="capture"
                [chartId]="chartId" [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', summaryDisplay, selectedSummaryMaker, summarySelectedRegion}}]"></comment-button>

                <download-button>
                    <button ngbDropdownItem (click)="exportSummary(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportSummary(false)">Download CSV</button>
                    <button ngbDropdownItem (click)="downloadAsPDF('Summary - Value Share')">Download PDF</button>
                </download-button>
            </div>

            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>