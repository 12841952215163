import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { authorize } from 'src/app/helpers/AuthorizePage';
import { SalesDateService } from 'src/app/components/elements/sales-date-filter/sales-date-filter.service';
import { SalesDateHelper } from 'src/app/components/elements/sales-date-filter/helper';
import { RequestService } from 'src/app/services/request.service';
import { FaceShareHelper } from './faceshare.helper';
import { FaceshareQueries } from 'src/app/model/constants';

@Component({
  selector: 'app-faceshare',
  templateUrl: './faceshare.component.html',
  styleUrls: ['./faceshare.component.scss']
})

export class FaceShareComponent implements OnInit, OnDestroy {
  private loadingSubscription: Subscription;
  private salesDateSubscription: Subscription;

  public isLoading: boolean      = false;
  public lstAllOptionsData : any = {};
  public lstOptionAggregatedDateType: any = null;
  public lstOptionSelectedSalesDate: any = null;

  isSalesYMReady: boolean;
  isMarkerCategoryReady: boolean;
  isMarkerCategoryFilterReady: boolean;
  isAccountReady: boolean;
  isAccountFilterReady: boolean;
  isBySkuReady: boolean;
  isByAccountBySkuReady: boolean;

  constructor(private router: Router,
              private salesDateService: SalesDateService,
              private requestService: RequestService) {}

  async ngOnInit() {
    if (!authorize()) {
      this.router.navigateByUrl('unauthorized');
    } else {
      this.loadingSubscription = FaceShareHelper.isLoading().subscribe((loading: boolean) => {
        this.isLoading = loading;
      });

      this.salesDateSubscription = this.salesDateService.getTraxSalesDate().subscribe((date: string[]) => {
        if (date && date.length > 0) {
          this.requestService.sendRequest(FaceshareQueries.filter, {}, true).then((res) => {
            if (res) {
              const salesYM = JSON.parse(res)?.SALES_YM || [];
              const lstSalesYM = salesYM.map((elt: string) => {
                return {
                  name: elt,
                  value: elt
                }
              });

              SalesDateHelper.setFaceshareSalesDate(lstSalesYM);
              this.isSalesYMReady = true;
              this.checkPageReadyForUse();
            }
          });
        }
      })
    }
  }

  ngOnDestroy(): void {
    this.salesDateService.setTraxSalesDate([]);

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    if (this.salesDateSubscription) {
      this.salesDateSubscription.unsubscribe();
    }
  }

  public onDateTypeSaveItemsChange(e: any) {
    const {selectedDateType, selectedSalesDate} = e;

    if (selectedDateType) {
      this.lstOptionAggregatedDateType = selectedDateType;
    }

    if (selectedSalesDate) {
      this.lstOptionSelectedSalesDate = [...selectedSalesDate];
    }
  }

  public markerCategoryFilterReady(ready: boolean) {
    this.isMarkerCategoryFilterReady = ready;
    this.checkPageReadyForUse();
  }

  public accountFilterReady(ready: boolean) {
    this.isAccountFilterReady = ready;
    this.checkPageReadyForUse();
  }

  public markerCategoryReady(ready: boolean){
    this.isMarkerCategoryReady = ready;
  }

  public accountReady(ready: boolean) {
    this.isAccountReady = ready;
  }

  private checkPageReadyForUse() {
    if (this.isAccountFilterReady && this.isMarkerCategoryFilterReady && this.isSalesYMReady) {
      FaceShareHelper.setSalesDate(['MTD']);
    }
  }
}
